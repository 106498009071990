import Decimal from "decimal.js";

import {
  DirectIndexChartCompareTo,
  DirectIndexType,
  SectorMapping,
  Security,
  SubAccount,
} from "../generated/graphql";
import { RadioOption } from "../types";

export type DirectIndexingCustomization = {
  addedSecurities: Security[];
  excludedSectors: SectorMapping[];
  excludedSecurities: Security[];
};

export type DirectIndexSubAccount = {
  type: DirectIndexType;
  subAccount: SubAccount;
  subAccountId: string;
  signedAt?: Date;
  customization: DirectIndexingCustomization;
  lhfOverride?: Decimal;
};

export type DirectIndexingSetupStockSelection = {
  securityId: string;
  symbol: string;
  name: string;
  quantity: Decimal;
  value: Decimal;
  withinIndex: boolean;
  targetAllocation?: Decimal;
  canShowTargetAllocation: boolean;
  taxLots: Array<{
    id: string;
    quantity: Decimal;
    openBuyPrice: Decimal;
  }>;
};

export enum DirectIndexingSetupDepositMethod {
  Cash = "Cash",
  StocksAndCash = "StocksAndCash",
  LineOfCredit = "LineOfCredit",
}

export const DIRECT_INDEXING_COMPARE_TO_OPTIONS: RadioOption<DirectIndexChartCompareTo>[] =
  [
    {
      label: "Reinvested",
      value: DirectIndexChartCompareTo.Reinvested,
      description:
        "Shows your portfolio performance if you reinvested your tax savings",
      infoPopOver:
        "This line shows your portfolio’s performance if your tax savings from harvested losses were reinvested the same day they were harvested, based on your account setting’s tax rate, without considering capital gains. This is hypothetical, as actual reinvestment did not occur.",
      badge: "New feature",
    },
    {
      label: "ETF",
      value: DirectIndexChartCompareTo.Etf,
      description: "Shows you how you’re tracking against the comparison ETF",
    },
  ];
