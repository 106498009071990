export * from "./dateOnly";
export * from "./dateTime";

import { FieldMergeFunction } from "@apollo/client";
import { unionBy as _unionBy } from "lodash";

import { StrictTypedTypePolicies } from "../generated/apollo-helpers-graphql";
import {
  ClearingAccount,
  PaginatedActivity,
  PaginatedSimpleActivity,
} from "../generated/graphql";

type PaginatedResponse = {
  data: unknown[];
  nextPageToken: string;
};

const mergePagination = (
  existing: PaginatedResponse,
  incoming: PaginatedResponse,
  { args }: Record<string, any>,
) => ({
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  data: _unionBy(existing?.data ?? [], incoming.data ?? [], "__ref"),
  nextPageToken:
    !existing?.data.length || args.input?.nextPageToken
      ? incoming?.nextPageToken
      : existing?.nextPageToken,
});

const mergeActivityPagination = (
  existing: PaginatedActivity,
  incoming: PaginatedActivity,
) => {
  return {
    __typename: "PaginatedActivity",
    data: {
      __typename: "Activity",
      acatsTransfers: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.acatsTransfers ?? [],
        incoming.data.acatsTransfers ?? [],
        "__ref",
      ),
      cashTransfers: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.cashTransfers ?? [],
        incoming.data.cashTransfers ?? [],
        "__ref",
      ),
      credits: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.credits ?? [],
        incoming.data.credits ?? [],
        "__ref",
      ),
      creditCardPayments: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.creditCardPayments ?? [],
        incoming.data.creditCardPayments ?? [],
        "__ref",
      ),
      dailyCashDividends: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.dailyCashDividends ?? [],
        incoming.data.dailyCashDividends ?? [],
        "__ref",
      ),
      directIndexFeeCharges: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.directIndexFeeCharges ?? [],
        incoming.data.directIndexFeeCharges ?? [],
        "__ref",
      ),
      directIndexTradeExecutions: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.directIndexTradeExecutions ?? [],
        incoming.data.directIndexTradeExecutions ?? [],
        "__ref",
      ),
      intraAccountCashTransfers: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.intraAccountCashTransfers ?? [],
        incoming.data.intraAccountCashTransfers ?? [],
        "__ref",
      ),
      intraAccountStockTransfers: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.intraAccountStockTransfers ?? [],
        incoming.data.intraAccountStockTransfers ?? [],
        "__ref",
      ),
      liquidateDirectIndexRequests: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.liquidateDirectIndexRequests ?? [],
        incoming.data.liquidateDirectIndexRequests ?? [],
        "__ref",
      ),
      loans: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.loans ?? [],
        incoming.data.loans ?? [],
        "__ref",
      ),
      marginInterestCharges: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.marginInterestCharges ?? [],
        incoming.data.marginInterestCharges ?? [],
        "__ref",
      ),
      orders: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.orders ?? [],
        incoming.data.orders ?? [],
        "__ref",
      ),
      pendingLoans: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.pendingLoans ?? [],
        incoming.data.pendingLoans ?? [],
        "__ref",
      ),
      stockAdjustments: _unionBy(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existing?.data.stockAdjustments ?? [],
        incoming.data.stockAdjustments ?? [],
        "__ref",
      ),
    },
    nextPageToken: incoming.nextPageToken,
  };
};

const mergeSimpleActivityPagination = (
  existing: PaginatedSimpleActivity,
  incoming: PaginatedSimpleActivity,
  { args }: Record<string, any>,
) => {
  return {
    __typename: "PaginatedSimpleActivity",
    data: _unionBy(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      existing?.data ?? [],
      incoming.data,
      "__ref",
    ),
    nextPageToken:
      !existing?.data.length || args.input.nextPageToken
        ? incoming?.nextPageToken
        : existing?.nextPageToken,
  };
};

/**
 * Adds the result of the mutation into the ClearingAccount in the cache.
 * Uses the input's clearingAccountId to identify which ClearingAccount to merge into.
 */
const mergeClearingAccountMutation = (
  field: keyof ClearingAccount,
): FieldMergeFunction => {
  return (_, incoming, { args, cache }) => {
    const clearingAccountRef = cache.identify({
      __typename: "ClearingAccount",
      id: args?.input?.clearingAccountId,
    });
    if (clearingAccountRef) {
      cache.modify({
        id: clearingAccountRef,
        fields: {
          [field]: () => incoming,
        },
      });
    }
    return incoming;
  };
};

export const typePolicies: StrictTypedTypePolicies = {
  KeyValuePair: {
    keyFields: ["namespace", "key"],
  },
  ClearingAccount: {
    fields: {
      activity: {
        keyArgs: ["input", ["status", "filter", "subAccountId", "securityId"]],
        merge: mergeActivityPagination,
      },
      simpleActivity: {
        keyArgs: ["input", ["status", "filter", "subAccountId", "securityId"]],
        merge: mergeSimpleActivityPagination,
      },
      demoSimpleActivity: {
        keyArgs: ["input", ["status", "filter", "subAccountId", "securityId"]],
        merge: mergeSimpleActivityPagination,
      },
      getAccountAcatsTransfers: {
        keyArgs: false,
        merge: mergePagination,
      },
      userInvestorDocuments: {
        keyArgs: ["category", "startDate"],
        merge(existing = { documents: [] }, incoming) {
          return {
            nextPageToken: incoming.nextPageToken,
            documents: [...existing.documents, ...(incoming.documents || [])],
          };
        },
      },
      activeScheduledDepositConfigs: {
        // Prevents caching by subAccountId, this puts it under the same key
        keyArgs: false,
      },
    },
  },
  ScheduledDepositConfig: {
    keyFields: ["subAccountId", "type"],
  },
  Security: {
    keyFields: ["id", "asOfDate"],
  },
  Query: {
    fields: {
      keyValueGet: {
        keyArgs: ["input"],
      },
      getAccountCashTransfers: {
        keyArgs: false,
        merge: mergePagination,
      },
      getAccountLoans: {
        keyArgs: false,
        merge: mergePagination,
      },
      getAccountOrders: {
        keyArgs: false,
        merge: mergePagination,
      },
      portfolio: {
        keyArgs: ["input"],
      },
      // No need to merge portfolioHistory, this is to handle loading states when changing the period
      portfolioHistory: {
        keyArgs: false,
        merge(_existing, incoming) {
          return incoming;
        },
      },
      getHistoricalPerformanceData: {
        keyArgs: ["args", ["type", "period"]],
      },
    },
  },
  Mutation: {
    fields: {
      updateInvestmentSettings: {
        // Links the mutation result to the clearing account
        merge: mergeClearingAccountMutation("investmentSettings"),
      },
      updateStockLendingSettings: {
        merge: mergeClearingAccountMutation("stockLendingInfo"),
      },
      upsertTradeRestrictions: {
        merge: mergeClearingAccountMutation("tradeRestrictions"),
      },
      updateTransferOnDeathBeneficiaries: {
        merge: mergeClearingAccountMutation("transferOnDeathBeneficiaries"),
      },
    },
  },
  TreasuryConfig: {
    keyFields: ["subAccountId"],
  },
  // activeAllocationConfig is part of the ClearingAccount fragment
  AllocationConfig: {
    keyFields: ["clearingAccountId"],
  },
};
