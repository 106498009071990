import { DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP } from "@frec-js/common";

import {
  ClearingAccountFragment,
  DividendPreferenceType,
  SubAccountType,
} from "../generated/graphql";
import { RadioOption } from "./radio";

export type DividendPreferenceOption = RadioOption<DividendPreferenceType>;

export type SelfManagedDividendPreferenceType = Exclude<
  DividendPreferenceType,
  DividendPreferenceType.Reinvest
>;
export type DirectIndexingDividendPreferenceType = Exclude<
  DividendPreferenceType,
  DividendPreferenceType.MoveToDirectIndexing
>;
// Not used currently
export type TreasuryDividendPreferenceType = Exclude<
  DividendPreferenceType,
  DividendPreferenceType.MoveToTreasury
>;

export const SELF_MANAGED_DIVIDEND_OPTIONS_LABEL: Record<
  SelfManagedDividendPreferenceType,
  string
> = {
  [DividendPreferenceType.MoveToDirectIndexing]:
    "Reinvest into allocation or direct indices",
  [DividendPreferenceType.PayLoanAndFrecCash]:
    "Pay down portfolio line of credit",
  [DividendPreferenceType.MoveToTreasury]: "Reinvest into Treasury",
  [DividendPreferenceType.MoveToFrecCash]: "Move to Cash",
};

export const DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL: Record<
  DirectIndexingDividendPreferenceType,
  string
> = {
  [DividendPreferenceType.Reinvest]:
    "Reinvest into allocation or direct indices",
  [DividendPreferenceType.PayLoanAndFrecCash]:
    "Pay down portfolio line of credit",
  [DividendPreferenceType.MoveToTreasury]: "Reinvest into Treasury",
  [DividendPreferenceType.MoveToFrecCash]: "Move to Cash",
};

export const SELF_MANAGED_DIVIDEND_OPTIONS: DividendPreferenceOption[] = [
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToDirectIndexing
      ],
    value: DividendPreferenceType.MoveToDirectIndexing,
    description:
      "Reinvest dividends equally across your allocation or direct indexing accounts",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.PayLoanAndFrecCash
      ],
    value: DividendPreferenceType.PayLoanAndFrecCash,
    description:
      "Use dividends to pay off any balance on your portfolio line of credit",
    infoPopOver:
      "If your portfolio line of credit is fully paid down, any excess will move to your Cash balance on Frec",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToTreasury
      ],
    value: DividendPreferenceType.MoveToTreasury,
    description: "Earn yield on your dividends by moving them to Treasury",
    infoPopOver:
      "Since there is a $5 minimum requirement to invest into Treasury, any dividends less than $5 will accumulate in “held in process” under Treasury until the amount is $5 or more and then will be automatically invested.",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToFrecCash
      ],
    value: DividendPreferenceType.MoveToFrecCash,
    description: "Move any dividends to your Cash balance on Frec",
  },
];

export const DIRECT_INDEXING_DIVIDEND_OPTIONS: DividendPreferenceOption[] = [
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[DividendPreferenceType.Reinvest],
    value: DividendPreferenceType.Reinvest,
    description:
      "Reinvest dividends back into your allocation or their respective direct index",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.PayLoanAndFrecCash
      ],
    value: DividendPreferenceType.PayLoanAndFrecCash,
    description:
      "Use dividends to pay off any balance on your portfolio line of credit",
    infoPopOver:
      "If your portfolio line of credit is fully paid down, any excess will move to your Cash balance on Frec",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToTreasury
      ],
    value: DividendPreferenceType.MoveToTreasury,
    description: "Earn yield on your dividends by moving them to Treasury",
    infoPopOver:
      "Since there is a $5 minimum requirement to invest into Treasury, any dividends less than $5 will accumulate in “held in process” under Treasury until the amount is $5 or more and then will be automatically invested.",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToFrecCash
      ],
    value: DividendPreferenceType.MoveToFrecCash,
    description: "Move any dividends to your Cash balance on Frec",
  },
];

type DividendPreferences<T extends boolean> = T extends true
  ? {
      [SubAccountType.DirectIndex]: DirectIndexingDividendPreferenceType;
      [SubAccountType.Primary]: SelfManagedDividendPreferenceType;
      [SubAccountType.Treasury]: TreasuryDividendPreferenceType;
    }
  : {
      [SubAccountType.DirectIndex]:
        | DirectIndexingDividendPreferenceType
        | undefined;
      [SubAccountType.Primary]: SelfManagedDividendPreferenceType | undefined;
      [SubAccountType.Treasury]: TreasuryDividendPreferenceType | undefined;
    };

/**
 * Extracts the dividend preferences from the clearing account, optionally
 * using the default settings if the preference is not set.
 */
export const useClearingAccountDividendPreferences = <T extends boolean = true>(
  account?: ClearingAccountFragment,
  useDefaults: T = true as T,
): DividendPreferences<T> => {
  const getPreference = (type: SubAccountType) =>
    account?.dividendPreference?.find((d) => d.subAccountType === type)
      ?.preferenceType;

  const preferences = {
    [SubAccountType.DirectIndex]: getPreference(SubAccountType.DirectIndex),
    [SubAccountType.Primary]: getPreference(SubAccountType.Primary),
    [SubAccountType.Treasury]: getPreference(SubAccountType.Treasury),
  };

  if (useDefaults) {
    return Object.fromEntries(
      Object.entries(preferences).map(([key, value]) => [
        key,
        value ??
          DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[key as SubAccountType],
      ]),
    ) as DividendPreferences<T>;
  }

  return preferences as DividendPreferences<T>;
};
