import { ClassNameValue, extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        { text: ["12", "14", "16", "18", "20", "24", "32", "40", "64"] },
      ],
      "font-weight": [
        { font: ["book", "normal", "medium", "semibold", "bold"] },
      ],
    },
  },
});

export const classNames = (...classes: ClassNameValue[]) => {
  return twMerge(...classes);
};

export const mergeClassNames = (
  originalClassNames: Record<string, string>,
  newClassNames?: Record<string, string>,
) => {
  const results: Record<string, string> = {};
  const keys = new Set([
    ...Object.keys(originalClassNames),
    ...Object.keys(newClassNames ?? {}),
  ]);
  keys.forEach((key) => {
    const originalValue = originalClassNames[key];
    const newValue = newClassNames?.[key];
    results[key] = classNames(originalValue, newValue);
  });
  return results;
};
