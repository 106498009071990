import {
  convertAllocationConfigCashTransfersToInput,
  DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP,
  normalizeAllocationConfig,
  validateAllocationConfig,
  ValidateAllocationConfigResult,
} from "@frec-js/common";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AllocationConfigCashTransferInput,
  AllocationConfigFragment,
  AllocationConfigPurchaseOrderInput,
  AllocationConfigType,
  ClearingAccountFragment,
  DividendPreferenceType,
  SelfManagedTaxLossConfig,
  SubAccountType,
} from "../../generated/graphql";
import {
  CurrentSubAccountAllocationsMap,
  useCurrentSubAccountAllocations,
} from "../../hooks";
import {
  DirectIndexingDividendPreferenceType,
  SelfManagedDividendPreferenceType,
  useClearingAccountDividendPreferences,
} from "../../types";

export interface AllocationSetupProviderContextProps {
  loading: boolean;
  // SetupLayout
  stepReached: number;
  setStepReached: Dispatch<SetStateAction<number>>;
  // Allocation
  type: AllocationConfigType;
  setType: Dispatch<SetStateAction<AllocationConfigType>>;
  currentSubAccountAllocations: CurrentSubAccountAllocationsMap;
  cashTransfers: AllocationConfigCashTransferInput[];
  addCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  removeCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  editCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  // TODO: implement purchase orders
  purchaseOrders: AllocationConfigPurchaseOrderInput[];
  // Recurring deposit
  skipRecurringDeposit: boolean;
  setSkipRecurringDeposit: Dispatch<SetStateAction<boolean>>;
  // Dividend preferences
  disableTreasuryOption: boolean;
  showDividendStep: boolean;
  directIndexingDividendPreference: DirectIndexingDividendPreferenceType;
  setDirectIndexingDividendPreference: Dispatch<
    SetStateAction<DirectIndexingDividendPreferenceType>
  >;
  primaryDividendPreference: SelfManagedDividendPreferenceType;
  setPrimaryDividendPreference: Dispatch<
    SetStateAction<SelfManagedDividendPreferenceType>
  >;
  // Auto conversion
  sweepToSubAccountId: string | undefined;
  autoConversionHidden: boolean;
  autoConversionSecurityIds: string[];
  setAutoConversionSecurityIds: Dispatch<SetStateAction<string[]>>;
  sweepAll: boolean;
  setSweepAll: Dispatch<SetStateAction<boolean>>;
  // Utils
  normalize: () => void;
  // Validation
  validationErrors: ValidateAllocationConfigResult;
}

const AllocationSetupContext =
  createContext<AllocationSetupProviderContextProps>({
    loading: false,
    // SetupLayout
    stepReached: 0,
    setStepReached: () => undefined,
    // Allocation
    type: AllocationConfigType.PortfolioRebalance, // Only use PortfolioRebalance for Portfolio Allocations
    setType: () => undefined,
    currentSubAccountAllocations: new Map(),
    cashTransfers: [],
    addCashTransfer: () => undefined,
    removeCashTransfer: () => undefined,
    editCashTransfer: () => undefined,
    // TODO: implement purchase orders
    purchaseOrders: [],
    // Recurring deposit
    skipRecurringDeposit: false,
    setSkipRecurringDeposit: () => undefined,
    // Dividend preferences
    disableTreasuryOption: false,
    showDividendStep: false,
    directIndexingDividendPreference: DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
      SubAccountType.DirectIndex
    ] as DirectIndexingDividendPreferenceType,
    setDirectIndexingDividendPreference: () => undefined,
    primaryDividendPreference: DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
      SubAccountType.Primary
    ] as SelfManagedDividendPreferenceType,
    setPrimaryDividendPreference: () => undefined,
    // Auto conversion
    sweepToSubAccountId: undefined,
    autoConversionHidden: true, // Hidden by default
    autoConversionSecurityIds: [],
    setAutoConversionSecurityIds: () => undefined,
    sweepAll: false,
    setSweepAll: () => undefined,
    // Utils
    normalize: () => undefined,
    // Validation
    validationErrors: {
      allocationConfigErrors: [],
    },
  });

export const useAllocationSetup = () => {
  const context = useContext<AllocationSetupProviderContextProps>(
    AllocationSetupContext,
  );
  return context;
};

type AllocationSetupProviderProps = PropsWithChildren<{
  // Use conditional rendering so this provider is not mounted when the clearing account is undefined.
  clearingAccount?: ClearingAccountFragment;
  // Use this for the Allocations edit flow to prefill the allocation config.
  allocationConfig?: AllocationConfigFragment;
  // Although this is optional (if the config does not exist), it should be passed as a defined value
  // (if the config exists) when this provider is mounted.
  selfManagedTaxLossConfig?: SelfManagedTaxLossConfig;
}>;

/**
 * Context provider for the Allocations set up and edit flows.
 * Consumers should read the `loading` state to determine if the provider is ready.
 * This provider loads sub account portfolio aggregates once and then uses the data to set the initial state.
 */
export const AllocationSetupProvider = ({
  clearingAccount,
  allocationConfig,
  selfManagedTaxLossConfig,
  children,
}: AllocationSetupProviderProps) => {
  const prefillPortfolioAllocation =
    useRef<AllocationConfigCashTransferInput[]>();
  const { currentSubAccountAllocations, loading } =
    useCurrentSubAccountAllocations(clearingAccount?.id);

  // Provider states
  const [stepReached, setStepReached] = useState<number>(0);
  const [type, setType] = useState<AllocationConfigType>(
    allocationConfig?.type ?? AllocationConfigType.PortfolioRebalance,
  );
  const [cashTransfers, setCashTransfers] = useState<
    AllocationConfigCashTransferInput[]
  >(
    convertAllocationConfigCashTransfersToInput(
      allocationConfig?.cashTransfers ?? [],
    ),
  );
  const [skipRecurringDeposit, setSkipRecurringDeposit] =
    useState<boolean>(false);
  const dividendPreferences = useClearingAccountDividendPreferences(
    clearingAccount,
    true,
  );
  const [
    directIndexingDividendPreference,
    setDirectIndexingDividendPreference,
  ] = useState<DirectIndexingDividendPreferenceType>(
    dividendPreferences[SubAccountType.DirectIndex],
  );
  const [primaryDividendPreference, setPrimaryDividendPreference] =
    useState<SelfManagedDividendPreferenceType>(
      dividendPreferences[SubAccountType.Primary],
    );
  const [autoConversionSecurityIds, setAutoConversionSecurityIds] = useState<
    string[]
  >(selfManagedTaxLossConfig?.securities ?? []);
  const [sweepAll, setSweepAll] = useState<boolean>(
    selfManagedTaxLossConfig?.sweepAll ?? false,
  );

  // Initialize cash transfers from sub account allocations
  useEffect(() => {
    if (
      loading ||
      prefillPortfolioAllocation.current ||
      currentSubAccountAllocations.size === 0
    ) {
      return;
    }
    const initialCashTransfers: AllocationConfigCashTransferInput[] = [];
    // Only include sub accounts that have greater than 0% allocation, because
    // we only use integers for the percentage, we are filtering out small sub accounts.
    currentSubAccountAllocations.forEach((percentage, subAccountId) => {
      if (percentage.toDP(0).gt(0)) {
        initialCashTransfers.push({
          allocationSubAccountId: subAccountId,
          percentage: percentage.toDP(0),
        });
      }
    });
    // Normalize the cash transfers to ensure they sum to 100%
    const normalizedCashTransfers = normalizeAllocationConfig(
      {
        cashTransfers: initialCashTransfers,
        purchaseOrders: [],
        type: AllocationConfigType.PortfolioRebalance,
      },
      0,
    ).cashTransfers;
    // If an Allocation config is not provided, prefill the cash transfers.
    if (!allocationConfig) {
      setCashTransfers(normalizedCashTransfers);
    }
    prefillPortfolioAllocation.current = normalizedCashTransfers;
  }, [allocationConfig, currentSubAccountAllocations, loading]);

  // TODO: replace with reducer pattern
  const addCashTransfer = useCallback(
    (add: AllocationConfigCashTransferInput) => {
      // If the added sub account has a current allocation percentage,
      // use it from the currentCashTransfers ref.
      const currentPercentage = prefillPortfolioAllocation.current?.find(
        (c) => c.allocationSubAccountId === add.allocationSubAccountId,
      )?.percentage;
      if (currentPercentage?.gt(0)) {
        setCashTransfers((prev) => [
          ...prev,
          { ...add, percentage: currentPercentage.toDP(0) },
        ]);
      } else {
        setCashTransfers((prev) => [...prev, add]);
      }
    },
    [],
  );
  const removeCashTransfer = useCallback(
    (remove: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.filter(
          (c) => c.allocationSubAccountId !== remove.allocationSubAccountId,
        ),
      );
    },
    [],
  );
  const editCashTransfer = useCallback(
    (edit: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.map((c) =>
          c.allocationSubAccountId === edit.allocationSubAccountId ? edit : c,
        ),
      );
    },
    [],
  );

  const normalize = useCallback(() => {
    const normalized = normalizeAllocationConfig(
      {
        type,
        cashTransfers,
        // TODO: implement purchase orders
        purchaseOrders: [],
      },
      0,
    );
    setCashTransfers(normalized.cashTransfers);
  }, [type, cashTransfers]);

  const validationErrors = useMemo(() => {
    const errors = validateAllocationConfig({
      type,
      cashTransfers,
      purchaseOrders: [],
    });
    return errors;
  }, [type, cashTransfers]);

  // If the treasury is in the allocation, disable the reinvest into treasury option for dividends.
  const treasurySubAccountId = clearingAccount?.treasury?.subAccountId;
  const disableTreasuryOption = useMemo(() => {
    return Boolean(
      treasurySubAccountId &&
        cashTransfers.some(
          (transfer) =>
            transfer.allocationSubAccountId === treasurySubAccountId,
        ),
    );
  }, [treasurySubAccountId, cashTransfers]);
  // Only show the dividend step if the treasury option is disabled and
  // the user has their dividend preference set to move to treasury for
  // either direct indices or primary sub account.
  const showDividendStep =
    disableTreasuryOption &&
    (dividendPreferences[SubAccountType.DirectIndex] ===
      DividendPreferenceType.MoveToTreasury ||
      dividendPreferences[SubAccountType.Primary] ===
        DividendPreferenceType.MoveToTreasury);

  const value = useMemo<AllocationSetupProviderContextProps>(() => {
    const sweepToSubAccountId =
      selfManagedTaxLossConfig?.sweepToSubAccountId ?? undefined;
    const autoConversionEnabled =
      selfManagedTaxLossConfig?.sweepAll ||
      (selfManagedTaxLossConfig?.securities?.length ?? 0) > 0;
    const autoConversionHidden =
      !autoConversionEnabled ||
      !cashTransfers.some(
        (ct) => ct.allocationSubAccountId === sweepToSubAccountId,
      );
    return {
      loading,
      // SetupLayout
      stepReached,
      setStepReached,
      // Allocation
      type,
      setType,
      currentSubAccountAllocations,
      cashTransfers,
      addCashTransfer,
      removeCashTransfer,
      editCashTransfer,
      // TODO: implement purchase orders
      purchaseOrders: [],
      // Recurring deposit
      skipRecurringDeposit,
      setSkipRecurringDeposit,
      // Dividend preferences
      disableTreasuryOption,
      showDividendStep,
      directIndexingDividendPreference,
      setDirectIndexingDividendPreference,
      primaryDividendPreference,
      setPrimaryDividendPreference,
      // Auto conversion
      sweepToSubAccountId,
      autoConversionHidden,
      autoConversionSecurityIds,
      setAutoConversionSecurityIds,
      sweepAll,
      setSweepAll,
      // Utils
      normalize,
      // Validation
      validationErrors,
    };
  }, [
    loading,
    stepReached,
    setStepReached,
    type,
    setType,
    currentSubAccountAllocations,
    cashTransfers,
    addCashTransfer,
    removeCashTransfer,
    editCashTransfer,
    skipRecurringDeposit,
    setSkipRecurringDeposit,
    disableTreasuryOption,
    showDividendStep,
    directIndexingDividendPreference,
    setDirectIndexingDividendPreference,
    primaryDividendPreference,
    setPrimaryDividendPreference,
    autoConversionSecurityIds,
    setAutoConversionSecurityIds,
    sweepAll,
    setSweepAll,
    normalize,
    validationErrors,
  ]);

  return (
    <AllocationSetupContext.Provider value={value}>
      {children}
    </AllocationSetupContext.Provider>
  );
};
