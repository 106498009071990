import { colors } from "@frec-js/common";

import {
  DirectIndexType,
  SubAccount,
  SubAccountType,
} from "../../generated/graphql";
import {
  generateAnalogousColors,
  generateMonochromaticColors,
} from "../../utils";

export const getDirectIndexColor = (type?: DirectIndexType) => {
  switch (type) {
    case DirectIndexType.SpInfoTech:
      return colors.frecSalmon;
    case DirectIndexType.Sp500:
      return colors.frecBlue;
    case DirectIndexType.SpAdrDm:
      return colors.frecPlum;
    case DirectIndexType.SpAdrEm:
      return colors.frecSeaGreen;
    case DirectIndexType.SpShariah:
      return colors.frecBronze;
    case DirectIndexType.CrspLargeCap:
      return colors.frecPurple;
    case DirectIndexType.CrspMidCap:
      return colors.frecDarkTeal;
    case DirectIndexType.CrspSmallCap:
      return colors.frecTeal;
    case DirectIndexType.CrspTotalMarket:
      return colors.frecGrape;
    case DirectIndexType.CrspIssLargeCapEsg:
      return colors.frecAppleGreen;
    case DirectIndexType.Smh:
      return colors.frecDenimBlue;
    case DirectIndexType.Russell_1000:
      return colors.frecRust;
    case DirectIndexType.Russell_2000:
      return colors.frecMagenta;
    case DirectIndexType.Russell_3000:
      return colors.frecDustyRose;
    default:
      return colors.frecBlue;
  }
};

export const getSubAccountColor = (subAccount?: Partial<SubAccount>) => {
  switch (subAccount?.type) {
    case SubAccountType.DirectIndex:
      return getDirectIndexColor(subAccount?.directIndexAccountMetadata?.type);
    case SubAccountType.Treasury:
      return colors.frecTreasury;
    default:
      return colors.frecBlue;
  }
};

export const getDirectIndexingColorPalette = (
  type?: DirectIndexType,
  numColors = 16,
) => {
  const numMonochromaticColors = Math.ceil(numColors / 2);
  const directIndexColor = getDirectIndexColor(type);

  // Generate analogous colors and monochromatic palettes for each
  const analogousColors = generateAnalogousColors(directIndexColor);
  const monochromaticPalettes: string[][] = [];
  analogousColors.forEach((color) => {
    monochromaticPalettes.push(
      generateMonochromaticColors(color, numMonochromaticColors),
    );
  });

  // Mix colors from monochromatic palettes from darkest to lightest
  const directIndexColorPalette = [];
  let monochromaticStep = 0;
  for (let i = 0; i < numColors; i++) {
    directIndexColorPalette.push(
      monochromaticPalettes[i % monochromaticPalettes.length][
        monochromaticStep % numMonochromaticColors
      ],
    );
    if (i > 0 && i % monochromaticPalettes.length === 0) {
      monochromaticStep++;
    }
  }

  return directIndexColorPalette;
};
