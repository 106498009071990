export enum CompanyReferralSlug {
  Meta = "meta",
  Google = "google",
  Rivian = "rivian",
  Databricks = "databricks",
  RedHat = "redhat",
  Stripe = "stripe",
  Amazon = "amazon",
  Uber = "uber",
  LinkedIn = "linkedin",
}

export type CompanyReferralHeadlineFormat =
  | "sellingShares"
  | "anticipatingGains"
  | "tenderOffer";

export type CompanyReferralMilestoneReward = {
  label: string;
  description: string;
  asterisk?: number;
};

export type CompanyReferralMilestone = {
  threshold: number;
  description: string;
  rewards: CompanyReferralMilestoneReward[];
};

export type CompanyReferralData = {
  slug: string; // no spaces allowed
  companyName: string; // the name of the company
  ticker: string | null; // the stock symbol of the company
  employeeName: string; // what the employees are called at the company
  termsUrl: string; // the link to the company-specific referral program terms
  headlineFormat?: CompanyReferralHeadlineFormat; // the headline format for the company
  shareDescription?: string; // some text about how to share this link
  og: {
    image: string;
  };
  logoName: string;
  milestones: CompanyReferralMilestone[];
};

const MILESTONE_20K = {
  threshold: 20,
  description: "Get |$20,000| managed for free",
  rewards: [
    {
      label: "$20k of direct indexing managed free",
      description: "Your first $20k of direct indexing managed for free",
    },
  ],
};

const MILESTONE_50K = {
  threshold: 50,
  description: "Get |$50,000| managed for free",
  rewards: [
    {
      label: "$50k of direct indexing managed free",
      description: "Your first $50k of direct indexing managed for free",
    },
  ],
};

const MILESTONE_75K = {
  threshold: 100,
  description: "Get |$75,000| managed for free",
  rewards: [
    {
      label: "$75k of direct indexing managed free",
      description: "Your first $75k of direct indexing managed for free",
    },
    {
      label: "No wire fees",
      description: "No outgoing wire fees. Incoming wire fees are always free.",
    },
    {
      label: "No transfer fees",
      description:
        "We reimburse incoming stock transfer fees your brokerage may charge (terms apply). Plus, we’ll waive outgoing stock transfer fees.",
    },
  ],
};

const MILESTONE_100K = {
  threshold: 200,
  description: "Get |$100,000| managed for free",
  rewards: [
    {
      label: "$100k of direct indexing managed free",
      description: "Your first $100k of direct indexing managed for free",
    },
    {
      label: "Borrow at 5.13% vs 5.33%",
      description: "Borrow up to 70% of your portfolio at 5.13%",
      asterisk: 4,
    },
    {
      label: "No wire fees",
      description: "No outgoing wire fees. Incoming wire fees are always free.",
    },
    {
      label: "No transfer fees",
      description:
        "We reimburse incoming stock transfer fees your brokerage may charge (terms apply). Plus, we’ll waive outgoing stock transfer fees.",
    },
  ],
};

const MILESTONES = [
  MILESTONE_20K,
  MILESTONE_50K,
  MILESTONE_75K,
  MILESTONE_100K,
];

export const COMPANY_REFERRALS: Record<
  CompanyReferralSlug,
  CompanyReferralData
> = {
  [CompanyReferralSlug.Meta]: {
    slug: CompanyReferralSlug.Meta,
    companyName: "Meta",
    ticker: "META",
    employeeName: "Metamates",
    termsUrl: "https://docs.frec.com/meta_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "sellingShares",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/metamates-at-frec.png",
    },
    logoName: "meta-logo-color.png",
    shareDescription: "Share this link on “investing@meta” or “taxes@meta”",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Google]: {
    slug: CompanyReferralSlug.Google,
    companyName: "Google",
    ticker: "GOOG",
    employeeName: "Googlers",
    termsUrl: "https://docs.frec.com/google_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "sellingShares",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/googlers-at-frec.png",
    },
    logoName: "google-logo.png",
    shareDescription:
      "Share this link on g/investing, g/financial-planning, or g/mustachians",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Rivian]: {
    slug: CompanyReferralSlug.Rivian,
    companyName: "Rivian",
    ticker: "RIVN",
    employeeName: "Rivians",
    termsUrl: "https://docs.frec.com/rivian_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "anticipatingGains",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/rivians-at-frec.png",
    },
    logoName: "rivian-logo.png",
    shareDescription: "Share this link with your fellow Rivians",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Databricks]: {
    slug: CompanyReferralSlug.Databricks,
    companyName: "Databricks",
    ticker: null,
    employeeName: "Bricksters",
    termsUrl: "https://docs.frec.com/databricks_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "tenderOffer",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/bricksters-at-frec.png",
    },
    logoName: "databricks-logo.png",
    shareDescription: "Share this link with your fellow Bricksters",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.RedHat]: {
    slug: CompanyReferralSlug.RedHat,
    companyName: "Red Hat",
    ticker: "IBM",
    employeeName: "Red Hatters",
    termsUrl: "https://docs.frec.com/redhat_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "sellingShares",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/redhatters-at-frec.png",
    },
    logoName: "redhat-logo.png",
    shareDescription: "Share this link with your fellow Red Hatters",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Stripe]: {
    slug: CompanyReferralSlug.Stripe,
    companyName: "Stripe",
    ticker: null,
    employeeName: "Stripes",
    termsUrl: "https://docs.frec.com/stripe_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "tenderOffer",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/stripes-at-frec.png",
    },
    logoName: "stripe-logo.png",
    shareDescription:
      "Share this link on #personal-finance, #equity-unofficial, #churning",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Amazon]: {
    slug: CompanyReferralSlug.Amazon,
    companyName: "Amazon",
    ticker: "AMZN",
    employeeName: "Amazonians",
    termsUrl: "https://docs.frec.com/amazon_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "sellingShares",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/amazonians-at-frec.png",
    },
    logoName: "amazon-logo.png",
    shareDescription: "Share this link with your fellow Amazonians",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.Uber]: {
    slug: CompanyReferralSlug.Uber,
    companyName: "Uber",
    ticker: "UBER",
    employeeName: "Uber employees",
    termsUrl: "https://docs.frec.com/uber_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "anticipatingGains",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/uber-employees-at-frec.png",
    },
    logoName: "uber-logo.png",
    shareDescription: "Share this link on #investing",
    milestones: MILESTONES,
  },
  [CompanyReferralSlug.LinkedIn]: {
    slug: CompanyReferralSlug.LinkedIn,
    companyName: "LinkedIn",
    ticker: "MSFT",
    employeeName: "LinkedIn employees",
    termsUrl: "https://docs.frec.com/linkedin_at_frec_fee_waiver_terms.pdf",
    headlineFormat: "sellingShares",
    og: {
      image:
        "https://frec.com/webstatic/images/company-referrals/linkedin-employees-at-frec.png",
    },
    logoName: "linkedin-logo.png",
    shareDescription: "Share this link with your fellow LinkedIn colleagues",
    milestones: MILESTONES,
  },
};
