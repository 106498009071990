import Decimal from "decimal.js";
import { DateOnly } from "../date_utils";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A big int */
  BigInt: { input: BigInt; output: BigInt };
  /** A string that's of the form yyyy-mm-dd */
  Date: { input: DateOnly; output: DateOnly };
  /** A string that's an ISO 8601 representation of a date and time */
  DateTime: { input: Date; output: Date };
  /** Decimal for accurate arithmetic operations */
  Decimal: { input: Decimal; output: Decimal };
  /** JSON blob, useful for pass-through REST apis */
  JSON: { input: object; output: object };
};

export type AcatsAssetInput = {
  quantity: Scalars["Int"]["input"];
  securityId: Scalars["ID"]["input"];
};

export enum AcatsAssetStoreCashType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export enum AcatsAssetType {
  Cash = "CASH",
  Cins = "CINS",
  Cusip = "CUSIP",
  Isin = "ISIN",
  Sedol = "SEDOL",
  Symbol = "SYMBOL",
}

export type AcatsCreateTransferInput = {
  assets: Array<AcatsAssetInput>;
  cash: Array<Scalars["Decimal"]["input"]>;
  fromAccountId: Scalars["ID"]["input"];
  stockTransferServiceType?: InputMaybe<StockTransferServiceType>;
  toAccountId: Scalars["ID"]["input"];
  transferType: AcatsTransferType;
};

export type AcatsCreateTransferResponse = {
  __typename?: "AcatsCreateTransferResponse";
  acatsTransferId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<AcatsError>>;
};

export type AcatsError = {
  __typename?: "AcatsError";
  attemptedValue?: Maybe<Scalars["String"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  fieldName?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type AcatsStatusHistory = {
  __typename?: "AcatsStatusHistory";
  acatsTransferId: Scalars["String"]["output"];
  clientReferenceId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<AcatsError>>;
  eventTime: Scalars["DateTime"]["output"];
  frecStatus: AcatsTransferStatus;
  id: Scalars["ID"]["output"];
};

export type AcatsTransfer = {
  __typename?: "AcatsTransfer";
  acatsControlNumber?: Maybe<Scalars["BigInt"]["output"]>;
  accountConnectionType: LinkedBrokerageAccountType;
  accountInstitutionName: Scalars["String"]["output"];
  accountMask?: Maybe<Scalars["String"]["output"]>;
  accountOfficialName: Scalars["String"]["output"];
  assets?: Maybe<Array<AcatsTransferAsset>>;
  associatedAcatsId?: Maybe<Scalars["ID"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  contraPartyComment?: Maybe<Scalars["String"]["output"]>;
  currentStatus: AcatsTransferStatus;
  direction: AcatsTransferDirection;
  errors?: Maybe<Array<AcatsError>>;
  eventTime: Scalars["DateTime"]["output"];
  fee?: Maybe<Array<AcatsTransferFee>>;
  id: Scalars["ID"]["output"];
  linkedBrokerageAccount?: Maybe<LinkedBrokerageAccount>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  statusHistory?: Maybe<Array<AcatsStatusHistory>>;
  transferType: AcatsTransferType;
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export type AcatsTransferAsset = {
  __typename?: "AcatsTransferAsset";
  cash?: Maybe<Scalars["Decimal"]["output"]>;
  cashType?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  securityId?: Maybe<Scalars["String"]["output"]>;
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export enum AcatsTransferDirection {
  Internal = "INTERNAL",
  Joining = "JOINING",
  Leaving = "LEAVING",
  Undefined = "UNDEFINED",
}

export type AcatsTransferFee = {
  __typename?: "AcatsTransferFee";
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  type: AcatsTransferFeeType;
};

export enum AcatsTransferFeeType {
  Operating = "OPERATING",
}

export enum AcatsTransferStatus {
  AdminInitiated = "ADMIN_INITIATED",
  Complete = "COMPLETE",
  Initiated = "INITIATED",
  OpsRejected = "OPS_REJECTED",
  OpsRetry = "OPS_RETRY",
  OpsSmaCheck = "OPS_SMA_CHECK",
  Purge = "PURGE",
  RequestError = "REQUEST_ERROR",
  Review = "REVIEW",
  SettleClose = "SETTLE_CLOSE",
  Unknown = "UNKNOWN",
}

export enum AcatsTransferType {
  Full = "FULL",
  Partial = "PARTIAL",
  Reclaim = "RECLAIM",
  ResidualCredit = "RESIDUAL_CREDIT",
}

export enum AccountHolderType {
  Employee = "EMPLOYEE",
  Error = "ERROR",
  Foreign = "FOREIGN",
  Individual = "INDIVIDUAL",
  Institutional = "INSTITUTIONAL",
  Unspecified = "UNSPECIFIED",
}

export enum AccountType {
  Cash = "CASH",
  Margin = "MARGIN",
  Option = "OPTION",
  Unspecified = "UNSPECIFIED",
}

export enum ActionContext {
  AcatsIn = "ACATS_IN",
  AcatsOut = "ACATS_OUT",
  AccountDocuments = "ACCOUNT_DOCUMENTS",
  AchIn = "ACH_IN",
  AchOut = "ACH_OUT",
  AddExternalBanks = "ADD_EXTERNAL_BANKS",
  AddExternalBrokerages = "ADD_EXTERNAL_BROKERAGES",
  AddExternalCreditCard = "ADD_EXTERNAL_CREDIT_CARD",
  Borrow = "BORROW",
  Buy = "BUY",
  CheckOut = "CHECK_OUT",
  CloseAccount = "CLOSE_ACCOUNT",
  DeleteExternalBanks = "DELETE_EXTERNAL_BANKS",
  DeleteExternalBrokerages = "DELETE_EXTERNAL_BROKERAGES",
  DeleteExternalCreditCard = "DELETE_EXTERNAL_CREDIT_CARD",
  EditProfile = "EDIT_PROFILE",
  None = "NONE",
  PayBack = "PAY_BACK",
  Sell = "SELL",
  WireIn = "WIRE_IN",
  WireOut = "WIRE_OUT",
}

export type ActionContextStatus = {
  __typename?: "ActionContextStatus";
  actionContext: ActionContext;
  isAllowed: Scalars["Boolean"]["output"];
};

export type Activity = {
  __typename?: "Activity";
  acatsTransfers?: Maybe<Array<AcatsTransfer>>;
  allocationExecutions?: Maybe<Array<AllocationExecution>>;
  cashTransfers?: Maybe<Array<CashTransfer>>;
  creditCardPayments?: Maybe<Array<PaymentExecution>>;
  credits?: Maybe<Array<Credit>>;
  dailyCashDividends?: Maybe<Array<DailyCashDividend>>;
  directIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  directIndexTradeExecutions?: Maybe<Array<DirectIndexTradeExecutionSummary>>;
  fpslInterests?: Maybe<Array<FpslInterest>>;
  intraAccountCashTransfers?: Maybe<Array<IntraAccountCashTransfer>>;
  intraAccountStockTransfers?: Maybe<Array<IntraAccountStockTransfer>>;
  liquidateDirectIndexRequests?: Maybe<
    Array<LiquidateDirectIndexPortfolioRequest>
  >;
  loans?: Maybe<Array<LoanEvent>>;
  marginInterestCharges?: Maybe<Array<MarginInterestCharge>>;
  orders?: Maybe<Array<Order>>;
  pendingLoans?: Maybe<Array<PendingLoan>>;
  stockAdjustments?: Maybe<Array<StockAdjustmentDetails>>;
};

export enum ActivityFilterType {
  Allocation = "ALLOCATION",
  AllocationRebalance = "ALLOCATION_REBALANCE",
  Buy = "BUY",
  CashSettlement = "CASH_SETTLEMENT",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  Deposit = "DEPOSIT",
  DirectIndexFee = "DIRECT_INDEX_FEE",
  DirectIndexLiquidationRequest = "DIRECT_INDEX_LIQUIDATION_REQUEST",
  DirectIndexTradeExecutions = "DIRECT_INDEX_TRADE_EXECUTIONS",
  Dividends = "DIVIDENDS",
  FpslInterest = "FPSL_INTEREST",
  IncludeDirectIndexOrders = "INCLUDE_DIRECT_INDEX_ORDERS",
  InterestCharge = "INTEREST_CHARGE",
  InterestPayment = "INTEREST_PAYMENT",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  Loan = "LOAN",
  LoanPayment = "LOAN_PAYMENT",
  Sell = "SELL",
  StockTransfer = "STOCK_TRANSFER",
  Withdrawal = "WITHDRAWAL",
}

export type ActivityInput = {
  filter?: InputMaybe<Array<ActivityFilterType>>;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  status: ActivityStatus;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ActivityListItemTypeKey {
  AcatsIn = "ACATS_IN",
  AcatsOut = "ACATS_OUT",
  AllocationRebalance = "ALLOCATION_REBALANCE",
  AllocationWithdrawal = "ALLOCATION_WITHDRAWAL",
  Buy = "BUY",
  CashSettlement = "CASH_SETTLEMENT",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  Deposit = "DEPOSIT",
  DirectIndexingFeeCharge = "DIRECT_INDEXING_FEE_CHARGE",
  DirectIndexingTradeExecution = "DIRECT_INDEXING_TRADE_EXECUTION",
  DividendPayment = "DIVIDEND_PAYMENT",
  DividendReinvestment = "DIVIDEND_REINVESTMENT",
  InterestCharge = "INTEREST_CHARGE",
  InterestPayment = "INTEREST_PAYMENT",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  LiquidateDirectIndexRequest = "LIQUIDATE_DIRECT_INDEX_REQUEST",
  Loan = "LOAN",
  LoanPayment = "LOAN_PAYMENT",
  PendingLoan = "PENDING_LOAN",
  PendingLoanPayment = "PENDING_LOAN_PAYMENT",
  Sell = "SELL",
  Withdrawal = "WITHDRAWAL",
}

export enum ActivityStatus {
  Complete = "COMPLETE",
  Pending = "PENDING",
}

export enum ActivityType {
  AcatsTransfer = "ACATS_TRANSFER",
  AllocationExecution = "ALLOCATION_EXECUTION",
  CashTransfer = "CASH_TRANSFER",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  DailyCashDividends = "DAILY_CASH_DIVIDENDS",
  DirectIndexFeeCharge = "DIRECT_INDEX_FEE_CHARGE",
  DirectIndexTradeExecutions = "DIRECT_INDEX_TRADE_EXECUTIONS",
  FpslInterest = "FPSL_INTEREST",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  LiquidateDirectIndexRequest = "LIQUIDATE_DIRECT_INDEX_REQUEST",
  Loan = "LOAN",
  MarginInterestCharge = "MARGIN_INTEREST_CHARGE",
  Order = "ORDER",
  PendingLoan = "PENDING_LOAN",
  PendingLoanPayment = "PENDING_LOAN_PAYMENT",
  StockAdjustment = "STOCK_ADJUSTMENT",
}

export enum AddressType {
  Business = "BUSINESS",
  Default = "DEFAULT",
  Mailing = "MAILING",
  PoBox = "PO_BOX",
  Residential = "RESIDENTIAL",
}

export enum AdjustmentMethod {
  All = "All",
  None = "None",
  PriceReturn = "PriceReturn",
  SplitOnly = "SplitOnly",
}

export enum AdjustmentSource {
  AdminInitiated = "ADMIN_INITIATED",
  AllocationDeposit = "ALLOCATION_DEPOSIT",
  AllocationRebalance = "ALLOCATION_REBALANCE",
  AllocationWithdrawal = "ALLOCATION_WITHDRAWAL",
  AutoTlh = "AUTO_TLH",
  AutoTlhAllocation = "AUTO_TLH_ALLOCATION",
  CorporateActionStore = "CORPORATE_ACTION_STORE",
  CrspProcessing = "CRSP_PROCESSING",
  DividendSweep = "DIVIDEND_SWEEP",
  DividendSweepAllocation = "DIVIDEND_SWEEP_ALLOCATION",
  DiLiquidation = "DI_LIQUIDATION",
  DiRecurringDeposit = "DI_RECURRING_DEPOSIT",
  Manual = "MANUAL",
  MarketVectorProcessing = "MARKET_VECTOR_PROCESSING",
  RecurringDeposit = "RECURRING_DEPOSIT",
  RefreshSymbols = "REFRESH_SYMBOLS",
  RussellDataProcessing = "RUSSELL_DATA_PROCESSING",
  SodExt_235 = "SOD_EXT_235",
  SodExt_869 = "SOD_EXT_869",
  SodExt_870 = "SOD_EXT_870",
  SodExt_1027 = "SOD_EXT_1027",
  SpDataProcessing = "SP_DATA_PROCESSING",
  Treasury = "TREASURY",
  UserInitiated = "USER_INITIATED",
}

export enum AdjustmentType {
  AccountTransfer = "ACCOUNT_TRANSFER",
  Acquisition = "ACQUISITION",
  AdrFee = "ADR_FEE",
  CashAdjustment = "CASH_ADJUSTMENT",
  DividendReinvestment = "DIVIDEND_REINVESTMENT",
  Fpsl = "FPSL",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  OpsRelated = "OPS_RELATED",
  Spinoff = "SPINOFF",
  StockMerger = "STOCK_MERGER",
  StockSplit = "STOCK_SPLIT",
}

export type AggregatedMarginInterestFee = {
  __typename?: "AggregatedMarginInterestFee";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
};

export type AllocatedStock = {
  __typename?: "AllocatedStock";
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum AllocationAction {
  Deposit = "DEPOSIT",
  Edit = "EDIT",
  Rebalance = "REBALANCE",
  Remove = "REMOVE",
  Withdraw = "WITHDRAW",
}

export type AllocationConfig = {
  __typename?: "AllocationConfig";
  allowedActions?: Maybe<Array<AllocationAction>>;
  cashTransfers?: Maybe<Array<AllocationConfigCashTransfer>>;
  clearingAccountId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  purchaseOrders?: Maybe<Array<AllocationConfigPurchaseOrder>>;
  scheduledDepositConfigs?: Maybe<Array<ScheduledDepositConfig>>;
  type: AllocationConfigType;
};

export type AllocationConfigCashTransfer = {
  __typename?: "AllocationConfigCashTransfer";
  allocationConfigId: Scalars["ID"]["output"];
  allocationSubAccountId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  percentage: Scalars["Decimal"]["output"];
};

export type AllocationConfigCashTransferInput = {
  allocationSubAccountId: Scalars["ID"]["input"];
  percentage: Scalars["Decimal"]["input"];
};

export type AllocationConfigInput = {
  cashTransfers?: InputMaybe<Array<AllocationConfigCashTransferInput>>;
  clearingAccountId: Scalars["ID"]["input"];
  purchaseOrders?: InputMaybe<Array<AllocationConfigPurchaseOrderInput>>;
  scheduledDepositConfigs?: InputMaybe<Array<ScheduledDepositConfigInput>>;
  type: AllocationConfigType;
};

export type AllocationConfigPurchaseOrder = {
  __typename?: "AllocationConfigPurchaseOrder";
  allocationConfigId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  percentage: Scalars["Decimal"]["output"];
  securityId: Scalars["ID"]["output"];
};

export type AllocationConfigPurchaseOrderInput = {
  percentage: Scalars["Decimal"]["input"];
  securityId: Scalars["ID"]["input"];
};

export enum AllocationConfigType {
  OneTime = "ONE_TIME",
  PortfolioRebalance = "PORTFOLIO_REBALANCE",
  PublicApi = "PUBLIC_API",
  SplitDeposit = "SPLIT_DEPOSIT",
}

export type AllocationExecution = {
  __typename?: "AllocationExecution";
  allocationConfigId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  status: AllocationExecutionStatus;
  statusEventTime: Scalars["DateTime"]["output"];
  steps: Array<AllocationExecutionStepDetails>;
  transferAccountName?: Maybe<Scalars["String"]["output"]>;
  transferId?: Maybe<Scalars["ID"]["output"]>;
  transferSourceType?: Maybe<MoneyMovementSourceType>;
  type: AllocationExecutionType;
};

export enum AllocationExecutionInitiationType {
  ScheduledDeposit = "SCHEDULED_DEPOSIT",
  UserInitiated = "USER_INITIATED",
}

export enum AllocationExecutionStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Depositing = "DEPOSITING",
  Failed = "FAILED",
  Invalidated = "INVALIDATED",
  Liquidated = "LIQUIDATED",
  Liquidating = "LIQUIDATING",
  Submitted = "SUBMITTED",
}

export enum AllocationExecutionStep {
  Deposit = "DEPOSIT",
  FullLiquidation = "FULL_LIQUIDATION",
  Liquidation = "LIQUIDATION",
}

export type AllocationExecutionStepDetails = {
  __typename?: "AllocationExecutionStepDetails";
  allocationExecutionId: Scalars["ID"]["output"];
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  referenceId?: Maybe<Scalars["ID"]["output"]>;
  settlementAccountName?: Maybe<Scalars["String"]["output"]>;
  settlementId: Scalars["ID"]["output"];
  settlementSourceType: MoneyMovementSourceType;
  step: AllocationExecutionStep;
};

export enum AllocationExecutionType {
  Deposit = "DEPOSIT",
  Rebalance = "REBALANCE",
  Withdrawal = "WITHDRAWAL",
}

export enum AllocationWithdrawalStrategy {
  MinimizeTrackingError = "MINIMIZE_TRACKING_ERROR",
  OverallocatedAccountFirst = "OVERALLOCATED_ACCOUNT_FIRST",
}

export type AnnualizedReturn = {
  __typename?: "AnnualizedReturn";
  annualizedReturn: Scalars["Float"]["output"];
  endConstituentCount: Scalars["Int"]["output"];
  endDate: Scalars["Date"]["output"];
  endPrice: Scalars["Float"]["output"];
  gicsCode?: Maybe<GicsCode>;
  startConstituentCount: Scalars["Int"]["output"];
  startDate: Scalars["Date"]["output"];
  startPrice: Scalars["Float"]["output"];
  years: Scalars["Int"]["output"];
};

export type AnnualizedReturnResponse = {
  __typename?: "AnnualizedReturnResponse";
  annualizedReturns: Array<AnnualizedReturn>;
  index: StockIndex;
};

export type ApexAcatsAnonymousStatus = {
  __typename?: "ApexAcatsAnonymousStatus";
  created: Scalars["String"]["output"];
  errorText?: Maybe<Scalars["String"]["output"]>;
  expectedSettleDate: Scalars["String"]["output"];
  lastUpdated: Scalars["String"]["output"];
  nsccStatus: Scalars["String"]["output"];
  publicStatus: Scalars["String"]["output"];
};

export type ApexAcatsAsset = {
  __typename?: "ApexAcatsAsset";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  assetErrors?: Maybe<Array<ApexAcatsAssetError>>;
  comment?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<ApexAcatsAssetCurrency>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifiers?: Maybe<Array<ApexAcatsAssetIdentifier>>;
  longShort?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  settlingLocation?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsAssetCurrency = {
  __typename?: "ApexAcatsAssetCurrency";
  isoCode: Scalars["String"]["output"];
  rate: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
};

export type ApexAcatsAssetError = {
  __typename?: "ApexAcatsAssetError";
  propertyIdentifier: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  stateId: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ApexAcatsAssetIdentifier = {
  __typename?: "ApexAcatsAssetIdentifier";
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ApexAcatsCaseStates = {
  __typename?: "ApexAcatsCaseStates";
  comment?: Maybe<Scalars["String"]["output"]>;
  cycleDate?: Maybe<Scalars["String"]["output"]>;
  override?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  recommendation: Scalars["String"]["output"];
  scheduledAt?: Maybe<Scalars["String"]["output"]>;
  scheduledBy?: Maybe<Scalars["String"]["output"]>;
  scores?: Maybe<Array<Maybe<ApexAcatsScore>>>;
  stateType: Scalars["String"]["output"];
  submittedAt?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsGetTransferDetailsResponse = {
  __typename?: "ApexAcatsGetTransferDetailsResponse";
  acatsControlNumber?: Maybe<Scalars["BigInt"]["output"]>;
  anonymousKey: Scalars["String"]["output"];
  anonymousStatus: ApexAcatsAnonymousStatus;
  caseStates?: Maybe<Array<ApexAcatsCaseStates>>;
  created: Scalars["String"]["output"];
  deliverer: ApexAcatsPartyDetails;
  deliveringAssets?: Maybe<Array<ApexAcatsAsset>>;
  direction: AcatsTransferDirection;
  errors?: Maybe<Array<AcatsError>>;
  nextCycle: Scalars["String"]["output"];
  nonDeliverableAssets?: Maybe<Array<ApexAcatsAsset>>;
  previousCycle: Scalars["String"]["output"];
  processingCaseId: Scalars["BigInt"]["output"];
  receiver: ApexAcatsPartyDetails;
  requestedAssets?: Maybe<Array<ApexAcatsAsset>>;
  status: ApexAcatsTransferStatus;
  tifId?: Maybe<Scalars["String"]["output"]>;
  transferType: Scalars["String"]["output"];
};

export type ApexAcatsPartyDetails = {
  __typename?: "ApexAcatsPartyDetails";
  accountNumber: Scalars["String"]["output"];
  accountTitle?: Maybe<Scalars["String"]["output"]>;
  accountTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assets?: Maybe<Array<ApexAcatsAsset>>;
  correspondentName?: Maybe<Scalars["String"]["output"]>;
  interestedPartySsnOrTaxId?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isMarginAccount?: Maybe<Scalars["Boolean"]["output"]>;
  participantName: Scalars["String"]["output"];
  participantNumber: Scalars["Int"]["output"];
  primarySsnOrTaxId?: Maybe<Scalars["String"]["output"]>;
  secondarySsnOrTaxId?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsScore = {
  __typename?: "ApexAcatsScore";
  notes?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<Scalars["String"]["output"]>;
  scoreType?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsSimulatorConfig = {
  apiSimulated?: InputMaybe<Scalars["Boolean"]["input"]>;
  regionSimulated?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewAction?: InputMaybe<ApexAcatsSimulatorConfigReviewAction>;
  tifRejectReason?: InputMaybe<ApexAcatsSimulatorConfigTifRejectReason>;
};

export enum ApexAcatsSimulatorConfigReviewAction {
  Accelerate = "ACCELERATE",
  Accept = "ACCEPT",
  Reject = "REJECT",
}

export enum ApexAcatsSimulatorConfigTifRejectReason {
  AccountFlat = "ACCOUNT_FLAT",
  AccountInTransfer = "ACCOUNT_IN_TRANSFER",
  AccountTitleMismatch = "ACCOUNT_TITLE_MISMATCH",
  ClientRescinded = "CLIENT_RESCINDED",
  DocumentationNeeded = "DOCUMENTATION_NEEDED",
  Duplicate = "DUPLICATE",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  MissingAuthorizationSignature = "MISSING_AUTHORIZATION_SIGNATURE",
  SsnMismatch = "SSN_MISMATCH",
}

export type ApexAcatsTransferStatus = {
  __typename?: "ApexAcatsTransferStatus";
  action?: Maybe<Scalars["String"]["output"]>;
  alpsActionReason?: Maybe<Scalars["String"]["output"]>;
  alpsRecommendedAction?: Maybe<Scalars["String"]["output"]>;
  isScheduled: Scalars["Boolean"]["output"];
  isSubmitted: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  nsccState: Scalars["String"]["output"];
  nsccSubState?: Maybe<Scalars["String"]["output"]>;
  rejectReason?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
};

export type ApexRelationship = {
  __typename?: "ApexRelationship";
  depositAccountId: Scalars["String"]["output"];
  externalStatus: Scalars["String"]["output"];
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  rejectionReasons?: Maybe<Scalars["String"]["output"]>;
  relationshipId: Scalars["String"]["output"];
};

export enum ApexServiceType {
  ApexDefault = "APEX_DEFAULT",
  FrecApexSimulator = "FREC_APEX_SIMULATOR",
}

export type ApexSimulationEvent = {
  __typename?: "ApexSimulationEvent";
  eventTime: Scalars["DateTime"]["output"];
  eventType: EventType;
  externalId: Scalars["BigInt"]["output"];
  id: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
  topic: Scalars["String"]["output"];
};

export type ApexSimulationEventInput = {
  eventTime: Scalars["DateTime"]["input"];
  eventType: EventType;
  externalId: Scalars["BigInt"]["input"];
  id: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  payload: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
  topic: Scalars["String"]["input"];
};

export enum ApplicationLockName {
  EventsAcatsTransfers = "EVENTS_ACATS_TRANSFERS",
  EventsAccounts = "EVENTS_ACCOUNTS",
  EventsAchRelationships = "EVENTS_ACH_RELATIONSHIPS",
  EventsBasketOrdersAccountAllocation = "EVENTS_BASKET_ORDERS_ACCOUNT_ALLOCATION",
  EventsBasketOrdersMf = "EVENTS_BASKET_ORDERS_MF",
  EventsBasketOrdersSubmission = "EVENTS_BASKET_ORDERS_SUBMISSION",
  EventsCashTransfers = "EVENTS_CASH_TRANSFERS",
  EventsMutualFundsOrders = "EVENTS_MUTUAL_FUNDS_ORDERS",
  EventsMutualFundsOrdersRiskSummary = "EVENTS_MUTUAL_FUNDS_ORDERS_RISK_SUMMARY",
  EventsOrders = "EVENTS_ORDERS",
  EventsSketchInvestigationStatus = "EVENTS_SKETCH_INVESTIGATION_STATUS",
  EventsWireDeposits = "EVENTS_WIRE_DEPOSITS",
  ExecutePendingAllocationExecutions = "EXECUTE_PENDING_ALLOCATION_EXECUTIONS",
  ExecutePendingTreasuryExecutions = "EXECUTE_PENDING_TREASURY_EXECUTIONS",
  ExecuteQueuedOrders = "EXECUTE_QUEUED_ORDERS",
  GenerateAutoTlhCandidates = "GENERATE_AUTO_TLH_CANDIDATES",
  GenerateDiCandidates = "GENERATE_DI_CANDIDATES",
  MonitorDiExecutionStatus = "MONITOR_DI_EXECUTION_STATUS",
  QueueApprovedDiOrders = "QUEUE_APPROVED_DI_ORDERS",
  ScheduledDepositExecutions = "SCHEDULED_DEPOSIT_EXECUTIONS",
  UpdateDiExecutionStatus = "UPDATE_DI_EXECUTION_STATUS",
}

export enum AvailabilityLevel {
  AvailableAssets = "AVAILABLE_ASSETS",
  LastSettled = "LAST_SETTLED",
}

export type BackfillIterableUserFieldsInput = {
  fields: Array<Scalars["String"]["input"]>;
  userIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type BackupSodFilesInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BackupSodFilesResponse = {
  __typename?: "BackupSodFilesResponse";
  files: Array<Scalars["String"]["output"]>;
  status: Scalars["Boolean"]["output"];
};

export type BarInput = {
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  interval: TimeInterval;
  intervalMultiple: Scalars["Int"]["input"];
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  symbol: Scalars["String"]["input"];
};

export type BarOutput = {
  __typename?: "BarOutput";
  bars: Array<BarOutputData>;
  delay: Scalars["Float"]["output"];
  outcome: Scalars["String"]["output"];
};

export type BarOutputData = {
  __typename?: "BarOutputData";
  TWAP: Scalars["Float"]["output"];
  VWAP: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  endTime: Scalars["DateTime"]["output"];
  high: Scalars["Float"]["output"];
  low: Scalars["Float"]["output"];
  open: Scalars["Float"]["output"];
  startTime: Scalars["DateTime"]["output"];
  trades: Scalars["Float"]["output"];
  volume: Scalars["Float"]["output"];
};

export type BatchOrderSingleInput = {
  limitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  lotMatchingInstructions?: InputMaybe<Array<LotMatchingInstructionInput>>;
  maxSellQuantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["input"];
  quantityType: QuantityType;
  side: OrderSide;
  symbol: Scalars["String"]["input"];
};

export enum BatchOrderSource {
  AdminInitiated = "ADMIN_INITIATED",
  AutoTlh = "AUTO_TLH",
  DirectIndex = "DIRECT_INDEX",
  MarginResolver = "MARGIN_RESOLVER",
  Payments = "PAYMENTS",
  PublicApi = "PUBLIC_API",
  Treasury = "TREASURY",
  TreasuryFees = "TREASURY_FEES",
  UserInitiated = "USER_INITIATED",
}

export type BatchTradeOrder = {
  __typename?: "BatchTradeOrder";
  batchOrderMetadataId: Scalars["ID"]["output"];
  orders: Array<Order>;
};

export type BetaValueInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  seedSymbol: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type BillingDatesResponse = {
  __typename?: "BillingDatesResponse";
  endDate: Scalars["Date"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type BusinessEntity = {
  __typename?: "BusinessEntity";
  address: GenericAddress;
  bankingInstitutionNames?: Maybe<Array<Scalars["String"]["output"]>>;
  businessScope: BusinessEntityScope;
  corporationSecretaryName?: Maybe<Scalars["String"]["output"]>;
  entityIndividuals?: Maybe<Array<BusinessEntityIndividual>>;
  entityInvestmentProfile?: Maybe<BusinessEntityInvestmentProfile>;
  entityType: BusinessEntityType;
  faxNumber?: Maybe<Scalars["String"]["output"]>;
  foreignBankUSAgent?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  industry: BusinessEntityIndustry;
  largeTraderIds: Array<Scalars["String"]["output"]>;
  mailingAddress: GenericAddress;
  multiplePartnersOrManagers?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  primaryFundingSource: BusinessEntityFundingSource;
  stateOfIncorporation: Scalars["String"]["output"];
  taxId: Scalars["String"]["output"];
  userTitle: Scalars["String"]["output"];
  w9Data?: Maybe<BusinessEntityW9Data>;
};

export type BusinessEntityAuthorizedUser = {
  __typename?: "BusinessEntityAuthorizedUser";
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
  userTitle: Scalars["String"]["output"];
};

export type BusinessEntityDetails = {
  __typename?: "BusinessEntityDetails";
  address?: Maybe<Array<GenericAddress>>;
  id: Scalars["ID"]["output"];
  industry: BusinessEntityIndustry;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
};

export enum BusinessEntityFundingSource {
  CorporateIncome = "CORPORATE_INCOME",
  InvestmentCapital = "INVESTMENT_CAPITAL",
  Other = "OTHER",
}

export type BusinessEntityIndividual = {
  __typename?: "BusinessEntityIndividual";
  address?: Maybe<Array<GenericAddress>>;
  dateOfBirth: Scalars["Date"]["output"];
  disclosure: IndividualDisclosure;
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  individualType: BusinessEntityIndividualType;
  ssn: Scalars["String"]["output"];
};

export enum BusinessEntityIndividualType {
  BeneficialOwner = "BENEFICIAL_OWNER",
  CompanyOfficer = "COMPANY_OFFICER",
}

export enum BusinessEntityIndustry {
  AgricultureForestryFishing = "AGRICULTURE_FORESTRY_FISHING",
  Construction = "CONSTRUCTION",
  FinanceInsuranceRealEstate = "FINANCE_INSURANCE_REAL_ESTATE",
  Manufacturing = "MANUFACTURING",
  Mining = "MINING",
  PublicAdministration = "PUBLIC_ADMINISTRATION",
  RetailTrade = "RETAIL_TRADE",
  Services = "SERVICES",
  TransportationCommunicationsElectricGasSanitaryServices = "TRANSPORTATION_COMMUNICATIONS_ELECTRIC_GAS_SANITARY_SERVICES",
  WholesaleTrade = "WHOLESALE_TRADE",
}

export type BusinessEntityInfo = {
  __typename?: "BusinessEntityInfo";
  entityAuthorizedUser: BusinessEntityAuthorizedUser;
  entityDetails: BusinessEntityDetails;
  entityIndividuals: Array<BusinessEntityIndividual>;
};

export enum BusinessEntityInvestmentObjective {
  CapitalPreservation = "CAPITAL_PRESERVATION",
  Growth = "GROWTH",
  GrowthIncome = "GROWTH_INCOME",
  Income = "INCOME",
  Speculation = "SPECULATION",
}

export enum BusinessEntityInvestmentPlan {
  ActiveTrading = "ACTIVE_TRADING",
  LongTermInvesting = "LONG_TERM_INVESTING",
  ShortTermInvesting = "SHORT_TERM_INVESTING",
}

export type BusinessEntityInvestmentProfile = {
  __typename?: "BusinessEntityInvestmentProfile";
  annualIncomeMax: Scalars["Int"]["output"];
  annualIncomeMin: Scalars["Int"]["output"];
  depositSource: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  initialDepositAmount: Scalars["Int"]["output"];
  investmentExperience: InvestmentExperience;
  investmentObjective: BusinessEntityInvestmentObjective;
  investmentPlan: BusinessEntityInvestmentPlan;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["output"];
  netWorthMin: Scalars["Int"]["output"];
  riskTolerance: RiskTolerance;
  withdrawFrequency: BusinessEntityWithdrawFrequency;
};

export enum BusinessEntityMailingPreference {
  Business = "BUSINESS",
  Mailing = "MAILING",
  PoBox = "PO_BOX",
}

export enum BusinessEntityScope {
  CommercialRetail = "COMMERCIAL_RETAIL",
  FinancialServices = "FINANCIAL_SERVICES",
  Other = "OTHER",
}

export enum BusinessEntityType {
  CCorporation = "C_CORPORATION",
  LlcCCorporation = "LLC_C_CORPORATION",
  LlcSCorporation = "LLC_S_CORPORATION",
  Lp = "LP",
  StatutoryBusinessTrust = "STATUTORY_BUSINESS_TRUST",
  SCorporation = "S_CORPORATION",
}

export type BusinessEntityW9Data = {
  __typename?: "BusinessEntityW9Data";
  certifyEnteredInformationCorrect: Scalars["Boolean"]["output"];
  certifyNotSubjectToBackupWithholding: Scalars["Boolean"]["output"];
  certifyUSPersonForTax: Scalars["Boolean"]["output"];
  exemptPayeeCode?: Maybe<Scalars["String"]["output"]>;
  exemptionFromFatca?: Maybe<Scalars["String"]["output"]>;
};

export enum BusinessEntityWithdrawFrequency {
  Frequent = "FREQUENT",
  Occasional = "OCCASIONAL",
  Rare = "RARE",
}

export enum BuyBackIndicatorEnumGql {
  None = "None",
  ReplacementShares = "ReplacementShares",
}

export enum CipCategory {
  Address = "ADDRESS",
  Dob = "DOB",
  Name = "NAME",
  TaxId = "TAX_ID",
}

export type CipInvestigationCategory = {
  __typename?: "CIPInvestigationCategory";
  name: CipCategory;
  requestedDocuments?: Maybe<Array<CipInvestigationCategoryRequestDocument>>;
};

export type CipInvestigationCategoryRequestDocument = {
  __typename?: "CIPInvestigationCategoryRequestDocument";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CipInvestigationResult = {
  __typename?: "CIPInvestigationResult";
  categories: Array<CipInvestigationCategory>;
  status: CipInvestigationStatus;
};

export enum CipInvestigationStatus {
  Accepted = "ACCEPTED",
  Appealed = "APPEALED",
  Error = "ERROR",
  Indeterminate = "INDETERMINATE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type CacheBetaValueInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  seedSymbol: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  symbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CalendlyMeetingInput = {
  eventId: Scalars["ID"]["input"];
  inviteeId: Scalars["ID"]["input"];
};

export type CancelAchDepositInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelAchDepositResponse = {
  __typename?: "CancelAchDepositResponse";
  status: CashTransferStatus;
};

export type CancelAchWithdrawalInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelAchWithdrawalResponse = {
  __typename?: "CancelAchWithdrawalResponse";
  status: CashTransferStatus;
};

export type CancelCashTransferInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelCashTransferResponse = {
  __typename?: "CancelCashTransferResponse";
  status: CashTransferStatus;
};

export type CancelLiquidateDirectIndexPortfolioInput = {
  liquidateDirectIndexPortfolioRequestId: Scalars["ID"]["input"];
};

export type CancelOrderInput = {
  orderId: Scalars["ID"]["input"];
};

export type CancelOrderResponse = {
  __typename?: "CancelOrderResponse";
  order: Order;
};

export type CancelWireWithdrawalInput = {
  clearingAccountId: Scalars["ID"]["input"];
  transferId: Scalars["ID"]["input"];
};

export type CancelWireWithdrawalResponse = {
  __typename?: "CancelWireWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export enum CandidateGenerationExecutionFilter {
  Default = "DEFAULT",
  DepositOnly = "DEPOSIT_ONLY",
  LiquidationAndDeposit = "LIQUIDATION_AND_DEPOSIT",
  LiquidationOnly = "LIQUIDATION_ONLY",
}

export type CashDividend = {
  __typename?: "CashDividend";
  amount: Scalars["Decimal"]["output"];
  cashDividendFee: Array<CashDividendFee>;
  clearingAccountId: Scalars["ID"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type CashDividendFee = {
  __typename?: "CashDividendFee";
  amount: Scalars["Decimal"]["output"];
  cashDividendId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: CashDividendFeeType;
};

export enum CashDividendFeeType {
  AgencyProcessingFee = "AGENCY_PROCESSING_FEE",
  MiscellaneousFee = "MISCELLANEOUS_FEE",
}

export type CashRewardType = {
  __typename?: "CashRewardType";
  amount: Scalars["Decimal"]["output"];
};

export type CashTransfer = {
  __typename?: "CashTransfer";
  amount: Scalars["Decimal"]["output"];
  automated: Scalars["Boolean"]["output"];
  cashTransferFee?: Maybe<Array<CashTransferFee>>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  destinationDepositAccount?: Maybe<DepositAccount>;
  direction: CashTransferDirection;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  method: CashTransferMethod;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  sourceDepositAccount?: Maybe<DepositAccount>;
  sourceType: CashTransferSourceType;
  status: CashTransferStatus;
  statusEventTime: Scalars["DateTime"]["output"];
  statusHistory?: Maybe<Array<CashTransferStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export enum CashTransferDirection {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export type CashTransferFee = {
  __typename?: "CashTransferFee";
  clearingHouseCredit?: Maybe<Scalars["Decimal"]["output"]>;
  correspondentNetAmount?: Maybe<Scalars["Decimal"]["output"]>;
  customerDebit?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  type: CashTransferFeeType;
};

export enum CashTransferFeeType {
  Operating = "OPERATING",
  Return = "RETURN",
  Unknown = "UNKNOWN",
}

export enum CashTransferMethod {
  Ach = "ACH",
  Check = "CHECK",
  Wire = "WIRE",
}

export enum CashTransferSourceType {
  AdminInitiated = "ADMIN_INITIATED",
  Allocation = "ALLOCATION",
  AllocationPeriodicDeposit = "ALLOCATION_PERIODIC_DEPOSIT",
  DirectIndex = "DIRECT_INDEX",
  Loan = "LOAN",
  MarginInterestAutoPayment = "MARGIN_INTEREST_AUTO_PAYMENT",
  MarginResolver = "MARGIN_RESOLVER",
  PaymentExecution = "PAYMENT_EXECUTION",
  PublicApi = "PUBLIC_API",
  Scheduled = "SCHEDULED",
  TreasuryExecution = "TREASURY_EXECUTION",
  TreasurySweep = "TREASURY_SWEEP",
  Unknown = "UNKNOWN",
  UserInitiated = "USER_INITIATED",
}

export enum CashTransferStatus {
  Canceled = "CANCELED",
  CancelPending = "CANCEL_PENDING",
  CancelRejected = "CANCEL_REJECTED",
  CancelRequested = "CANCEL_REQUESTED",
  Completed = "COMPLETED",
  FailedAtBank = "FAILED_AT_BANK",
  FundsPosted = "FUNDS_POSTED",
  OpsApproved = "OPS_APPROVED",
  OpsRejected = "OPS_REJECTED",
  OpsReviewRequired = "OPS_REVIEW_REQUIRED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
  Undefined = "UNDEFINED",
  UserInitiated = "USER_INITIATED",
}

export type CashTransferStatusHistory = {
  __typename?: "CashTransferStatusHistory";
  bankConfirmationId?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  externalStatus?: Maybe<Scalars["String"]["output"]>;
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  externalStatusTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonDesc?: Maybe<Scalars["String"]["output"]>;
  reasonDetail?: Maybe<Scalars["String"]["output"]>;
  reasonRecommendation?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type CashTransferToDirectIndexPortfolioInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  /** @deprecated use sourceId instead */
  depositPlaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  source: MoneyMovementSourceType;
  sourceId?: InputMaybe<Scalars["ID"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum CategorizationType {
  Custodian = "CUSTODIAN",
  CCorporation = "C_CORPORATION",
  FirmAccount = "FIRM_ACCOUNT",
  Individual = "INDIVIDUAL",
  Ira = "IRA",
  Joint = "JOINT",
  LimitedLiabilityCompanyCCorporation = "LIMITED_LIABILITY_COMPANY_C_CORPORATION",
  LimitedLiabilityCompanyPartnership = "LIMITED_LIABILITY_COMPANY_PARTNERSHIP",
  LimitedLiabilityCompanySCorporation = "LIMITED_LIABILITY_COMPANY_S_CORPORATION",
  Solo_401K = "SOLO_401K",
  StatutoryBusinessTrust = "STATUTORY_BUSINESS_TRUST",
  SCorporation = "S_CORPORATION",
  Unspecified = "UNSPECIFIED",
}

export type CheckDayTradeInput = {
  clearingAccountId: Scalars["ID"]["input"];
  currentSellOrder?: InputMaybe<SellOrder>;
  range?: InputMaybe<DateRange>;
};

export type ClearingAccount = {
  __typename?: "ClearingAccount";
  accountType?: Maybe<AccountType>;
  accountUser: Array<ClearingAccountUser>;
  actionContexts?: Maybe<Array<ActionContextStatus>>;
  activeAllocationConfig?: Maybe<AllocationConfig>;
  activeScheduledDepositConfigs?: Maybe<Array<ScheduledDepositConfig>>;
  activity?: Maybe<PaginatedActivity>;
  cashDividend?: Maybe<CashDividend>;
  cashDividendsForHolding?: Maybe<Array<CashDividend>>;
  cashTransfer?: Maybe<CashTransfer>;
  catAccountHolderType?: Maybe<AccountHolderType>;
  categorizationType?: Maybe<CategorizationType>;
  cipInvestigationResult?: Maybe<CipInvestigationResult>;
  dailyCashDividend?: Maybe<DailyCashDividend>;
  demoActiveScheduledDepositConfigs?: Maybe<Array<ScheduledDepositConfig>>;
  demoCashTransfer?: Maybe<CashTransfer>;
  demoDailyCashDividend?: Maybe<DailyCashDividend>;
  demoIntraAccountCashTransfer?: Maybe<IntraAccountCashTransfer>;
  demoIntraAccountStockTransfer?: Maybe<IntraAccountStockTransfer>;
  demoSelfManagedTaxLossConfig?: Maybe<SelfManagedTaxLossConfig>;
  demoSimpleActivity?: Maybe<PaginatedSimpleActivity>;
  depositAccounts?: Maybe<Array<DepositAccount>>;
  dividendPreference?: Maybe<Array<DividendPreference>>;
  dividendSummary?: Maybe<DividendSummary>;
  entity?: Maybe<ClearingAccountEntity>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  externalClearingAccountId: Scalars["ID"]["output"];
  fdid: Scalars["ID"]["output"];
  getAccountAcatsTransfers?: Maybe<PaginatedAcatsTransferResponse>;
  id: Scalars["ID"]["output"];
  intraAccountCashTransfer?: Maybe<IntraAccountCashTransfer>;
  intraAccountStockTransfer?: Maybe<IntraAccountStockTransfer>;
  intraDayMarginCallData?: Maybe<IntraDayMarginCallData>;
  investmentSettings?: Maybe<InvestmentSettings>;
  isClientAccount?: Maybe<Scalars["Boolean"]["output"]>;
  isPatternDayTrader?: Maybe<Scalars["Boolean"]["output"]>;
  isTestAccount?: Maybe<Scalars["Boolean"]["output"]>;
  marginCallData?: Maybe<MarginCallData>;
  marginInterestCharge?: Maybe<MarginInterestCharge>;
  order?: Maybe<Order>;
  paymentExecution?: Maybe<PaymentExecution>;
  primarySubAccountId: Scalars["String"]["output"];
  primaryUser?: Maybe<User>;
  realizedLotsSummary?: Maybe<RealizedLotsSummary>;
  recentDirectIndexDeposits?: Maybe<Array<ScheduledDepositExecution>>;
  recentPayments?: Maybe<Array<PaymentExecution>>;
  restrictionHistory?: Maybe<Array<ClearingAccountRestriction>>;
  restrictions?: Maybe<Array<ClearingAccountRestriction>>;
  scheduledRewards?: Maybe<Array<ScheduledReward>>;
  selfManagedTaxLossConfig?: Maybe<SelfManagedTaxLossConfig>;
  simpleActivity?: Maybe<PaginatedSimpleActivity>;
  simpleActivityById?: Maybe<SimpleActivity>;
  stockAdjustment?: Maybe<StockAdjustmentDetails>;
  stockAdjustmentsForHolding?: Maybe<Array<StockAdjustmentDetails>>;
  stockLendingInfo?: Maybe<StockLendingInfo>;
  subAccounts: Array<SubAccount>;
  taxLotEntries?: Maybe<Array<TaxLotEntry>>;
  tradeRestrictions?: Maybe<Array<TradeRestriction>>;
  transferOnDeathBeneficiaries?: Maybe<Array<TransferOnDeathBeneficiary>>;
  treasury?: Maybe<TreasuryConfig>;
  userInvestorDocuments?: Maybe<GetUserInvestorDocumentsResponse>;
};

export type ClearingAccountActiveScheduledDepositConfigsArgs = {
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClearingAccountActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountCashDividendsForHoldingArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  securityId: Scalars["ID"]["input"];
};

export type ClearingAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDailyCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoActiveScheduledDepositConfigsArgs = {
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClearingAccountDemoCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoDailyCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoIntraAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoIntraAccountStockTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoSimpleActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountDividendSummaryArgs = {
  input: DividendSummaryInput;
};

export type ClearingAccountGetAccountAcatsTransfersArgs = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ClearingAccountIntraAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountIntraAccountStockTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountMarginInterestChargeArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountPaymentExecutionArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountRealizedLotsSummaryArgs = {
  input?: InputMaybe<RealizedLotsSummaryInput>;
};

export type ClearingAccountSimpleActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountSimpleActivityByIdArgs = {
  id: Scalars["ID"]["input"];
  type: ActivityType;
};

export type ClearingAccountStockAdjustmentArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountStockAdjustmentsForHoldingArgs = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  securityId: Scalars["ID"]["input"];
};

export type ClearingAccountTaxLotEntriesArgs = {
  input?: InputMaybe<TaxLotInput>;
};

export type ClearingAccountUserInvestorDocumentsArgs = {
  category: InvestorDocumentCategory;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClearingAccountEntity = BusinessEntityInfo | TrustEntity;

export enum ClearingAccountIdType {
  ExternalId = "EXTERNAL_ID",
  Id = "ID",
}

export type ClearingAccountInput = {
  id: Scalars["ID"]["input"];
  type: ClearingAccountIdType;
};

export enum ClearingAccountMetadataRequestType {
  AccountClose = "ACCOUNT_CLOSE",
  AccountCreate = "ACCOUNT_CREATE",
  AccountReopen = "ACCOUNT_REOPEN",
  AccountUpdate = "ACCOUNT_UPDATE",
}

export type ClearingAccountQaDataInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  primaryUserId: Scalars["String"]["input"];
};

export enum ClearingAccountRequestStatus {
  RequestActionRequired = "REQUEST_ACTION_REQUIRED",
  RequestCanceled = "REQUEST_CANCELED",
  RequestComplete = "REQUEST_COMPLETE",
  RequestError = "REQUEST_ERROR",
  RequestNew = "REQUEST_NEW",
  RequestOpsRejected = "REQUEST_OPS_REJECTED",
  RequestOpsRetry = "REQUEST_OPS_RETRY",
  RequestPending = "REQUEST_PENDING",
  RequestRejected = "REQUEST_REJECTED",
  RequestUserInitiated = "REQUEST_USER_INITIATED",
}

export type ClearingAccountRestriction = {
  __typename?: "ClearingAccountRestriction";
  active: Scalars["Boolean"]["output"];
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  restrictionType: ClearingAccountRestrictionType;
};

export enum ClearingAccountRestrictionType {
  CashTransferReview = "CASH_TRANSFER_REVIEW",
  Closed = "CLOSED",
  Closing = "CLOSING",
  ForcedLiquidation = "FORCED_LIQUIDATION",
  Frozen = "FROZEN",
  MarginCall = "MARGIN_CALL",
  PatternDayTrade = "PATTERN_DAY_TRADE",
  Pending = "PENDING",
  RebalanceCall = "REBALANCE_CALL",
  ReconBreak = "RECON_BREAK",
  Rejected = "REJECTED",
  RestrictTransfersOut = "RESTRICT_TRANSFERS_OUT",
}

export type ClearingAccountUser = {
  __typename?: "ClearingAccountUser";
  type: ClearingAccountUserType;
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export enum ClearingAccountUserType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum ClearingHouseId {
  Apex = "APEX",
  Drivewealth = "DRIVEWEALTH",
}

export type ComparisonPortfolioSnapshot = {
  __typename?: "ComparisonPortfolioSnapshot";
  clearingAccountId: Scalars["ID"]["output"];
  date: Scalars["DateTime"]["output"];
  snapshotHoldingClosingValue: Scalars["Decimal"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type CompletedInviteStatusEntry = {
  __typename?: "CompletedInviteStatusEntry";
  eventTime: Scalars["DateTime"]["output"];
  inviteCodeApplicationId: Scalars["String"]["output"];
  redactedRefereeFullName: Scalars["String"]["output"];
  refereeUserId?: Maybe<Scalars["String"]["output"]>;
  referrerUserId?: Maybe<Scalars["String"]["output"]>;
  rewards: Array<ReferralRewardDetails>;
  userReferralDeal?: Maybe<UserReferralDeal>;
};

export type CompletedInvitesForUserInput = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstantMmfData = {
  __typename?: "ConstantMMFData";
  cusip: Scalars["String"]["output"];
  fundLink: Scalars["String"]["output"];
  inceptionDate: Scalars["DateTime"]["output"];
  minInvestment: Scalars["Int"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum CoreExchangeAccountType {
  Deposit = "DEPOSIT",
  Investment = "INVESTMENT",
  Loan = "LOAN",
  Loc = "LOC",
}

export enum CorporateActionExchange {
  Arcx = "ARCX",
  Bats = "BATS",
  Xase = "XASE",
  Xnas = "XNAS",
  Xnys = "XNYS",
}

export enum CorporateActionPayType {
  Cash = "CASH",
  DissentersRights = "DISSENTERS_RIGHTS",
  Stock = "STOCK",
  StockAndCash = "STOCK_AND_CASH",
  Unknown = "UNKNOWN",
}

export enum CorporateActionSourceType {
  Ext235 = "EXT235",
  Ops = "OPS",
  Xignite = "XIGNITE",
}

export enum CorporateActionStatus {
  Created = "CREATED",
  Processed = "PROCESSED",
  Skipped = "SKIPPED",
}

export enum CorporateActionType {
  CashMerger = "CASH_MERGER",
  Other = "OTHER",
  ReturnOfCapital = "RETURN_OF_CAPITAL",
  ReverseSplit = "REVERSE_SPLIT",
  SpinOff = "SPIN_OFF",
  Split = "SPLIT",
  StockAndCashMerger = "STOCK_AND_CASH_MERGER",
  StockMerger = "STOCK_MERGER",
  SymbolChange = "SYMBOL_CHANGE",
  Takeover = "TAKEOVER",
}

export type CreateDirectIndexSubAccountInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  /** @deprecated use sourceId instead */
  depositPlaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  loanPercentage?: InputMaybe<Scalars["Decimal"]["input"]>;
  lotsToTransferBySymbol?: InputMaybe<Array<LotsToTransferBySymbolInput>>;
  source?: InputMaybe<MoneyMovementSourceType>;
  sourceId?: InputMaybe<Scalars["ID"]["input"]>;
  tradeRestrictions?: InputMaybe<Array<TradeRestrictionSecurityInput>>;
  type: DirectIndexType;
};

export type CreateJointAccountAndInviteCoOwnerResponse = {
  __typename?: "CreateJointAccountAndInviteCoOwnerResponse";
  clearingAccountId: Scalars["ID"]["output"];
};

export type CreateJointAccountClearingHouseAccountResponse = {
  __typename?: "CreateJointAccountClearingHouseAccountResponse";
  clearingAccountId: Scalars["ID"]["output"];
};

export type CreateLinkedBrokerageAccountInput = {
  accountNumber: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nsccNumber: Scalars["String"]["input"];
};

export type CreateLoanForInterestInput = {
  billingPeriodStr?: InputMaybe<Scalars["String"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreatePendingLoanInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  cashTransferMethod?: InputMaybe<CashTransferMethod>;
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  destinationId?: InputMaybe<Scalars["ID"]["input"]>;
  loanType?: InputMaybe<LoanType>;
  pendingAcatsIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  pendingBatchOrderMetadataID?: InputMaybe<Scalars["ID"]["input"]>;
  pendingCashTransferID?: InputMaybe<Scalars["ID"]["input"]>;
  pendingLoanType?: InputMaybe<PendingLoanType>;
};

export type Credit = {
  __typename?: "Credit";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  contraAccount: Scalars["String"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  inviteCodeDeal?: Maybe<UserReferralDeal>;
  source?: Maybe<CreditSource>;
  status: CreditStatus;
  subAccountId: Scalars["ID"]["output"];
  type: CreditType;
};

export enum CreditSource {
  DiReferral = "DI_REFERRAL",
}

export enum CreditStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Created = "CREATED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
}

export enum CreditType {
  Courtesy = "COURTESY",
  FeeReversal = "FEE_REVERSAL",
  PromoCredit = "PROMO_CREDIT",
  Rebate = "REBATE",
  WriteOff = "WRITE_OFF",
  ZeroingAccount = "ZEROING_ACCOUNT",
}

export type CumulativeNetDeposit = {
  __typename?: "CumulativeNetDeposit";
  date: Scalars["Date"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type DailyCashDividend = {
  __typename?: "DailyCashDividend";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  dividends: Array<DividendSecurity>;
  effectiveTime: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  fees: Array<DividendSecurityFee>;
  id: Scalars["ID"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type DailyMarginInterestCharge = {
  __typename?: "DailyMarginInterestCharge";
  date: Scalars["Date"]["output"];
  loan: Scalars["Decimal"]["output"];
  marginInterest: Scalars["Decimal"]["output"];
  marginInterestCharge: Scalars["Decimal"]["output"];
};

export enum DateInterval {
  Day = "DAY",
  Month = "MONTH",
  Quarter = "QUARTER",
  Week = "WEEK",
  Year = "YEAR",
}

export type DatePeriod = {
  __typename?: "DatePeriod";
  endDate: Scalars["Date"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type DatePeriodArgs = {
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type DateRange = {
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type DayTradeResponse = {
  __typename?: "DayTradeResponse";
  currentTradeFlagged: Scalars["Boolean"]["output"];
  dayRange: Scalars["Int"]["output"];
  dayTrades: Scalars["Int"]["output"];
  totalTrades: Scalars["Int"]["output"];
};

export type DeleteBusinessEntityIndividualInput = {
  id: Scalars["ID"]["input"];
};

export type DepositAccount = {
  __typename?: "DepositAccount";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  apexRelationshipId?: Maybe<Scalars["String"]["output"]>;
  apexRelationshipStatus?: Maybe<Scalars["String"]["output"]>;
  clearingAccountId?: Maybe<Scalars["ID"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId?: Maybe<Scalars["ID"]["output"]>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
  subType: Scalars["String"]["output"];
  wireRoutingNumber?: Maybe<Scalars["String"]["output"]>;
};

export type DepositAccountInput = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  clearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  plaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  subType: Scalars["String"]["input"];
  wireRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DepositAccountStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type DetailedQuote = {
  __typename?: "DetailedQuote";
  ask?: Maybe<Scalars["Float"]["output"]>;
  askMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  askSize?: Maybe<Scalars["Int"]["output"]>;
  askTime?: Maybe<Scalars["DateTime"]["output"]>;
  bid?: Maybe<Scalars["Float"]["output"]>;
  bidMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  bidSize?: Maybe<Scalars["Int"]["output"]>;
  bidTime?: Maybe<Scalars["DateTime"]["output"]>;
  changeFromPreviousClose: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  delay: Scalars["Float"]["output"];
  high: Scalars["Float"]["output"];
  high52Weeks: Scalars["Float"]["output"];
  identifier: Scalars["String"]["output"];
  identifierType: Scalars["String"]["output"];
  last: Scalars["Float"]["output"];
  lastMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  lastSize: Scalars["Int"]["output"];
  low: Scalars["Float"]["output"];
  low52Weeks: Scalars["Float"]["output"];
  message: Scalars["String"]["output"];
  open: Scalars["Float"]["output"];
  outcome: Scalars["String"]["output"];
  percentChangeFromPreviousClose: Scalars["Float"]["output"];
  previousClose: Scalars["Float"]["output"];
  previousCloseDate: Scalars["Date"]["output"];
  security: DetailedSecurity;
  time: Scalars["DateTime"]["output"];
  tradingHalted: Scalars["Boolean"]["output"];
  volume: Scalars["Int"]["output"];
};

export type DetailedQuoteInput = {
  clearingAccountIdForDisplay?: InputMaybe<Scalars["String"]["input"]>;
  realTime: Scalars["Boolean"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type DetailedSecurity = {
  __typename?: "DetailedSecurity";
  CIK?: Maybe<Scalars["String"]["output"]>;
  CUSIP?: Maybe<Scalars["String"]["output"]>;
  ISIN?: Maybe<Scalars["String"]["output"]>;
  categoryOrIndustry?: Maybe<Scalars["String"]["output"]>;
  market: Scalars["String"]["output"];
  marketIdentificationCode: Scalars["String"]["output"];
  mostLiquidExchange: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
  valoren?: Maybe<Scalars["String"]["output"]>;
};

export type DirectIndexAccountInfo = {
  __typename?: "DirectIndexAccountInfo";
  cantBuy: Array<Scalars["String"]["output"]>;
  cantSell: Array<Scalars["String"]["output"]>;
  diCash: Scalars["Decimal"]["output"];
  settings?: Maybe<DirectIndexSubAccountSettings>;
  stockInfo: Array<DirectIndexStockInfo>;
};

export type DirectIndexAccountInfoInput = {
  cantBuy: Array<Scalars["String"]["input"]>;
  cantSell: Array<Scalars["String"]["input"]>;
  diCash: Scalars["Decimal"]["input"];
  stockInfo: Array<DirectIndexStockInfoInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type DirectIndexAccountMetadata = {
  __typename?: "DirectIndexAccountMetadata";
  chartCompareTo?: Maybe<DirectIndexChartCompareTo>;
  customization?: Maybe<DirectIndexCustomization>;
  etfSymbol?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lhfOverride?: Maybe<Scalars["Decimal"]["output"]>;
  signedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<DirectIndexStatus>;
  subAccountId: Scalars["ID"]["output"];
  trackingPreference?: Maybe<DirectIndexTrackingPreference>;
  type: DirectIndexType;
};

export type DirectIndexAccountWeights = {
  __typename?: "DirectIndexAccountWeights";
  subAccountId: Scalars["ID"]["output"];
  weights: Array<DirectIndexWeight>;
};

export enum DirectIndexAnalysisAccount {
  Betterment = "BETTERMENT",
  CharlesSchwab = "CHARLES_SCHWAB",
  Fidelity = "FIDELITY",
  Frec = "FREC",
  Other = "OTHER",
  Wealthfront = "WEALTHFRONT",
}

export type DirectIndexAnalysisRequest = {
  __typename?: "DirectIndexAnalysisRequest";
  accountType?: Maybe<DirectIndexAnalysisAccount>;
  calendlyMeetingId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  meeting?: Maybe<MeetingDetails>;
  status: DirectIndexAnalysisRequestStatus;
  totalPortfolioValue?: Maybe<Scalars["Decimal"]["output"]>;
  uploadedDocumentId?: Maybe<Scalars["ID"]["output"]>;
  userId: Scalars["ID"]["output"];
};

export type DirectIndexAnalysisRequestInput = {
  accountType?: InputMaybe<DirectIndexAnalysisAccount>;
  calendlyMeetingId?: InputMaybe<Scalars["ID"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  totalPortfolioValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  uploadedDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  userId: Scalars["ID"]["input"];
};

export enum DirectIndexAnalysisRequestStatus {
  Complete = "COMPLETE",
  Initiated = "INITIATED",
}

export enum DirectIndexAttribute {
  AddSecurityId = "ADD_SECURITY_ID",
  RemoveGicsSector = "REMOVE_GICS_SECTOR",
  RemoveSecurityId = "REMOVE_SECURITY_ID",
}

export enum DirectIndexCandidateGenerationRunMode {
  AllowBbsWashSales = "ALLOW_BBS_WASH_SALES",
  AllowWashSales = "ALLOW_WASH_SALES",
  Default = "DEFAULT",
  EstimateTaxImpact = "ESTIMATE_TAX_IMPACT",
  LiquidateZeroTargets = "LIQUIDATE_ZERO_TARGETS",
  LiquidateZeroTargetsRebalance = "LIQUIDATE_ZERO_TARGETS_REBALANCE",
  LiquidateZeroTargetsRebalanceWashSales = "LIQUIDATE_ZERO_TARGETS_REBALANCE_WASH_SALES",
  Rebalance = "REBALANCE",
}

export enum DirectIndexCandidateSkipReason {
  ClearingAccountRestricted = "CLEARING_ACCOUNT_RESTRICTED",
  DiAccountClosed = "DI_ACCOUNT_CLOSED",
  DiAccountNotReady = "DI_ACCOUNT_NOT_READY",
  DiAccountPaused = "DI_ACCOUNT_PAUSED",
  ErrorFetchingStockInfo = "ERROR_FETCHING_STOCK_INFO",
  ErrorRunningOptimization = "ERROR_RUNNING_OPTIMIZATION",
  ExecutionFilterRequested = "EXECUTION_FILTER_REQUESTED",
  HoldingsTaxLotMismatch = "HOLDINGS_TAX_LOT_MISMATCH",
  InvalidLiquidateResponse = "INVALID_LIQUIDATE_RESPONSE",
  MultipleLiquidationRequestsFound = "MULTIPLE_LIQUIDATION_REQUESTS_FOUND",
  MultiIndexConflict = "MULTI_INDEX_CONFLICT",
  NoCandidatesGenerated = "NO_CANDIDATES_GENERATED",
  SuccessfulDiRunFoundForToday = "SUCCESSFUL_DI_RUN_FOUND_FOR_TODAY",
  UnhandledError = "UNHANDLED_ERROR",
}

export enum DirectIndexChartCompareTo {
  Etf = "ETF",
  Reinvested = "REINVESTED",
}

export type DirectIndexCustomization = {
  __typename?: "DirectIndexCustomization";
  addSecurityIds: Array<IndexCustomizationSecurity>;
  id: Scalars["ID"]["output"];
  removeGICSSectorIds: Array<GicsCode>;
  removeSecurityIds: Array<IndexCustomizationSecurity>;
};

export type DirectIndexCustomizationInput = {
  addSecuritySymbols: Array<Scalars["String"]["input"]>;
  removeGICSSectorIds: Array<GicsCode>;
  removeSecuritySymbols: Array<Scalars["String"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum DirectIndexFeeBilledTo {
  DirectIndexAssets = "DIRECT_INDEX_ASSETS",
  DirectIndexCash = "DIRECT_INDEX_CASH",
  Loan = "LOAN",
}

export type DirectIndexFeeCharge = {
  __typename?: "DirectIndexFeeCharge";
  amount: Scalars["Decimal"]["output"];
  billedTo: DirectIndexFeeBilledTo;
  billingEndDate: Scalars["DateTime"]["output"];
  billingStartDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export enum DirectIndexFeeStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type DirectIndexLtvResponse = {
  __typename?: "DirectIndexLTVResponse";
  initialLTV: Scalars["Decimal"]["output"];
  maintenanceLTV: Scalars["Decimal"]["output"];
};

export type DirectIndexOnCreateCustomizationInput = {
  addSecuritySymbols: Array<Scalars["String"]["input"]>;
  removeGICSSectorIds: Array<GicsCode>;
  removeSecuritySymbols: Array<Scalars["String"]["input"]>;
};

export type DirectIndexOptimizationRunData = {
  __typename?: "DirectIndexOptimizationRunData";
  dayTradeCantSell: Array<Scalars["String"]["output"]>;
  lossHarvestCantBuy: Array<Scalars["String"]["output"]>;
  missingCostCantSell: Array<Scalars["String"]["output"]>;
  postProcessedResponse: DirectIndexOptimizeResult;
  rawResponse: DirectIndexOptimizeResult;
  valueCantBuy: Array<Scalars["String"]["output"]>;
  washSalesCantBuy: Array<Scalars["String"]["output"]>;
  washSalesCantSell: Array<Scalars["String"]["output"]>;
};

export type DirectIndexOptimizeResult = {
  __typename?: "DirectIndexOptimizeResult";
  cashDelta: Scalars["Decimal"]["output"];
  finalRisk?: Maybe<Scalars["Decimal"]["output"]>;
  initialRisk?: Maybe<Scalars["Decimal"]["output"]>;
  quantityDelta: Array<DirectIndexQuantityDelta>;
};

export type DirectIndexOptions = {
  allowGains?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidBBSWashSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidBuyingSymbolsWithLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidDayTrades?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidSmallWeightPositions?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidWashSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  diFeeBPS?: InputMaybe<Scalars["Decimal"]["input"]>;
  lossHarvestingFactor?: InputMaybe<Scalars["Decimal"]["input"]>;
  maxPercentageToPreventBuying?: InputMaybe<Scalars["Decimal"]["input"]>;
  minCashBuffer?: InputMaybe<Scalars["Decimal"]["input"]>;
  minimumTradeAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  minimumValueForInvestment?: InputMaybe<Scalars["Decimal"]["input"]>;
  transactionCosts?: InputMaybe<Scalars["Decimal"]["input"]>;
  useCovarianceMatrix?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DirectIndexOverlap = {
  __typename?: "DirectIndexOverlap";
  directIndexType: DirectIndexType;
  overlapPercent: Scalars["Decimal"]["output"];
};

export type DirectIndexOverrideInput = {
  overrides: Array<DirectIndexOverrideSecurityInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type DirectIndexOverrideSecurityInput = {
  canLiquidate: Scalars["Boolean"]["input"];
  canPurchase: Scalars["Boolean"]["input"];
  symbol: Scalars["String"]["input"];
  weight: Scalars["Decimal"]["input"];
};

export type DirectIndexPerformanceData = {
  __typename?: "DirectIndexPerformanceData";
  comparisonPortfolioHistory?: Maybe<Array<ComparisonPortfolioSnapshot>>;
  directIndexHistory: Array<PortfolioSnapshot>;
  periods: Array<DirectIndexPerformanceDataPeriod>;
  reinvestPortfolioDeltaHistory?: Maybe<Array<ReinvestPortfolioDelta>>;
};

export type DirectIndexPerformanceDataPeriod = {
  __typename?: "DirectIndexPerformanceDataPeriod";
  cumulativeNetDeposits: Array<CumulativeNetDeposit>;
  estimatedTaxSavings?: Maybe<Scalars["Decimal"]["output"]>;
  longTermGains?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  longWashSalesDisallowed?: Maybe<Scalars["Decimal"]["output"]>;
  moneyWeightedReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
  netDeposits?: Maybe<Scalars["Decimal"]["output"]>;
  netLongTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  netShortTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  period: Scalars["String"]["output"];
  shortTermGains?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  shortWashSalesDisallowed?: Maybe<Scalars["Decimal"]["output"]>;
  simpleReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
  taxLossesHarvested?: Maybe<Scalars["Decimal"]["output"]>;
  timeWeightedReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
};

export type DirectIndexPreferenceInput = {
  chartCompareTo?: InputMaybe<DirectIndexChartCompareTo>;
  subAccountId: Scalars["ID"]["input"];
  trackingPreference?: InputMaybe<DirectIndexTrackingPreference>;
};

export type DirectIndexQuantityDelta = {
  __typename?: "DirectIndexQuantityDelta";
  delta: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type DirectIndexSearchResult = {
  __typename?: "DirectIndexSearchResult";
  indexName: Scalars["String"]["output"];
  indexType: DirectIndexType;
};

export enum DirectIndexSecurityOverrideSource {
  Admin = "ADMIN",
  CustomFixedWeight = "CUSTOM_FIXED_WEIGHT",
  CustomMarketCap = "CUSTOM_MARKET_CAP",
  TradeRestriction = "TRADE_RESTRICTION",
}

export type DirectIndexSimulationAction = {
  __typename?: "DirectIndexSimulationAction";
  direction: Scalars["String"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  proceeds: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type DirectIndexSimulationHolding = {
  __typename?: "DirectIndexSimulationHolding";
  cost: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  targetWeight: Scalars["Decimal"]["output"];
};

export type DirectIndexSimulationOutputOptions = {
  summarize: Scalars["Boolean"]["input"];
  trackHistory: Scalars["Boolean"]["input"];
};

export type DirectIndexSimulationRunInfo = {
  __typename?: "DirectIndexSimulationRunInfo";
  date: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  multiIndexSimulationId?: Maybe<Scalars["String"]["output"]>;
  serializedArgs: Scalars["String"]["output"];
  status: DirectIndexSimulationStatus;
};

export type DirectIndexSimulationRunInfoById = {
  __typename?: "DirectIndexSimulationRunInfoById";
  info: DirectIndexSimulationRunInfo;
  serializedResult?: Maybe<Scalars["String"]["output"]>;
};

export type DirectIndexSimulationState = {
  __typename?: "DirectIndexSimulationState";
  cash: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
  holdings: Array<DirectIndexSimulationHolding>;
  logData: DirectIndexSimulationStateLogData;
};

export type DirectIndexSimulationStateLogData = {
  __typename?: "DirectIndexSimulationStateLogData";
  deposits: Scalars["Decimal"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  stockValue: Scalars["Decimal"]["output"];
};

export enum DirectIndexSimulationStatus {
  Cancelled = "CANCELLED",
  Cancelling = "CANCELLING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Running = "RUNNING",
  Submitted = "SUBMITTED",
}

export enum DirectIndexSolver {
  Barra = "barra",
  BnbCvxopt = "bnb_cvxopt",
  BnbOsqp = "bnb_osqp",
  BnbProxqp = "bnb_proxqp",
  BnbPyomoMosek = "bnb_pyomo_mosek",
  MiqpCplexDirect = "miqp_cplex_direct",
  MiqpGurobi = "miqp_gurobi",
  MiqpMosek = "miqp_mosek",
}

export type DirectIndexSolverOptions = {
  forceLiquidateZeroTargets?: InputMaybe<Scalars["Boolean"]["input"]>;
  solver?: InputMaybe<DirectIndexSolver>;
  timeoutMs?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DirectIndexStatus {
  Closed = "CLOSED",
  Init = "INIT",
  InitialFundingFailed = "INITIAL_FUNDING_FAILED",
  InitialFundingPending = "INITIAL_FUNDING_PENDING",
  Paused = "PAUSED",
  Running = "RUNNING",
}

export type DirectIndexStockInfo = {
  __typename?: "DirectIndexStockInfo";
  barraId?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Decimal"]["output"];
  secondaryTaxLots?: Maybe<Array<DirectIndexTaxLot>>;
  symbol: Scalars["String"]["output"];
  targetWeight: Scalars["Decimal"]["output"];
  taxLots: Array<DirectIndexTaxLot>;
};

export type DirectIndexStockInfoInput = {
  barraId?: InputMaybe<Scalars["String"]["input"]>;
  price: Scalars["Decimal"]["input"];
  symbol: Scalars["String"]["input"];
  targetWeight: Scalars["Decimal"]["input"];
  taxLots: Array<DirectIndexTaxLotInput>;
};

export type DirectIndexSubAccountSettings = {
  __typename?: "DirectIndexSubAccountSettings";
  avoidBBSWashSales?: Maybe<Scalars["Boolean"]["output"]>;
  lhfOverride?: Maybe<Scalars["Decimal"]["output"]>;
};

export type DirectIndexTaxLot = {
  __typename?: "DirectIndexTaxLot";
  buyBackIndicator: BuyBackIndicatorEnumGql;
  closedLotId?: Maybe<Scalars["String"]["output"]>;
  closedTransactionId?: Maybe<Scalars["String"]["output"]>;
  cost: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
  netProceed: Scalars["Decimal"]["output"];
  openBuyCostAmount: Scalars["Decimal"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  openLotId: Scalars["String"]["output"];
  openTransactionId: Scalars["String"]["output"];
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss: Scalars["Decimal"]["output"];
  realizedIndicator: RealizedIndicatorEnumGql;
  securityId: Scalars["String"]["output"];
  settlementDate?: Maybe<Scalars["Date"]["output"]>;
  subAccountId: Scalars["String"]["output"];
  taxLotCloseSellDate?: Maybe<Scalars["Date"]["output"]>;
  taxLotOpenBuyDate?: Maybe<Scalars["Date"]["output"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["output"];
  washSaleAdjustmentDate?: Maybe<Scalars["Date"]["output"]>;
  washSalesDisallowed: Scalars["Decimal"]["output"];
  washSalesIndicator: WashSaleIndicatorEnumGql;
};

export type DirectIndexTaxLotInput = {
  buyBackIndicator: BuyBackIndicatorEnumGql;
  closedLotId?: InputMaybe<Scalars["String"]["input"]>;
  closedTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  cost: Scalars["Decimal"]["input"];
  eventTime: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
  netProceed: Scalars["Decimal"]["input"];
  openBuyCostAmount: Scalars["Decimal"]["input"];
  openBuyPrice: Scalars["Decimal"]["input"];
  openLotId: Scalars["String"]["input"];
  openTransactionId: Scalars["String"]["input"];
  quantity: Scalars["Decimal"]["input"];
  realizedGainLoss: Scalars["Decimal"]["input"];
  realizedIndicator: RealizedIndicatorEnumGql;
  securityId: Scalars["String"]["input"];
  settlementDate?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId: Scalars["String"]["input"];
  taxLotCloseSellDate?: InputMaybe<Scalars["Date"]["input"]>;
  taxLotOpenBuyDate?: InputMaybe<Scalars["Date"]["input"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["input"];
  washSaleAdjustmentDate?: InputMaybe<Scalars["Date"]["input"]>;
  washSalesDisallowed: Scalars["Decimal"]["input"];
  washSalesIndicator: WashSaleIndicatorEnumGql;
};

export enum DirectIndexTrackingPreference {
  Default = "DEFAULT",
  LossHarvestingPriority = "LOSS_HARVESTING_PRIORITY",
  TrackingPriority = "TRACKING_PRIORITY",
}

export type DirectIndexTradeExecution = {
  __typename?: "DirectIndexTradeExecution";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  estimatedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedWashSales?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  finalRisk?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  initialRisk?: Maybe<Scalars["Decimal"]["output"]>;
  isFullLiquidation: Scalars["Boolean"]["output"];
  liquidateAmount?: Maybe<Scalars["Decimal"]["output"]>;
  metadata: Array<DirectIndexTradeExecutionMetadata>;
  status: TradeExecutionStatus;
  statusHistory?: Maybe<Array<DirectIndexTradeExecutionStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  type?: Maybe<TradeExecutionType>;
  weights?: Maybe<Array<DirectIndexWeight>>;
};

export type DirectIndexTradeExecutionMetadata = {
  __typename?: "DirectIndexTradeExecutionMetadata";
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  orderStatus?: Maybe<OrderStatus>;
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type DirectIndexTradeExecutionStatusHistory = {
  __typename?: "DirectIndexTradeExecutionStatusHistory";
  directIndexTradeExecutionId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  status: TradeExecutionStatus;
};

export type DirectIndexTradeExecutionSummary = {
  __typename?: "DirectIndexTradeExecutionSummary";
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isLiquidationRequest: Scalars["Boolean"]["output"];
  latestStatusTime?: Maybe<Scalars["DateTime"]["output"]>;
  orders: Array<TradeExecutionOrders>;
  status: TradeExecutionStatus;
  subAccountId: Scalars["ID"]["output"];
};

export enum DirectIndexType {
  CrspIssLargeCapEsg = "CRSP_ISS_LARGE_CAP_ESG",
  CrspLargeCap = "CRSP_LARGE_CAP",
  CrspMidCap = "CRSP_MID_CAP",
  CrspSmallCap = "CRSP_SMALL_CAP",
  CrspTotalMarket = "CRSP_TOTAL_MARKET",
  CustomFixedWeight = "CUSTOM_FIXED_WEIGHT",
  CustomMarketCap = "CUSTOM_MARKET_CAP",
  EtfBased = "ETF_BASED",
  Russell_1000 = "RUSSELL_1000",
  Russell_2000 = "RUSSELL_2000",
  Russell_3000 = "RUSSELL_3000",
  Smh = "SMH",
  Sp500 = "SP500",
  Sp1500 = "SP1500",
  SpAdrDm = "SP_ADR_DM",
  SpAdrEm = "SP_ADR_EM",
  SpInfoTech = "SP_INFO_TECH",
  SpShariah = "SP_SHARIAH",
}

export type DirectIndexWeight = {
  __typename?: "DirectIndexWeight";
  canDisplayWeight: Scalars["Boolean"]["output"];
  canLiquidate: Scalars["Boolean"]["output"];
  canPurchase: Scalars["Boolean"]["output"];
  computedAt?: Maybe<Scalars["DateTime"]["output"]>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  weight: Scalars["Decimal"]["output"];
};

export type DirectIndexWeightInput = {
  canLiquidate: Scalars["Boolean"]["input"];
  canPurchase: Scalars["Boolean"]["input"];
  securityId: Scalars["ID"]["input"];
  weight: Scalars["Decimal"]["input"];
};

export type DirectIndexWeightsInput = {
  fromDateTime?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
  toDateTime?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DividendPreference = {
  __typename?: "DividendPreference";
  clearingAccountId: Scalars["ID"]["output"];
  effectiveTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  preferenceType: DividendPreferenceType;
  subAccountType: SubAccountType;
};

export type DividendPreferenceInput = {
  clearingAccountId: Scalars["ID"]["input"];
  effectiveTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  preferenceType: DividendPreferenceType;
  subAccountType: SubAccountType;
};

export enum DividendPreferenceType {
  MoveToDirectIndexing = "MOVE_TO_DIRECT_INDEXING",
  MoveToFrecCash = "MOVE_TO_FREC_CASH",
  MoveToTreasury = "MOVE_TO_TREASURY",
  PayLoanAndFrecCash = "PAY_LOAN_AND_FREC_CASH",
  Reinvest = "REINVEST",
}

export enum DividendReinvestment {
  DoNotReinvest = "DO_NOT_REINVEST",
  Reinvest = "REINVEST",
}

export type DividendSecurity = {
  __typename?: "DividendSecurity";
  amount: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type DividendSecurityFee = {
  __typename?: "DividendSecurityFee";
  amount: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  type: CashDividendFeeType;
};

export type DividendSummary = {
  __typename?: "DividendSummary";
  stockAdjustmentAmounts?: Maybe<Array<StockAdjustmentAmountByType>>;
  totalDividends: Scalars["Decimal"]["output"];
};

export type DividendSummaryInput = {
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type EmailCaptureInput = {
  email: Scalars["String"]["input"];
  emailCaptureDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum EmailType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum EmploymentStatus {
  Employed = "EMPLOYED",
  Retired = "RETIRED",
  Student = "STUDENT",
  Unemployed = "UNEMPLOYED",
  Unspecified = "UNSPECIFIED",
}

export type EntityAccountRequestStatus = {
  __typename?: "EntityAccountRequestStatus";
  asOfDate: Scalars["DateTime"]["output"];
  state: EntityAccountRequestStatusState;
};

export enum EntityAccountRequestStatusState {
  AccountRequestCompleted = "ACCOUNT_REQUEST_COMPLETED",
  AdminCanceled = "ADMIN_CANCELED",
  BusinessAccountInitiated = "BUSINESS_ACCOUNT_INITIATED",
  PendingBusinessAccount = "PENDING_BUSINESS_ACCOUNT",
  PendingTrustAccount = "PENDING_TRUST_ACCOUNT",
  TrustAccountInitiated = "TRUST_ACCOUNT_INITIATED",
}

export enum ErrorType {
  ApiSchemaFailure = "API_SCHEMA_FAILURE",
  AuthenticationFailure = "AUTHENTICATION_FAILURE",
  AuthorizationFailure = "AUTHORIZATION_FAILURE",
  Conflict = "CONFLICT",
  MfaChallenge = "MFA_CHALLENGE",
  NetworkError = "NETWORK_ERROR",
  UnexpectedError = "UNEXPECTED_ERROR",
  UnexpectedNumberOfPrimaryUsers = "UNEXPECTED_NUMBER_OF_PRIMARY_USERS",
  UserLoginConflict = "USER_LOGIN_CONFLICT",
  ValidationFailure = "VALIDATION_FAILURE",
}

export type EstimateAllocationGainLossInput = {
  id: Scalars["ID"]["input"];
  isFullLiquidation?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidateAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type EstimateDirectIndexGainLossInput = {
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  diCash?: InputMaybe<Scalars["Decimal"]["input"]>;
  directIndexType: DirectIndexType;
  isFullLiquidation?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidateAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  trackingPreference?: InputMaybe<DirectIndexTrackingPreference>;
  tradeRestrictions?: InputMaybe<Array<TradeRestrictionSecurityInput>>;
  transferStockFromDIArgs?: InputMaybe<TransferStockFromDiInput>;
  transferStockSubAccountArgs?: InputMaybe<TransferStockSubAccountInput>;
};

export type EstimateDirectIndexGainLossResponse = {
  __typename?: "EstimateDirectIndexGainLossResponse";
  lossHarvestPotential: Scalars["Decimal"]["output"];
  securityGainLoss: Array<SecurityGainLoss>;
  totalLongTermGains: Scalars["Decimal"]["output"];
  totalShortTermGains: Scalars["Decimal"]["output"];
};

export type EstimateDirectIndexLiquidationAmountResponse = {
  __typename?: "EstimateDirectIndexLiquidationAmountResponse";
  estimatedLiquidationAmount?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedMaximumWithdrawalAmount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type EventHistory = {
  __typename?: "EventHistory";
  processedEvents?: Maybe<Array<ApexSimulationEvent>>;
  simulationEvents?: Maybe<Array<ApexSimulationEvent>>;
};

export enum EventType {
  AcatsTransfers = "ACATS_TRANSFERS",
  Accounts = "ACCOUNTS",
  AchRelationships = "ACH_RELATIONSHIPS",
  BasketOrdersAccountAllocation = "BASKET_ORDERS_ACCOUNT_ALLOCATION",
  BasketOrdersMf = "BASKET_ORDERS_MF",
  BasketOrdersSubmission = "BASKET_ORDERS_SUBMISSION",
  CashTransfers = "CASH_TRANSFERS",
  MutualFundsOrders = "MUTUAL_FUNDS_ORDERS",
  MutualFundsOrdersRiskSummary = "MUTUAL_FUNDS_ORDERS_RISK_SUMMARY",
  Orders = "ORDERS",
  SketchInvestigationStatus = "SKETCH_INVESTIGATION_STATUS",
  WireDeposits = "WIRE_DEPOSITS",
}

export type ExecuteActiveAllocationInput = {
  executionIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  filterType?: InputMaybe<AllocationExecutionType>;
};

export type ExternalAccountHoldings = {
  __typename?: "ExternalAccountHoldings";
  holdings: Array<Holding>;
  id: Scalars["ID"]["output"];
};

export type ExternalAccountHoldingsInput = {
  holdings: Array<HoldingInput>;
  id: Scalars["ID"]["input"];
};

export enum ExtractNumber {
  Ext001 = "EXT001",
  Ext235 = "EXT235",
  Ext236 = "EXT236",
  Ext250 = "EXT250",
  Ext538 = "EXT538",
  Ext590 = "EXT590",
  Ext596 = "EXT596",
  Ext747 = "EXT747",
  Ext765 = "EXT765",
  Ext869 = "EXT869",
  Ext870 = "EXT870",
  Ext871 = "EXT871",
  Ext872 = "EXT872",
  Ext901 = "EXT901",
  Ext902 = "EXT902",
  Ext922 = "EXT922",
  Ext981 = "EXT981",
  Ext982 = "EXT982",
  Ext983 = "EXT983",
  Ext986 = "EXT986",
  Ext989 = "EXT989",
  Ext996 = "EXT996",
  Ext997 = "EXT997",
  Ext1027 = "EXT1027",
}

export enum FdxConnectionAggregator {
  Plaid = "PLAID",
  Yodlee = "YODLEE",
}

export type FactInput = {
  actionContext: ActionContext;
  clearingAccountId: Scalars["String"]["input"];
  clearingAccountRestriction?: InputMaybe<ClearingAccountRestrictionType>;
  quantityType?: InputMaybe<Scalars["String"]["input"]>;
  subAccountId?: InputMaybe<Scalars["String"]["input"]>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FeeType {
  AdminFee = "ADMIN_FEE",
  BasketTrade = "BASKET_TRADE",
  DirectIndex = "DIRECT_INDEX",
  MarginInterest = "MARGIN_INTEREST",
  Treasury = "TREASURY",
  WireIn = "WIRE_IN",
}

export type FpslInterest = {
  __typename?: "FpslInterest";
  amount: Scalars["Decimal"]["output"];
  destinationSubAccountId: Scalars["String"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
};

export enum FrecAccountType {
  Business = "BUSINESS",
  Individual = "INDIVIDUAL",
  JointCoOwner = "JOINT_CO_OWNER",
  JointInitiator = "JOINT_INITIATOR",
  Trust = "TRUST",
}

export type Fundamentals = {
  __typename?: "Fundamentals";
  bookValue?: Maybe<Scalars["Decimal"]["output"]>;
  bookValuePerShare?: Maybe<Scalars["Decimal"]["output"]>;
  currency: Scalars["String"]["output"];
  date: Scalars["DateTime"]["output"];
  dividendExDate?: Maybe<Scalars["String"]["output"]>;
  dividendYieldSecurity?: Maybe<Scalars["Decimal"]["output"]>;
  ebitda?: Maybe<Scalars["Decimal"]["output"]>;
  marketCapitalization?: Maybe<Scalars["Decimal"]["output"]>;
  marketCapitalizationExcludingNonTradedIssuerLevel?: Maybe<
    Scalars["Decimal"]["output"]
  >;
  netMargin?: Maybe<Scalars["Decimal"]["output"]>;
  priceToBook?: Maybe<Scalars["Decimal"]["output"]>;
  sector?: Maybe<Scalars["String"]["output"]>;
  sectorName?: Maybe<Scalars["String"]["output"]>;
};

export type FundamentalsInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type FundamentalsSet = {
  __typename?: "FundamentalsSet";
  fundamentals: Array<Fundamentals>;
  symbol: Scalars["String"]["output"];
};

export enum GicsCode {
  Gics10 = "GICS10",
  Gics15 = "GICS15",
  Gics20 = "GICS20",
  Gics25 = "GICS25",
  Gics30 = "GICS30",
  Gics35 = "GICS35",
  Gics40 = "GICS40",
  Gics45 = "GICS45",
  Gics50 = "GICS50",
  Gics55 = "GICS55",
  Gics60 = "GICS60",
}

export type GenerateAutoTlhCandidatesResponse = {
  __typename?: "GenerateAutoTLHCandidatesResponse";
  execution?: Maybe<TradeExecution>;
  reason?: Maybe<Scalars["String"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
};

export type GenerateCandidatesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  autoApprove?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  executionFilter?: InputMaybe<CandidateGenerationExecutionFilter>;
  forceRebalance?: InputMaybe<Scalars["Boolean"]["input"]>;
  runForClearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  runForSubAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  runMode?: InputMaybe<DirectIndexCandidateGenerationRunMode>;
  skipLogs?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GenerateCandidatesResponse = {
  __typename?: "GenerateCandidatesResponse";
  execution?: Maybe<DirectIndexTradeExecution>;
  reason?: Maybe<Scalars["String"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
};

export type GenerateReportInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  encrypt?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  filterForClearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  forceGenerate?: InputMaybe<Scalars["Boolean"]["input"]>;
  reports: Array<ReportType>;
};

export type GenerateReportResponse = {
  __typename?: "GenerateReportResponse";
  date: Scalars["Date"]["output"];
  endDate?: Maybe<Scalars["Date"]["output"]>;
  reportType: ReportType;
  status: StatusCode;
};

export type GenerateTlhCandidatesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  autoApprove?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  runForClearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GenerateUserCodeInput = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  type: MfaType;
};

export type GenerateUserCodeResponse = {
  __typename?: "GenerateUserCodeResponse";
  code: Scalars["String"]["output"];
};

export type GenericAddress = {
  __typename?: "GenericAddress";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  addressType: AddressType;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  state: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

export type GenericAddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressType: AddressType;
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  state: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type GetCashTransfersArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  directions?: InputMaybe<Array<CashTransferDirection>>;
  methods?: InputMaybe<Array<CashTransferMethod>>;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  statuses?: InputMaybe<Array<CashTransferStatus>>;
  subAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GetDirectIndexPerformanceDataInput = {
  performanceDataPeriod?: InputMaybe<PerformanceDataPeriod>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export type GetDirectIndexTradeExecutionsInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status: Array<TradeExecutionStatus>;
  subAccountId: Scalars["ID"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<TradeExecutionType>;
};

export type GetDividendsArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GetHistoricalIndexPerformanceDataInput = {
  period: PerformanceDataPeriod;
  type: DirectIndexType;
};

export type GetIndexDataInput = {
  types: Array<DirectIndexType>;
};

export type GetIndexForSecurityInput = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  symbol: Array<Scalars["String"]["input"]>;
};

export type GetIndexForSecurityResponse = {
  __typename?: "GetIndexForSecurityResponse";
  indexResults: Array<Maybe<IndexResultForSymbol>>;
};

export type GetJointAccountCoOwnerResponse = {
  __typename?: "GetJointAccountCoOwnerResponse";
  coOwnerAddress: GenericAddress;
  coOwnerContactEmail: UserContactEmail;
  coOwnerPersonalDetails: UserPersonalDetails;
  coOwnerUserId: Scalars["ID"]["output"];
  initiatorUserId: Scalars["ID"]["output"];
};

export type GetJointAccountOtherUserResponse = {
  __typename?: "GetJointAccountOtherUserResponse";
  userAddress: GenericAddress;
  userContactEmail: UserContactEmail;
  userId: Scalars["ID"]["output"];
  userPersonalDetails: UserPersonalDetails;
};

export type GetPendingLoansInput = {
  clearingAccountId: Scalars["ID"]["input"];
  pendingLoanStatuses: Array<PendingLoanStatus>;
};

export type GetPendingLoansResponse = {
  __typename?: "GetPendingLoansResponse";
  data: Array<PendingLoan>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type GetStockAdjustmentArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GetUserAchDailyTransferLimitInput = {
  userId: Scalars["ID"]["input"];
};

export type GetUserAchDailyTransferLimitResponse = {
  __typename?: "GetUserAchDailyTransferLimitResponse";
  userAchDailyTransferLimit: Scalars["Decimal"]["output"];
};

export type GetUserInvestorDocumentsInput = {
  category: InvestorDocumentCategory;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type GetUserInvestorDocumentsResponse = {
  __typename?: "GetUserInvestorDocumentsResponse";
  documents: Array<UserInvestorDocument>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type HistoricalDelta = {
  __typename?: "HistoricalDelta";
  deltas: Array<StockHistory>;
  prices: Array<StockHistory>;
  symbol: Scalars["String"]["output"];
};

export type HistoricalDeltaInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalDividendPayments = {
  __typename?: "HistoricalDividendPayments";
  adjDividend: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
  declarationDate?: Maybe<Scalars["Date"]["output"]>;
  dividend: Scalars["Decimal"]["output"];
  paymentDate?: Maybe<Scalars["Date"]["output"]>;
  recordDate?: Maybe<Scalars["Date"]["output"]>;
};

export type HistoricalInput = {
  adjustmentMethod?: AdjustmentMethod;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval: DateInterval;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalMarketCap = {
  __typename?: "HistoricalMarketCap";
  date: Scalars["Date"]["output"];
  marketCap: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type HistoricalMarketCapInput = {
  asOfDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalQuote = {
  __typename?: "HistoricalQuote";
  TWAP?: Maybe<Scalars["Float"]["output"]>;
  VWAP?: Maybe<Scalars["Float"]["output"]>;
  adjustmentMethodUsed?: Maybe<Scalars["String"]["output"]>;
  change: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  cumulativeAdjustmentFactor?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  high?: Maybe<Scalars["Float"]["output"]>;
  low?: Maybe<Scalars["Float"]["output"]>;
  open: Scalars["Float"]["output"];
  percentChange: Scalars["Float"]["output"];
  previousClose: Scalars["Float"]["output"];
  previousCloseDate?: Maybe<Scalars["Date"]["output"]>;
  volume: Scalars["Float"]["output"];
};

export type HistoricalQuoteInput = {
  __typename?: "HistoricalQuoteInput";
  adjustmentMethod: AdjustmentMethod;
  asOfDate: Scalars["Date"]["output"];
  symbols: Array<Scalars["String"]["output"]>;
};

export type HistoricalQuoteResponse = {
  __typename?: "HistoricalQuoteResponse";
  asOfDate: Scalars["Date"]["output"];
  delay?: Maybe<Scalars["Float"]["output"]>;
  historicalQuote: HistoricalQuote;
  identifier?: Maybe<Scalars["String"]["output"]>;
  identifierType?: Maybe<Scalars["String"]["output"]>;
  identity?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  outcome: Scalars["String"]["output"];
  security: HistoricalSecurity;
};

export type HistoricalSecurity = {
  __typename?: "HistoricalSecurity";
  CIK?: Maybe<Scalars["String"]["output"]>;
  CUSIP?: Maybe<Scalars["String"]["output"]>;
  FIGI?: Maybe<Scalars["String"]["output"]>;
  ISIN?: Maybe<Scalars["String"]["output"]>;
  SEDOL?: Maybe<Scalars["String"]["output"]>;
  compositeFIGI?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  instrumentClass?: Maybe<Scalars["String"]["output"]>;
  market?: Maybe<Scalars["String"]["output"]>;
  marketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sector?: Maybe<Scalars["String"]["output"]>;
  symbol: Scalars["String"]["output"];
  valoren?: Maybe<Scalars["String"]["output"]>;
};

export type Holding = {
  __typename?: "Holding";
  marginRequirement: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type HoldingInput = {
  marginRequirement: Scalars["Decimal"]["input"];
  price: Scalars["Decimal"]["input"];
  quantity: Scalars["Decimal"]["input"];
  subType: SecuritySubType;
  symbol: Scalars["String"]["input"];
  type: SecurityType;
};

export type IndexConstituent = {
  __typename?: "IndexConstituent";
  canDisplayWeight: Scalars["Boolean"]["output"];
  effectiveDate: Scalars["Date"]["output"];
  fractionalSupported: Scalars["Boolean"]["output"];
  gicsCode?: Maybe<GicsCode>;
  indexMarketCap: Scalars["Float"]["output"];
  marketCap: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
  weight: Scalars["Float"]["output"];
};

export type IndexConstituentData = {
  __typename?: "IndexConstituentData";
  constituents: Array<IndexConstituent>;
  index: StockIndex;
};

export type IndexCountryData = {
  __typename?: "IndexCountryData";
  countryMarketCapPercent: Scalars["Float"]["output"];
  countryName: Scalars["String"]["output"];
};

export type IndexCustomizationSecurity = {
  __typename?: "IndexCustomizationSecurity";
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type IndexDetails = {
  __typename?: "IndexDetails";
  allowedSectorCustomizations?: Maybe<Scalars["Int"]["output"]>;
  allowedStockCustomizations: Scalars["Int"]["output"];
  canCustomizeSectors: Scalars["Boolean"]["output"];
  comparisonEtf: Scalars["String"]["output"];
  comparisonEtfExpenseRatio: Scalars["Decimal"]["output"];
  comparisonEtfName: Scalars["String"]["output"];
  constituentData: IndexExplorerConstituentData;
  feePercent: Scalars["Decimal"]["output"];
  historicalPerformanceData: IndexHistoricalPerformanceData;
  indexDescription: Scalars["String"]["output"];
  /** @deprecated Use metadataTags instead */
  indexDisplayCategory: IndexDisplayCategory;
  indexName: Scalars["String"]["output"];
  indexSubText: Scalars["String"]["output"];
  indexType: DirectIndexType;
  investmentMinimum: Scalars["Decimal"]["output"];
  learnMoreLink: Scalars["String"]["output"];
  metadataTags: Array<IndexMetadataTag>;
  numberOfPositions: Scalars["Int"]["output"];
  stockIndex: StockIndex;
  ytdReturn: Scalars["Decimal"]["output"];
};

export enum IndexDisplayCategory {
  MostPopular = "MOST_POPULAR",
  Regular = "REGULAR",
}

export type IndexExplorerConstituentData = {
  __typename?: "IndexExplorerConstituentData";
  asOfDate?: Maybe<Scalars["Date"]["output"]>;
  constituents: Array<IndexConstituent>;
  countryData: Array<IndexCountryData>;
  sectorData: Array<IndexSectorData>;
  totalMarketCap: Scalars["Float"]["output"];
};

export type IndexHistorical = {
  __typename?: "IndexHistorical";
  GICSCode?: Maybe<GicsCode>;
  ISOCode?: Maybe<Scalars["String"]["output"]>;
  adjCount?: Maybe<Scalars["Float"]["output"]>;
  adjDivisor?: Maybe<Scalars["Float"]["output"]>;
  adjMarketCap?: Maybe<Scalars["Float"]["output"]>;
  closeCount: Scalars["Float"]["output"];
  closeDivisor?: Maybe<Scalars["Float"]["output"]>;
  closeMarketCap?: Maybe<Scalars["Float"]["output"]>;
  dailyReturn?: Maybe<Scalars["Float"]["output"]>;
  date: Scalars["Date"]["output"];
  indexCode?: Maybe<Scalars["String"]["output"]>;
  indexDividend?: Maybe<Scalars["Float"]["output"]>;
  indexKey: Scalars["String"]["output"];
  indexName: Scalars["String"]["output"];
  indexValue?: Maybe<Scalars["Float"]["output"]>;
};

export type IndexHistoricalData = {
  __typename?: "IndexHistoricalData";
  data?: Maybe<IndexHistorical>;
  index: StockIndex;
};

export type IndexHistoricalPerformanceData = {
  __typename?: "IndexHistoricalPerformanceData";
  period: PerformanceDataPeriod;
  returnForPeriod: Scalars["Decimal"]["output"];
  timeseriesData: Array<IndexPerformancePoint>;
};

export enum IndexMetadataTag {
  MarketCapAllCap = "MARKET_CAP_ALL_CAP",
  MarketCapLargeCap = "MARKET_CAP_LARGE_CAP",
  MarketCapMidCap = "MARKET_CAP_MID_CAP",
  MarketCapSmallCap = "MARKET_CAP_SMALL_CAP",
  Popular = "POPULAR",
  ProviderCrsp = "PROVIDER_CRSP",
  ProviderMvis = "PROVIDER_MVIS",
  ProviderRussell = "PROVIDER_RUSSELL",
  ProviderSp = "PROVIDER_SP",
  RegionInternational = "REGION_INTERNATIONAL",
  SectorTechnology = "SECTOR_TECHNOLOGY",
  ValuesEsg = "VALUES_ESG",
  ValuesShariah = "VALUES_SHARIAH",
}

export type IndexPerformancePoint = {
  __typename?: "IndexPerformancePoint";
  date: Scalars["Date"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type IndexResultForSymbol = {
  __typename?: "IndexResultForSymbol";
  index?: Maybe<Array<Maybe<StockIndex>>>;
  symbol: Scalars["String"]["output"];
};

export type IndexSectorData = {
  __typename?: "IndexSectorData";
  gicsCode: GicsCode;
  sectorMarketCapPercent: Scalars["Float"]["output"];
  sectorName: Scalars["String"]["output"];
  sectorPositionsCount: Scalars["Int"]["output"];
};

export type IndividualDisclosure = {
  __typename?: "IndividualDisclosure";
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  companySymbols?: Maybe<Scalars["String"]["output"]>;
  controlPerson: Scalars["Boolean"]["output"];
  firmName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  immediateFamilyMembers?: Maybe<Array<Scalars["String"]["output"]>>;
  politicalOrganization?: Maybe<Scalars["String"]["output"]>;
  politicalTitle?: Maybe<Scalars["String"]["output"]>;
  politicallyExposed: Scalars["Boolean"]["output"];
};

export type InitiateAchDepositInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InitiateAchDepositResponse = {
  __typename?: "InitiateAchDepositResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateAchWithdrawalInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
};

export type InitiateAchWithdrawalResponse = {
  __typename?: "InitiateAchWithdrawalResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateCashTransferInput = {
  allocationWithdrawalStrategy?: InputMaybe<AllocationWithdrawalStrategy>;
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  fromId: Scalars["ID"]["input"];
  fromType: MoneyMovementSourceType;
  isFullLiquidation?: InputMaybe<Scalars["Boolean"]["input"]>;
  toId: Scalars["ID"]["input"];
  toType: MoneyMovementSourceType;
};

export type InitiateCashTransferResponse = {
  __typename?: "InitiateCashTransferResponse";
  status: Scalars["Boolean"]["output"];
  transferId?: Maybe<Scalars["ID"]["output"]>;
  transferIdType?: Maybe<InitiateCashTransferResultIdType>;
};

export enum InitiateCashTransferResultIdType {
  AllocationExecution = "ALLOCATION_EXECUTION",
  CashAdjustmentsGroupId = "CASH_ADJUSTMENTS_GROUP_ID",
  CashTransfer = "CASH_TRANSFER",
  DiExecution = "DI_EXECUTION",
  ScheduledDepositExecution = "SCHEDULED_DEPOSIT_EXECUTION",
  TreasuryExecution = "TREASURY_EXECUTION",
}

export type InitiateLoanInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
};

export type InitiateWireDepositInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type InitiateWireDepositResponse = {
  __typename?: "InitiateWireDepositResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateWireWithdrawalInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
};

export type InitiateWireWithdrawalResponse = {
  __typename?: "InitiateWireWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export type IntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs = {
  date: Scalars["Date"]["input"];
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IntervalExecuteBatchUpdateUsersInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IntervalExecuteExistingBatchOrderInput = {
  batchOrderMetadataId: Scalars["ID"]["input"];
};

export type IntervalExecuteExistingBatchOrderResponse = {
  __typename?: "IntervalExecuteExistingBatchOrderResponse";
  status: Scalars["String"]["output"];
  submissionId?: Maybe<Scalars["ID"]["output"]>;
};

export type IntervalExecuteQueuedCashTransfersInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashTransferIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type IntervalFetchAndPersistCorporateActionsInput = {
  dryRun: Scalars["Boolean"]["input"];
  endDate: Scalars["Date"]["input"];
  eventTypes?: InputMaybe<Array<CorporateActionType>>;
  exchanges: Array<CorporateActionExchange>;
  startDate: Scalars["Date"]["input"];
};

export type IntervalFetchAndPersistCorporateActionsResponse = {
  __typename?: "IntervalFetchAndPersistCorporateActionsResponse";
  countCorporateActions: Scalars["Int"]["output"];
};

export type IntervalPopulateSecurityIndexCacheInput = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type IntervalSubmitBatchTradeOrderInput = {
  orders: Array<BatchOrderSingleInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type IntervalSubmitBatchTradeOrderResponse = {
  __typename?: "IntervalSubmitBatchTradeOrderResponse";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
};

export type IntraAccountCashTransfer = {
  __typename?: "IntraAccountCashTransfer";
  amount: Scalars["Decimal"]["output"];
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  from: IntraAccountCashTransferDirection;
  id: Scalars["ID"]["output"];
  source: AdjustmentSource;
  sourceSubAccountId?: Maybe<Scalars["ID"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
  to: IntraAccountCashTransferDirection;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export enum IntraAccountCashTransferDirection {
  Allocation = "ALLOCATION",
  DirectIndex = "DIRECT_INDEX",
  FrecCash = "FREC_CASH",
  Treasury = "TREASURY",
}

export type IntraAccountStockTransfer = {
  __typename?: "IntraAccountStockTransfer";
  adjustmentsGroupId: Scalars["ID"]["output"];
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  from: IntraAccountStockTransferDirection;
  source: AdjustmentSource;
  stocks: Array<IntraAccountStockTransferSecurity>;
  subAccountId: Scalars["ID"]["output"];
  to: IntraAccountStockTransferDirection;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export enum IntraAccountStockTransferDirection {
  DirectIndex = "DIRECT_INDEX",
  Primary = "PRIMARY",
}

export type IntraAccountStockTransferFromDiResponse = {
  __typename?: "IntraAccountStockTransferFromDIResponse";
  customization?: Maybe<DirectIndexCustomization>;
  success: Scalars["Boolean"]["output"];
};

export type IntraAccountStockTransferSecurity = {
  __typename?: "IntraAccountStockTransferSecurity";
  id: Scalars["ID"]["output"];
  quantity: Scalars["Decimal"]["output"];
  securityPriceAtTransfer: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type IntraDayAccountValues = {
  __typename?: "IntraDayAccountValues";
  amountAvailableToWithdraw?: Maybe<Scalars["Float"]["output"]>;
};

export type IntraDayMarginCallData = {
  __typename?: "IntraDayMarginCallData";
  accountState: MarginAccountState;
  callAmount?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum InvestmentExperience {
  Extensive = "EXTENSIVE",
  Good = "GOOD",
  Limited = "LIMITED",
  None = "NONE",
  Unspecified = "UNSPECIFIED",
}

export enum InvestmentObjective {
  Balanced = "BALANCED",
  GrowthIncome = "GROWTH_INCOME",
  MaximumGrowth = "MAXIMUM_GROWTH",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
}

export type InvestmentProfileRangeInput = {
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
};

export type InvestmentSearchResult = {
  __typename?: "InvestmentSearchResult";
  directIndices: Array<DirectIndexSearchResult>;
  securities: Array<SecuritySearchResult>;
};

export type InvestmentSettings = {
  __typename?: "InvestmentSettings";
  allowStrategicWashSales: Scalars["Boolean"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
};

export type InvestmentSettingsInput = {
  allowStrategicWashSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
};

export enum InvestorDocumentCategory {
  Statement = "STATEMENT",
  TaxForm = "TAX_FORM",
  TradeConfirmation = "TRADE_CONFIRMATION",
}

export type InviteCodeApplication = {
  __typename?: "InviteCodeApplication";
  appliedOn: Scalars["DateTime"]["output"];
  code: Scalars["String"]["output"];
  deal?: Maybe<UserReferralDeal>;
  id: Scalars["ID"]["output"];
  rewards: Array<ReferralRewardDetails>;
};

export type JobConfig = {
  __typename?: "JobConfig";
  createdBy: Scalars["String"]["output"];
  firstRunTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  interval: Scalars["Int"]["output"];
  intervalType: JobConfigIntervalType;
  name: Scalars["String"]["output"];
  status: JobConfigStatus;
  type: JobConfigType;
};

export enum JobConfigIntervalType {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
}

export enum JobConfigStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Paused = "PAUSED",
}

export enum JobConfigType {
  DirectIndexing = "DIRECT_INDEXING",
  EodProcessing = "EOD_PROCESSING",
  Iterable = "ITERABLE",
  Referral = "REFERRAL",
  Reporting = "REPORTING",
  Snapshot = "SNAPSHOT",
  SodPostProcessing = "SOD_POST_PROCESSING",
  SodProcessing = "SOD_PROCESSING",
  StateProcessor = "STATE_PROCESSOR",
}

export enum JobName {
  AutoTlhCandidateGeneration = "AUTO_TLH_CANDIDATE_GENERATION",
  BarraRiskDataProcessing = "BARRA_RISK_DATA_PROCESSING",
  BorrowingPowerSnapshot = "BORROWING_POWER_SNAPSHOT",
  CaisCustomerReport = "CAIS_CUSTOMER_REPORT",
  CorporateActionData = "CORPORATE_ACTION_DATA",
  CrspIndexDataProcessing = "CRSP_INDEX_DATA_PROCESSING",
  DirectIndexCandidateGeneration = "DIRECT_INDEX_CANDIDATE_GENERATION",
  DirectIndexCandidateGenerationMorningRun = "DIRECT_INDEX_CANDIDATE_GENERATION_MORNING_RUN",
  /** @deprecated Field no longer supported */
  DirectIndexCandidateGenerationRun2 = "DIRECT_INDEX_CANDIDATE_GENERATION_RUN2",
  DirectIndexCredit = "DIRECT_INDEX_CREDIT",
  DirectIndexFee = "DIRECT_INDEX_FEE",
  DirectIndexWeights = "DIRECT_INDEX_WEIGHTS",
  EndOfDayHealthCheck = "END_OF_DAY_HEALTH_CHECK",
  GrantReferralRewardJob = "GRANT_REFERRAL_REWARD_JOB",
  IterableBatchUserUpdate = "ITERABLE_BATCH_USER_UPDATE",
  LiquidationStateTransitionJob = "LIQUIDATION_STATE_TRANSITION_JOB",
  MarginCallProcessing = "MARGIN_CALL_PROCESSING",
  MarginInterestFee = "MARGIN_INTEREST_FEE",
  MarketingDataBatchUserUpdate = "MARKETING_DATA_BATCH_USER_UPDATE",
  MarketVectorDataProcessing = "MARKET_VECTOR_DATA_PROCESSING",
  PatternDayTradeStatus = "PATTERN_DAY_TRADE_STATUS",
  PortfolioSnapshot = "PORTFOLIO_SNAPSHOT",
  PortfolioSnapshotRegeneration = "PORTFOLIO_SNAPSHOT_REGENERATION",
  RussellDataProcessing = "RUSSELL_DATA_PROCESSING",
  SodProcessingExt981 = "SOD_PROCESSING_EXT981",
  SodProcessingExt982 = "SOD_PROCESSING_EXT982",
  SodProcessingExt1027 = "SOD_PROCESSING_EXT1027",
  SplitTaxLotProcessing = "SPLIT_TAX_LOT_PROCESSING",
  SpIndexDataProcessing = "SP_INDEX_DATA_PROCESSING",
  StartOfDayHealthCheck = "START_OF_DAY_HEALTH_CHECK",
  TaxLotReconciliation = "TAX_LOT_RECONCILIATION",
  TaxLotTransactionGeneration = "TAX_LOT_TRANSACTION_GENERATION",
  TreasuryExecution = "TREASURY_EXECUTION",
  TreasuryFee = "TREASURY_FEE",
}

export type JobRun = {
  __typename?: "JobRun";
  endDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  jobId: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  runBy?: Maybe<Scalars["String"]["output"]>;
  runTimeMs?: Maybe<Scalars["Int"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  status: JobRunStatus;
};

export enum JobRunStatus {
  Failed = "FAILED",
  Invalidated = "INVALIDATED",
  NotStarted = "NOT_STARTED",
  Running = "RUNNING",
  Skipped = "SKIPPED",
  Success = "SUCCESS",
}

export type KeyValueDeleteInput = {
  keys: Array<Scalars["String"]["input"]>;
  namespace: Scalars["String"]["input"];
};

export type KeyValueGetInput = {
  keys: Array<Scalars["String"]["input"]>;
  namespace: Scalars["String"]["input"];
};

export type KeyValuePair = {
  __typename?: "KeyValuePair";
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  namespace: Scalars["String"]["output"];
  value: Scalars["JSON"]["output"];
};

export type KeyValuePairInput = {
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type KeyValueSetInput = {
  namespace: Scalars["String"]["input"];
  records: Array<KeyValuePairInput>;
};

export type LinkPlaidInput = {
  plaidPublicToken: Scalars["String"]["input"];
  type: PlaidAccountType;
};

export type LinkPlaidResult = {
  __typename?: "LinkPlaidResult";
  accountName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  institution: Scalars["String"]["output"];
  mask?: Maybe<Scalars["String"]["output"]>;
  ownerName?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId: Scalars["String"]["output"];
  status: PlaidAccountStatus;
};

export type LinkedBrokerageAccount = {
  __typename?: "LinkedBrokerageAccount";
  acatsTransfers?: Maybe<Array<AcatsTransfer>>;
  id: Scalars["ID"]["output"];
  institutionName: Scalars["String"]["output"];
  isPartialTransferDisabled: Scalars["Boolean"]["output"];
  mask: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nsccNumber?: Maybe<Scalars["String"]["output"]>;
  plaidAccount?: Maybe<PlaidAccount>;
  plaidAccountId?: Maybe<Scalars["ID"]["output"]>;
};

export enum LinkedBrokerageAccountType {
  Manual = "MANUAL",
  Plaid = "PLAID",
}

export type LinkedBrokerageAccountsInput = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  includeDTC?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LiquidateDirectIndexPortfolioRequest = {
  __typename?: "LiquidateDirectIndexPortfolioRequest";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isFullLiquidation?: Maybe<Scalars["Boolean"]["output"]>;
  latestStatusTime?: Maybe<Scalars["DateTime"]["output"]>;
  status: LiquidateDirectIndexPortfolioStatus;
  subAccountId: Scalars["ID"]["output"];
};

export type LiquidateDirectIndexPortfolioResponse = {
  __typename?: "LiquidateDirectIndexPortfolioResponse";
  id?: Maybe<Scalars["ID"]["output"]>;
  status: LiquidateDirectIndexPortfolioStatus;
};

export enum LiquidateDirectIndexPortfolioStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Pending = "PENDING",
  Requested = "REQUESTED",
}

export enum LiquidationState {
  Complete = "COMPLETE",
  Failed = "FAILED",
  Init = "INIT",
  WireInitiated = "WIRE_INITIATED",
  WirePending = "WIRE_PENDING",
}

export enum LiquidityNeeds {
  NotImportant = "NOT_IMPORTANT",
  SomewhatImportant = "SOMEWHAT_IMPORTANT",
  Unspecified = "UNSPECIFIED",
  VeryImportant = "VERY_IMPORTANT",
}

export type LiveCashHolding = {
  __typename?: "LiveCashHolding";
  subAccountId: Scalars["ID"]["output"];
  subAccountType: SubAccountType;
  type: LiveCashHoldingType;
  value: Scalars["Decimal"]["output"];
};

export enum LiveCashHoldingType {
  CashBalance = "CASH_BALANCE",
  Fee = "FEE",
  Loan = "LOAN",
}

export type LiveSecurityHolding = {
  __typename?: "LiveSecurityHolding";
  avgCostBasis?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccountId: Scalars["ID"]["output"];
  dividendYtd?: Maybe<Scalars["Decimal"]["output"]>;
  quantity: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  subAccountId: Scalars["ID"]["output"];
  subAccountType: SubAccountType;
};

export type LoanError = {
  __typename?: "LoanError";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type LoanEvent = {
  __typename?: "LoanEvent";
  acatsTransferId?: Maybe<Scalars["ID"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: LoanType;
};

export type LoanResponse = {
  __typename?: "LoanResponse";
  errors?: Maybe<Array<Maybe<LoanError>>>;
  loanId?: Maybe<Scalars["ID"]["output"]>;
};

export enum LoanType {
  AcatsOutFee = "ACATS_OUT_FEE",
  AcatsTransferredLoan = "ACATS_TRANSFERRED_LOAN",
  AdrFee = "ADR_FEE",
  Cancellation = "CANCELLATION",
  CashTransferReturnFee = "CASH_TRANSFER_RETURN_FEE",
  InterestFeePayment = "INTEREST_FEE_PAYMENT",
  LoanRepayment = "LOAN_REPAYMENT",
  LoanRepaymentDividendSweep = "LOAN_REPAYMENT_DIVIDEND_SWEEP",
  LoanRepaymentMarginInterest = "LOAN_REPAYMENT_MARGIN_INTEREST",
  UserInitiated = "USER_INITIATED",
  WireWithdrawalFee = "WIRE_WITHDRAWAL_FEE",
}

export type LongTermMarginInterestForecast = {
  __typename?: "LongTermMarginInterestForecast";
  forecastDate?: Maybe<Scalars["Date"]["output"]>;
  rate?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum LongTermShortTermIndicatorEnumGql {
  Long = "Long",
  Short = "Short",
}

export type LotMatchingInstruction = {
  __typename?: "LotMatchingInstruction";
  cost?: Maybe<Scalars["Decimal"]["output"]>;
  longTermShortTermIndicator?: Maybe<LongTermShortTermIndicatorEnumGql>;
  price?: Maybe<Scalars["Decimal"]["output"]>;
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  taxLotEntryId: Scalars["ID"]["output"];
  tradeDate?: Maybe<Scalars["Date"]["output"]>;
};

export type LotMatchingInstructionInput = {
  quantity: Scalars["Decimal"]["input"];
  taxLotEntryId: Scalars["ID"]["input"];
  tradeDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type LotsToTransferBySymbolInput = {
  lotsToTransferIds: Array<Scalars["String"]["input"]>;
  securityId: Scalars["ID"]["input"];
  symbol: Scalars["String"]["input"];
};

export enum MmfDividendScheduleFrequency {
  Annually = "Annually",
  Daily = "Daily",
  Monthly = "Monthly",
  Unspecified = "Unspecified",
  Weekly = "Weekly",
}

export type MmfMetadata = {
  __typename?: "MMFMetadata";
  cusip: Scalars["String"]["output"];
  dividendSchedule: MmfDividendScheduleFrequency;
  expenseRatio: Scalars["Float"]["output"];
  inceptionDate: Scalars["DateTime"]["output"];
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  link: Scalars["String"]["output"];
  minInvestment: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  nav: Scalars["Float"]["output"];
  netAssets: Scalars["Float"]["output"];
  secYield7Day: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
  ytdYield: Scalars["Float"]["output"];
};

export type MmfMetadataInput = {
  symbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum ManualReviewStatus {
  Fail = "FAIL",
  Pass = "PASS",
  Retry = "RETRY",
}

export enum MarginAccountState {
  GoodStanding = "GOOD_STANDING",
  MarginCall = "MARGIN_CALL",
  RebalanceCall = "REBALANCE_CALL",
}

export type MarginCallData = {
  __typename?: "MarginCallData";
  clearingAccountId: Scalars["String"]["output"];
  dueDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  statuses: Array<MarginCallDataStatus>;
};

export type MarginCallDataStatus = {
  __typename?: "MarginCallDataStatus";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  marginCallId: Scalars["String"]["output"];
  state: MarginCallState;
};

export enum MarginCallState {
  CallCleared = "CALL_CLEARED",
  CallIssued = "CALL_ISSUED",
}

export type MarginInterestCharge = {
  __typename?: "MarginInterestCharge";
  amount: Scalars["Decimal"]["output"];
  billedTo: MarginInterestFeeBilledTo;
  billingEndDate: Scalars["DateTime"]["output"];
  billingStartDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export type MarginInterestChargeInput = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export enum MarginInterestFeeBilledTo {
  FrecCash = "FREC_CASH",
  Loan = "LOAN",
}

export enum MarginInterestFeeStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type MarginInterestForecast = {
  __typename?: "MarginInterestForecast";
  fromDate?: Maybe<Scalars["Date"]["output"]>;
  rate?: Maybe<Scalars["Decimal"]["output"]>;
};

export type MarginInterestForecastResponse = {
  __typename?: "MarginInterestForecastResponse";
  fomcMeetingNotesUrl?: Maybe<Scalars["String"]["output"]>;
  forecasts: Array<MarginInterestForecast>;
  longTermForecast: LongTermMarginInterestForecast;
};

export type MarginInterestResponse = {
  __typename?: "MarginInterestResponse";
  effectiveDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["Decimal"]["output"];
};

export type MarginRequirement = {
  __typename?: "MarginRequirement";
  initialRequirement: Scalars["Decimal"]["output"];
  maintenanceRequirement: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum MaritalStatus {
  Divorced = "DIVORCED",
  Married = "MARRIED",
  Single = "SINGLE",
  Widowed = "WIDOWED",
}

export type MarketDataSimulationArgs = {
  query: Scalars["String"]["input"];
  response: Scalars["String"]["input"];
  simulationId: Scalars["Int"]["input"];
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketDataSimulationEntry = {
  __typename?: "MarketDataSimulationEntry";
  id: Scalars["String"]["output"];
  query: Scalars["String"]["output"];
  response: Scalars["String"]["output"];
  simulationId: Scalars["Int"]["output"];
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export type MarketHours = {
  __typename?: "MarketHours";
  closingHour: Scalars["DateTime"]["output"];
  isTheStockMarketOpen: Scalars["Boolean"]["output"];
  openingHour: Scalars["DateTime"]["output"];
  previousMarketClose: Scalars["DateTime"]["output"];
  previousMarketOpen: Scalars["DateTime"]["output"];
  stockExchangeName: Scalars["String"]["output"];
};

export type MeetingDetails = {
  __typename?: "MeetingDetails";
  cancelUrl: Scalars["String"]["output"];
  endTime: Scalars["DateTime"]["output"];
  invitee: MeetingInvitee;
  joinUrl: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  rescheduleUrl: Scalars["String"]["output"];
  startTime: Scalars["DateTime"]["output"];
  status: MeetingStatus;
};

export type MeetingInvitee = {
  __typename?: "MeetingInvitee";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export enum MeetingStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Scheduled = "SCHEDULED",
}

export type MfaChallengeResponse = {
  __typename?: "MfaChallengeResponse";
  status?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum MfaType {
  App = "APP",
  BackupCode = "BACKUP_CODE",
  DeviceToken = "DEVICE_TOKEN",
  Phone = "PHONE",
}

export enum MoneyMovementSourceType {
  Ach = "ACH",
  Allocation = "ALLOCATION",
  Check = "CHECK",
  DirectIndex = "DIRECT_INDEX",
  FrecCash = "FREC_CASH",
  LineOfCredit = "LINE_OF_CREDIT",
  Treasury = "TREASURY",
  Wire = "WIRE",
}

export enum MoneyMovementSourceTypeGroup {
  Allocation = "ALLOCATION",
  DepositAccount = "DEPOSIT_ACCOUNT",
  LineOfCredit = "LINE_OF_CREDIT",
  SubAccount = "SUB_ACCOUNT",
}

export type MonitorTradeExecutionStatusInput = {
  executionIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updateOrderStatusOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum MultiIndexConflictType {
  Buy = "BUY",
  BuySell = "BUY_SELL",
  None = "NONE",
}

export enum MultiIndexResolutionType {
  LastRun = "LAST_RUN",
}

export enum MultiIndexRunType {
  V1 = "V1",
  V2 = "V2",
  /** @deprecated Field no longer supported */
  V3 = "V3",
  V4 = "V4",
}

export type MultiIndexSimulateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  resolutionType: MultiIndexResolutionType;
  simulationRunIds: Array<Scalars["ID"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  acatsCreateTransfer: AcatsCreateTransferResponse;
  addManualAccountNumberToPlaidAccount?: Maybe<PlaidAccount>;
  addTreasuryConfig: TreasuryConfig;
  addUserAddress?: Maybe<GenericAddress>;
  addUserEmploymentDetails?: Maybe<UserEmploymentDetails>;
  addUserMfaPreference: UserMfaPreferenceWithQrCode;
  addUserPersonalDetails?: Maybe<UserPersonalDetails>;
  addUserTrustedContact?: Maybe<UserTrustedContact>;
  adminBackfillUserFields: Scalars["Boolean"]["output"];
  applyInviteCode: Scalars["Boolean"]["output"];
  /** @deprecated use cancelCashTransfer instead */
  cancelAchDeposit: CancelAchDepositResponse;
  /** @deprecated use cancelCashTransfer instead */
  cancelAchWithdrawal: CancelAchWithdrawalResponse;
  cancelCashTransfer: CancelCashTransferResponse;
  cancelDirectIndexAnalysisMeeting: DirectIndexAnalysisRequest;
  cancelDirectIndexSimulation: Scalars["Boolean"]["output"];
  cancelLiquidateDirectIndexPortfolio: LiquidateDirectIndexPortfolioRequest;
  cancelPendingLoan: PendingLoan;
  cancelTradeOrder: CancelOrderResponse;
  /** @deprecated use cancelCashTransfer instead */
  cancelWireWithdrawal: CancelWireWithdrawalResponse;
  cashTransferToDirectIndexPortfolio: Scalars["Boolean"]["output"];
  createAllocationConfig: AllocationConfig;
  createClearingHouseAccountForBusiness: ClearingAccount;
  createClearingHouseAccountForIndividual: ClearingAccount;
  createClearingHouseAccountForTrust: ClearingAccount;
  createDirectIndexSubAccount: ClearingAccount;
  createDividendPreference: DividendPreference;
  createJointAccountAndInviteCoOwner: CreateJointAccountAndInviteCoOwnerResponse;
  createJointAccountClearingHouseAccount: CreateJointAccountClearingHouseAccountResponse;
  createLinkedBrokerageAccount?: Maybe<Scalars["Boolean"]["output"]>;
  createLinkedBrokerageAccountFromPlaidAccount?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  createPendingLoan: PendingLoan;
  createScheduledDepositConfig: ScheduledDepositConfig;
  deleteAllocationConfig: Scalars["Boolean"]["output"];
  deleteBusinessEntityIndividual: Scalars["Boolean"]["output"];
  deleteDepositAccount?: Maybe<DepositAccount>;
  deleteDirectIndexSimulation: Scalars["Boolean"]["output"];
  deleteDocument: Scalars["Boolean"]["output"];
  deleteLinkedBrokerageAccount?: Maybe<Scalars["Boolean"]["output"]>;
  deletePlaidAccount?: Maybe<Scalars["Boolean"]["output"]>;
  deletePlaidConnectionById?: Maybe<Scalars["Boolean"]["output"]>;
  deleteScheduledDepositConfig: Scalars["Boolean"]["output"];
  deleteUserTrustedContact: Scalars["Boolean"]["output"];
  disableUserMfaPreference: Scalars["Boolean"]["output"];
  /** @deprecated Debug only */
  expirePlaidAccount?: Maybe<PlaidAccount>;
  generateUserCode: GenerateUserCodeResponse;
  handleEmailCapture: Scalars["Boolean"]["output"];
  /** @deprecated use initiateCashTransfer instead */
  initiateAchDeposit: InitiateAchDepositResponse;
  /** @deprecated use initiateCashTransfer instead */
  initiateAchWithdrawal: InitiateAchWithdrawalResponse;
  initiateCashTransfer: InitiateCashTransferResponse;
  initiateLoan: LoanResponse;
  initiateTreasuryTransfer: TreasuryExecution;
  /** @deprecated use initiateCashTransfer instead */
  initiateWireDeposit: InitiateWireDepositResponse;
  /** @deprecated use initiateCashTransfer instead */
  initiateWireWithdrawal: InitiateWireWithdrawalResponse;
  intervalAssessTreasuryFees: Scalars["Int"]["output"];
  intervalBackupSodFiles: BackupSodFilesResponse;
  intervalBuildAndPersistComparisonPortfolioSnapshots?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalBuildAndPersistPortfolioSnapshots?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalBuildAndPersistPortfolioSnapshotsForAllActive?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalBuildAndPersistReinvestPortfolioDeltas?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalCacheBetaValues: Scalars["Boolean"]["output"];
  intervalComputeMarginInterestForAll: Array<AggregatedMarginInterestFee>;
  intervalExecuteActiveAllocation: Scalars["Boolean"]["output"];
  intervalExecuteBatchUpdateUsers: Scalars["Boolean"]["output"];
  intervalExecuteDirectIndexDepositConfigs: Scalars["Int"]["output"];
  intervalExecuteQueuedCashTransfers: Scalars["Boolean"]["output"];
  intervalExecuteQueuedOrders: Scalars["Int"]["output"];
  intervalExecuteTreasuryConfigs: Scalars["Int"]["output"];
  intervalExecuteTreasuryExecutions: Scalars["Int"]["output"];
  intervalFetchAndPersistCorporateActions: IntervalFetchAndPersistCorporateActionsResponse;
  intervalGenerateAutoTLHCandidates: Array<GenerateAutoTlhCandidatesResponse>;
  intervalGenerateBorrowingPowerSnapshots: Scalars["Int"]["output"];
  intervalGenerateDirectIndexingCandidates: Array<GenerateCandidatesResponse>;
  intervalGenerateReport: Array<GenerateReportResponse>;
  intervalLiquidationTransitions: Scalars["Boolean"]["output"];
  intervalMonitorDirectIndexTradeExecutionStatus: Scalars["Boolean"]["output"];
  intervalPopulateSecurityIndexCache: Scalars["Boolean"]["output"];
  intervalPostProcessSodFiles: Array<Maybe<PostProcessSodFilesResponse>>;
  intervalProcessDelayedEvents: Scalars["Boolean"]["output"];
  intervalProcessPendingLoans: Array<PendingLoan>;
  intervalProcessSodFiles: Array<ProcessSodResponse>;
  intervalQueueApprovedDirectIndexTradeExecution: Scalars["Int"]["output"];
  intervalRefreshActiveSymbols: Array<Security>;
  intervalRefreshStatuses: Scalars["Boolean"]["output"];
  intervalRegeneratePortfolioSnapshots: Scalars["Int"]["output"];
  intervalReprocessFailedStatuses: Scalars["Boolean"]["output"];
  intervalRunActiveJobs: Array<RunJobsResponse>;
  intervalRunJob: RunJobResponse;
  intervalValidateSodFiles: Array<ProcessSodResponse>;
  intraAccountStockTransferFromDI: IntraAccountStockTransferFromDiResponse;
  intraAccountStockTransferToDI: Scalars["Boolean"]["output"];
  keyValueDelete: Scalars["Boolean"]["output"];
  keyValueSet: Array<KeyValuePair>;
  linkPlaid: Array<PlaidAccount>;
  linkPlaidDepositAccountToClearingAccount: DepositAccount;
  markUserNotificationAsRead: Scalars["Boolean"]["output"];
  multiIndexSimulate: Scalars["Boolean"]["output"];
  placeBatchTradeOrder: PlaceBatchTradeOrderResponse;
  plaidCreateLinkToken: PlaidLinkToken;
  plaidVerifyMicrodepositLinkToken: PlaidLinkToken;
  rebalanceAllocation: Scalars["Boolean"]["output"];
  refreshRuleEngineConfigs?: Maybe<Array<RuleEngineConfig>>;
  repayLoan: LoanResponse;
  requestClearingHouseAccountClose: UpdateClearingHouseAccountStateResponse;
  requestMfaPhoneChallenge: RequestMfaPhoneChallengeResponse;
  requestPasswordReset: Scalars["Boolean"]["output"];
  requestVerificationCode: RequestVerificationCodeResponse;
  resendJointAccountCoOwnerInvitation: ResendJointAccountCoOwnerInvitationResponse;
  revokeOidcConnection?: Maybe<Scalars["Boolean"]["output"]>;
  scheduleDirectIndexAnalysisMeeting: DirectIndexAnalysisRequest;
  sendEmailNotification?: Maybe<Scalars["Boolean"]["output"]>;
  sendInviteViaEmail: Scalars["Boolean"]["output"];
  setDirectIndexSecurityOverrides: DirectIndexAccountWeights;
  setProgressFlag?: Maybe<Array<Maybe<UserProgressFlag>>>;
  setSelfManagedTaxLossConfig: SelfManagedTaxLossConfig;
  setSelfManagedTaxLossSecurities: SelfManagedTaxLossConfig;
  setUserSource?: Maybe<UserSource>;
  simulationAcatsTransferOpsAction: Scalars["Boolean"]["output"];
  simulationAddSimulationEvent: Scalars["Boolean"]["output"];
  simulationAddSimulationResponses: Scalars["Int"]["output"];
  simulationAddTestClearingAccount?: Maybe<Scalars["String"]["output"]>;
  simulationAddTestUser?: Maybe<Scalars["String"]["output"]>;
  simulationDeleteSimulationResponses: Scalars["Int"]["output"];
  simulationDeleteSimulationResponsesForSimulationId: Scalars["Int"]["output"];
  simulationDeleteTestClearingAccount: Scalars["Boolean"]["output"];
  simulationDeleteTestUser: Scalars["Boolean"]["output"];
  simulationDirectIndexExecution: Scalars["Boolean"]["output"];
  submitVerificationCode: Scalars["Boolean"]["output"];
  updateAllocationConfig: AllocationConfig;
  updateDirectIndexPreference: DirectIndexAccountMetadata;
  updateDividendPreference: DividendPreference;
  updateEntityAccountRequestStatus: User;
  updateInvestmentSettings: InvestmentSettings;
  updateScheduledDepositConfig: ScheduledDepositConfig;
  updateStockLendingSettings?: Maybe<StockLendingInfo>;
  updateTransferOnDeathBeneficiaries: Array<TransferOnDeathBeneficiary>;
  updateUserAddress?: Maybe<GenericAddress>;
  updateUserEmploymentDetails?: Maybe<UserEmploymentDetails>;
  updateUserPersonalDetails?: Maybe<UserPersonalDetails>;
  updateUserProfile?: Maybe<UpdateUserProfileResponse>;
  updateUserTrustedContact?: Maybe<UserTrustedContact>;
  uploadDocument: UploadedDocument;
  upsertBusinessEntity: BusinessEntity;
  upsertBusinessEntityIndividual: Array<BusinessEntityIndividual>;
  upsertBusinessEntityInvestmentProfile: BusinessEntityInvestmentProfile;
  upsertBusinessEntityW9Data: BusinessEntityW9Data;
  upsertDirectIndexAnalysisRequest: DirectIndexAnalysisRequest;
  upsertDirectIndexCustomization: DirectIndexCustomization;
  upsertJointAccountCoOwner: UpsertJointAccountCoOwnerResponse;
  upsertTradeRestrictions: Array<TradeRestriction>;
  upsertTrustEntity: TrustEntity;
  upsertTrustEntityIndividual: Array<Trustee>;
  upsertUserAddress?: Maybe<GenericAddress>;
  upsertUserDisclosures?: Maybe<UserDisclosures>;
  upsertUserIdentityDetails?: Maybe<UserIdentityDetails>;
  upsertUserInvestmentProfile?: Maybe<UserInvestmentProfile>;
  upsertUserPersonalDetails?: Maybe<UserPersonalDetails>;
  upsertUserServiceProfile?: Maybe<UserServiceProfile>;
  upsertUserTaxRates?: Maybe<UserTaxRates>;
  validateMfaChallenge: ValidateMfaPhoneChallengeResponse;
  validateVerificationCode: Scalars["Boolean"]["output"];
};

export type MutationAcatsCreateTransferArgs = {
  input: AcatsCreateTransferInput;
  simulatorConfig?: InputMaybe<ApexAcatsSimulatorConfig>;
};

export type MutationAddManualAccountNumberToPlaidAccountArgs = {
  checkPaymentsAuthorized?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAccountNumber: Scalars["String"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationAddTreasuryConfigArgs = {
  input: TreasuryConfigInput;
};

export type MutationAddUserAddressArgs = {
  userAddressInput: GenericAddressInput;
};

export type MutationAddUserEmploymentDetailsArgs = {
  userEmploymentDetailsInput: UserEmploymentDetailsInput;
};

export type MutationAddUserMfaPreferenceArgs = {
  userMfaPreferenceInput: UserMfaPreferenceInput;
};

export type MutationAddUserPersonalDetailsArgs = {
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationAddUserTrustedContactArgs = {
  userTrustedContactInput: UserTrustedContactInput;
};

export type MutationAdminBackfillUserFieldsArgs = {
  input: BackfillIterableUserFieldsInput;
};

export type MutationApplyInviteCodeArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type MutationCancelAchDepositArgs = {
  cancelAchDepositInput: CancelAchDepositInput;
};

export type MutationCancelAchWithdrawalArgs = {
  cancelAchWithdrawalInput: CancelAchWithdrawalInput;
};

export type MutationCancelCashTransferArgs = {
  input: CancelCashTransferInput;
};

export type MutationCancelDirectIndexAnalysisMeetingArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelDirectIndexSimulationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelLiquidateDirectIndexPortfolioArgs = {
  input: CancelLiquidateDirectIndexPortfolioInput;
};

export type MutationCancelPendingLoanArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelTradeOrderArgs = {
  cancelOrderInput: CancelOrderInput;
};

export type MutationCancelWireWithdrawalArgs = {
  cancelWireWithdrawalInput: CancelWireWithdrawalInput;
};

export type MutationCashTransferToDirectIndexPortfolioArgs = {
  input: CashTransferToDirectIndexPortfolioInput;
};

export type MutationCreateAllocationConfigArgs = {
  input: AllocationConfigInput;
};

export type MutationCreateClearingHouseAccountForBusinessArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationCreateClearingHouseAccountForIndividualArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationCreateClearingHouseAccountForTrustArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationCreateDirectIndexSubAccountArgs = {
  input: CreateDirectIndexSubAccountInput;
};

export type MutationCreateDividendPreferenceArgs = {
  input: DividendPreferenceInput;
};

export type MutationCreateLinkedBrokerageAccountArgs = {
  input: CreateLinkedBrokerageAccountInput;
};

export type MutationCreateLinkedBrokerageAccountFromPlaidAccountArgs = {
  accountNumber: Scalars["String"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationCreatePendingLoanArgs = {
  input: CreatePendingLoanInput;
};

export type MutationCreateScheduledDepositConfigArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
  subAccountId: Scalars["ID"]["input"];
};

export type MutationDeleteAllocationConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteBusinessEntityIndividualArgs = {
  input: DeleteBusinessEntityIndividualInput;
};

export type MutationDeleteDepositAccountArgs = {
  depositAccountId: Scalars["ID"]["input"];
};

export type MutationDeleteDirectIndexSimulationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLinkedBrokerageAccountArgs = {
  linkedBrokerageAccountId: Scalars["ID"]["input"];
};

export type MutationDeletePlaidAccountArgs = {
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationDeletePlaidConnectionByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteScheduledDepositConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserTrustedContactArgs = {
  userTrustedContactId: Scalars["ID"]["input"];
};

export type MutationDisableUserMfaPreferenceArgs = {
  preferenceId: Scalars["ID"]["input"];
};

export type MutationExpirePlaidAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateUserCodeArgs = {
  input: GenerateUserCodeInput;
};

export type MutationHandleEmailCaptureArgs = {
  input: EmailCaptureInput;
};

export type MutationInitiateAchDepositArgs = {
  initiateAchDepositInput: InitiateAchDepositInput;
};

export type MutationInitiateAchWithdrawalArgs = {
  initiateAchWithdrawalInput: InitiateAchWithdrawalInput;
};

export type MutationInitiateCashTransferArgs = {
  input: InitiateCashTransferInput;
};

export type MutationInitiateLoanArgs = {
  input: InitiateLoanInput;
};

export type MutationInitiateTreasuryTransferArgs = {
  input: TreasuryTransferInput;
};

export type MutationInitiateWireDepositArgs = {
  initiateWireDepositInput: InitiateWireDepositInput;
};

export type MutationInitiateWireWithdrawalArgs = {
  initiateWireWithdrawalInput: InitiateWireWithdrawalInput;
};

export type MutationIntervalBackupSodFilesArgs = {
  input: BackupSodFilesInput;
};

export type MutationIntervalBuildAndPersistComparisonPortfolioSnapshotsArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  v2Only?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationIntervalBuildAndPersistPortfolioSnapshotsArgs = {
  date: Scalars["Date"]["input"];
  subAccountIds: Array<Scalars["String"]["input"]>;
};

export type MutationIntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs =
  {
    input: IntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs;
  };

export type MutationIntervalBuildAndPersistReinvestPortfolioDeltasArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  generateV2ComparisonSnapshots?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationIntervalCacheBetaValuesArgs = {
  input: CacheBetaValueInput;
};

export type MutationIntervalComputeMarginInterestForAllArgs = {
  billingPeriodStr?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationIntervalExecuteActiveAllocationArgs = {
  input: ExecuteActiveAllocationInput;
};

export type MutationIntervalExecuteBatchUpdateUsersArgs = {
  input?: InputMaybe<IntervalExecuteBatchUpdateUsersInput>;
};

export type MutationIntervalExecuteQueuedCashTransfersArgs = {
  input?: InputMaybe<IntervalExecuteQueuedCashTransfersInput>;
};

export type MutationIntervalFetchAndPersistCorporateActionsArgs = {
  input: IntervalFetchAndPersistCorporateActionsInput;
};

export type MutationIntervalGenerateAutoTlhCandidatesArgs = {
  input: GenerateTlhCandidatesInput;
};

export type MutationIntervalGenerateBorrowingPowerSnapshotsArgs = {
  date: Scalars["Date"]["input"];
};

export type MutationIntervalGenerateDirectIndexingCandidatesArgs = {
  input: GenerateCandidatesInput;
};

export type MutationIntervalGenerateReportArgs = {
  input: GenerateReportInput;
};

export type MutationIntervalMonitorDirectIndexTradeExecutionStatusArgs = {
  input: MonitorTradeExecutionStatusInput;
};

export type MutationIntervalPopulateSecurityIndexCacheArgs = {
  input: IntervalPopulateSecurityIndexCacheInput;
};

export type MutationIntervalPostProcessSodFilesArgs = {
  input: PostProcessSodFilesInput;
};

export type MutationIntervalProcessDelayedEventsArgs = {
  eventType: EventType;
};

export type MutationIntervalProcessSodFilesArgs = {
  files: Array<ExtractNumber>;
  input: ProcessSodFilesInputArgs;
};

export type MutationIntervalQueueApprovedDirectIndexTradeExecutionArgs = {
  input: QueueApprovedDirectIndexTradeExecutionInput;
};

export type MutationIntervalRefreshStatusesArgs = {
  eventType: EventType;
};

export type MutationIntervalRegeneratePortfolioSnapshotsArgs = {
  input: IntervalRegeneratePortfolioSnapshotsInput;
};

export type MutationIntervalReprocessFailedStatusesArgs = {
  eventType: EventType;
};

export type MutationIntervalRunJobArgs = {
  input: RunJobInput;
};

export type MutationIntervalValidateSodFilesArgs = {
  files: Array<ExtractNumber>;
  input: ValidateSodFilesInputArgs;
};

export type MutationIntraAccountStockTransferFromDiArgs = {
  input: TransferStockFromDiInput;
};

export type MutationIntraAccountStockTransferToDiArgs = {
  input: TransferStockSubAccountInput;
};

export type MutationKeyValueDeleteArgs = {
  input: KeyValueDeleteInput;
};

export type MutationKeyValueSetArgs = {
  input: KeyValueSetInput;
};

export type MutationLinkPlaidArgs = {
  input: LinkPlaidInput;
};

export type MutationLinkPlaidDepositAccountToClearingAccountArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationMarkUserNotificationAsReadArgs = {
  notificationId: Scalars["ID"]["input"];
};

export type MutationMultiIndexSimulateArgs = {
  input: MultiIndexSimulateInput;
};

export type MutationPlaceBatchTradeOrderArgs = {
  input: PlaceBatchTradeOrderInput;
};

export type MutationPlaidCreateLinkTokenArgs = {
  androidPackageName?: InputMaybe<Scalars["String"]["input"]>;
  type: PlaidAccountType;
  updateAccountId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPlaidVerifyMicrodepositLinkTokenArgs = {
  plaidAccountId: Scalars["String"]["input"];
  type: PlaidAccountType;
};

export type MutationRebalanceAllocationArgs = {
  input: RebalanceAllocationInput;
};

export type MutationRepayLoanArgs = {
  input: RepayLoanInput;
};

export type MutationRequestClearingHouseAccountCloseArgs = {
  accountId: Scalars["ID"]["input"];
  undo?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationRequestMfaPhoneChallengeArgs = {
  input: RequestMfaPhoneChallengeInput;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRequestVerificationCodeArgs = {
  channel: PhoneVerificationChannel;
  phone: Scalars["String"]["input"];
};

export type MutationResendJointAccountCoOwnerInvitationArgs = {
  input: ResendJointAccountCoOwnerInvitationInput;
};

export type MutationRevokeOidcConnectionArgs = {
  input: RevokeOidcConnectionInput;
};

export type MutationScheduleDirectIndexAnalysisMeetingArgs = {
  input: ScheduleDirectIndexAnalysisMeetingInput;
};

export type MutationSendEmailNotificationArgs = {
  clearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  event: NotificationEvent;
};

export type MutationSendInviteViaEmailArgs = {
  email: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSetDirectIndexSecurityOverridesArgs = {
  input: DirectIndexOverrideInput;
};

export type MutationSetProgressFlagArgs = {
  input: SetProgressFlagInput;
};

export type MutationSetSelfManagedTaxLossConfigArgs = {
  input: SelfManagedTaxLossConfigInput;
};

export type MutationSetSelfManagedTaxLossSecuritiesArgs = {
  input: SelfManagedTaxLossSecuritiesInput;
};

export type MutationSetUserSourceArgs = {
  input: UserSourceInput;
};

export type MutationSimulationAcatsTransferOpsActionArgs = {
  input: OpsAcatsTransferInput;
};

export type MutationSimulationAddSimulationEventArgs = {
  event: ApexSimulationEventInput;
};

export type MutationSimulationAddSimulationResponsesArgs = {
  input: Array<MarketDataSimulationArgs>;
};

export type MutationSimulationAddTestClearingAccountArgs = {
  data: ClearingAccountQaDataInput;
};

export type MutationSimulationAddTestUserArgs = {
  data: UserQaDataInput;
};

export type MutationSimulationDeleteSimulationResponsesArgs = {
  responseIds: Array<Scalars["String"]["input"]>;
};

export type MutationSimulationDeleteSimulationResponsesForSimulationIdArgs = {
  simulationId: Scalars["Int"]["input"];
};

export type MutationSimulationDeleteTestClearingAccountArgs = {
  clearingAccountId: Scalars["String"]["input"];
};

export type MutationSimulationDeleteTestUserArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationSimulationDirectIndexExecutionArgs = {
  input: SimulationDirectIndexExecutionInput;
};

export type MutationSubmitVerificationCodeArgs = {
  code: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type MutationUpdateAllocationConfigArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateAllocationConfigInput;
};

export type MutationUpdateDirectIndexPreferenceArgs = {
  input: DirectIndexPreferenceInput;
};

export type MutationUpdateDividendPreferenceArgs = {
  id: Scalars["ID"]["input"];
  input: DividendPreferenceInput;
};

export type MutationUpdateEntityAccountRequestStatusArgs = {
  input: UpdateUserEntityAccountRequestStatusInput;
};

export type MutationUpdateInvestmentSettingsArgs = {
  input: InvestmentSettingsInput;
};

export type MutationUpdateScheduledDepositConfigArgs = {
  id: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
};

export type MutationUpdateStockLendingSettingsArgs = {
  input: UpdateStockLendingSettingsInput;
};

export type MutationUpdateTransferOnDeathBeneficiariesArgs = {
  input: UpdateTransferOnDeathBeneficiariesInput;
};

export type MutationUpdateUserAddressArgs = {
  userAddressId: Scalars["ID"]["input"];
  userAddressInput: GenericAddressInput;
};

export type MutationUpdateUserEmploymentDetailsArgs = {
  userEmploymentDetailsInput: UserEmploymentDetailsInput;
  userEmploymentId: Scalars["ID"]["input"];
};

export type MutationUpdateUserPersonalDetailsArgs = {
  userPersonalDetailsId: Scalars["ID"]["input"];
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationUpdateUserProfileArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  input: UpdateUserProfileInput;
};

export type MutationUpdateUserTrustedContactArgs = {
  userTrustedContactId: Scalars["ID"]["input"];
  userTrustedContactInput: UserTrustedContactInput;
};

export type MutationUploadDocumentArgs = {
  input: UploadDocumentInput;
};

export type MutationUpsertBusinessEntityArgs = {
  input: UpsertBusinessEntityInput;
};

export type MutationUpsertBusinessEntityIndividualArgs = {
  input: Array<UpsertBusinessEntityIndividualInput>;
};

export type MutationUpsertBusinessEntityInvestmentProfileArgs = {
  input: UpsertBusinessEntityInvestmentProfileInput;
};

export type MutationUpsertBusinessEntityW9DataArgs = {
  input: UpsertBusinessEntityW9DataInput;
};

export type MutationUpsertDirectIndexAnalysisRequestArgs = {
  input: DirectIndexAnalysisRequestInput;
};

export type MutationUpsertDirectIndexCustomizationArgs = {
  input: DirectIndexCustomizationInput;
};

export type MutationUpsertJointAccountCoOwnerArgs = {
  input: UpsertJointAccountCoOwnerInput;
};

export type MutationUpsertTradeRestrictionsArgs = {
  input: UpsertTradeRestrictionsInput;
};

export type MutationUpsertTrustEntityArgs = {
  input: UpsertTrustEntityInput;
};

export type MutationUpsertTrustEntityIndividualArgs = {
  input: UpsertTrustEntityIndividualInput;
};

export type MutationUpsertUserAddressArgs = {
  userAddressInput: GenericAddressInput;
};

export type MutationUpsertUserDisclosuresArgs = {
  userDisclosuresInput: UserDisclosuresInput;
};

export type MutationUpsertUserIdentityDetailsArgs = {
  userIdentityDetailsInput: UserIdentityDetailsInput;
};

export type MutationUpsertUserInvestmentProfileArgs = {
  userInvestmentProfileInput: UserInvestmentProfileInput;
};

export type MutationUpsertUserPersonalDetailsArgs = {
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationUpsertUserServiceProfileArgs = {
  userServiceProfileInput: UserServiceProfileInput;
};

export type MutationUpsertUserTaxRatesArgs = {
  userTaxRatesInput: UserTaxRatesInput;
};

export type MutationValidateMfaChallengeArgs = {
  input: ValidateMfaPhoneChallengeInput;
};

export type MutationValidateVerificationCodeArgs = {
  input: VerificationCodeInput;
};

export enum NotificationCategory {
  AcatsTransfer = "ACATS_TRANSFER",
  AccountManagement = "ACCOUNT_MANAGEMENT",
  CashTransfer = "CASH_TRANSFER",
  DirectIndex = "DIRECT_INDEX",
  Disclosure = "DISCLOSURE",
  EntityAccountRequest = "ENTITY_ACCOUNT_REQUEST",
  Invitation = "INVITATION",
  JointAccount = "JOINT_ACCOUNT",
  Loan = "LOAN",
  MarginCall = "MARGIN_CALL",
  MarginInterest = "MARGIN_INTEREST",
  Order = "ORDER",
  Payment = "PAYMENT",
  Referral = "REFERRAL",
  Treasury = "TREASURY",
}

export enum NotificationChannel {
  Email = "EMAIL",
  InApp = "IN_APP",
  Sms = "SMS",
}

export type NotificationCta = {
  __typename?: "NotificationCta";
  id?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
};

export enum NotificationEvent {
  AcatsTransferFailure = "ACATS_TRANSFER_FAILURE",
  AcatsTransferOutFullRequestReceived = "ACATS_TRANSFER_OUT_FULL_REQUEST_RECEIVED",
  AcatsTransferOutPartialRequestReceived = "ACATS_TRANSFER_OUT_PARTIAL_REQUEST_RECEIVED",
  AcatsTransferRequestSubmitted = "ACATS_TRANSFER_REQUEST_SUBMITTED",
  /** @deprecated Use ACATS_TRANSFER_REQUEST_SUBMITTED */
  AcatsTransferRequestSubmittedFirstTime = "ACATS_TRANSFER_REQUEST_SUBMITTED_FIRST_TIME",
  AcatsTransferReviewApproved = "ACATS_TRANSFER_REVIEW_APPROVED",
  AcatsTransferSuccess = "ACATS_TRANSFER_SUCCESS",
  AccountPending = "ACCOUNT_PENDING",
  Activation = "ACTIVATION",
  AdvisoryAgreementUpdate = "ADVISORY_AGREEMENT_UPDATE",
  AnnualDisclosure = "ANNUAL_DISCLOSURE",
  CashTransferFailure = "CASH_TRANSFER_FAILURE",
  CashTransferRequestSubmitted = "CASH_TRANSFER_REQUEST_SUBMITTED",
  CashTransferSuccess = "CASH_TRANSFER_SUCCESS",
  DirectIndexPortfolioReady = "DIRECT_INDEX_PORTFOLIO_READY",
  DocumentsNeeded = "DOCUMENTS_NEEDED",
  EmailVerification = "EMAIL_VERIFICATION",
  EntityAccountReady = "ENTITY_ACCOUNT_READY",
  ExistingUserAccountCreationAttempt = "EXISTING_USER_ACCOUNT_CREATION_ATTEMPT",
  FormCrsUpdate = "FORM_CRS_UPDATE",
  FormCrsUpdateWithTreasury = "FORM_CRS_UPDATE_WITH_TREASURY",
  InitiatorInviteCoOwner = "INITIATOR_INVITE_CO_OWNER",
  Invite = "INVITE",
  JointAccountReady = "JOINT_ACCOUNT_READY",
  LineOfCreditAlmostReady = "LINE_OF_CREDIT_ALMOST_READY",
  LineOfCreditReady = "LINE_OF_CREDIT_READY",
  LoanSuccess = "LOAN_SUCCESS",
  LoanSuccessCashTransferFailure = "LOAN_SUCCESS_CASH_TRANSFER_FAILURE",
  LoanSuccessCashTransferInitiated = "LOAN_SUCCESS_CASH_TRANSFER_INITIATED",
  MarginCallReminder = "MARGIN_CALL_REMINDER",
  MarginCallResolved = "MARGIN_CALL_RESOLVED",
  MarginCallWarning = "MARGIN_CALL_WARNING",
  MarginInterestAutoPaymentConfirmed = "MARGIN_INTEREST_AUTO_PAYMENT_CONFIRMED",
  MarginInterestDecrease = "MARGIN_INTEREST_DECREASE",
  MarginInterestIncrease = "MARGIN_INTEREST_INCREASE",
  OrderCompleted = "ORDER_COMPLETED",
  OrderInitiated = "ORDER_INITIATED",
  PasswordReset = "PASSWORD_RESET",
  PasswordUpdateSuccess = "PASSWORD_UPDATE_SUCCESS",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentLowBalanceAlert = "PAYMENT_LOW_BALANCE_ALERT",
  PaymentOneTimeInitiated = "PAYMENT_ONE_TIME_INITIATED",
  PaymentRelinkCreditCard = "PAYMENT_RELINK_CREDIT_CARD",
  PlaidConnectionApproved = "PLAID_CONNECTION_APPROVED",
  ReferralBonusEarned = "REFERRAL_BONUS_EARNED",
  ReferralCodeUsedEarnedDelayedCashReward = "REFERRAL_CODE_USED_EARNED_DELAYED_CASH_REWARD",
  TreasuryDepositInitiated = "TREASURY_DEPOSIT_INITIATED",
  TreasuryDepositSuccess = "TREASURY_DEPOSIT_SUCCESS",
  TreasuryRelinkBankAccount = "TREASURY_RELINK_BANK_ACCOUNT",
  TreasuryWithdrawalInitiated = "TREASURY_WITHDRAWAL_INITIATED",
  TreasuryYieldLanded = "TREASURY_YIELD_LANDED",
  Welcome = "WELCOME",
}

export type NotificationLiveTemplate = {
  __typename?: "NotificationLiveTemplate";
  category: NotificationCategory;
  channel: NotificationChannel;
  event: NotificationEvent;
  id: Scalars["ID"]["output"];
  version: Scalars["Int"]["output"];
};

export enum NotificationStatus {
  Failure = "FAILURE",
  Queued = "QUEUED",
  Read = "READ",
  Sent = "SENT",
}

export type NotificationTemplate = {
  __typename?: "NotificationTemplate";
  category: NotificationCategory;
  channel: NotificationChannel;
  createdBy?: Maybe<Scalars["String"]["output"]>;
  event: NotificationEvent;
  id: Scalars["ID"]["output"];
  live: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  subject?: Maybe<Scalars["String"]["output"]>;
  template: Scalars["String"]["output"];
  type: NotificationType;
  version: Scalars["Int"]["output"];
};

export enum NotificationType {
  Marketing = "MARKETING",
  Transactional = "TRANSACTIONAL",
}

export type OidcClientApplicationDetails = {
  __typename?: "OidcClientApplicationDetails";
  aggregator?: Maybe<FdxConnectionAggregator>;
  applicationName?: Maybe<Scalars["String"]["output"]>;
  lastSyncTime?: Maybe<Scalars["DateTime"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
};

export type OidcClientApplicationDetailsInput = {
  recipientId: Scalars["String"]["input"];
};

export type OidcGrantMetadata = {
  __typename?: "OidcGrantMetadata";
  connectionDetails?: Maybe<OidcClientApplicationDetails>;
  id: Scalars["ID"]["output"];
};

export enum OnboardingAccountCreationRequestStatus {
  Completed = "COMPLETED",
  Created = "CREATED",
  Pending = "PENDING",
}

export type OpsAcatsTransferInput = {
  acatsTransferId: Scalars["ID"]["input"];
  eventTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: ManualReviewStatus;
};

export type Order = {
  __typename?: "Order";
  adminComments?: Maybe<Array<Scalars["String"]["output"]>>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  executedTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  limitDuration?: Maybe<Scalars["DateTime"]["output"]>;
  limitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  longTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  lotMatchingInstructions?: Maybe<Array<LotMatchingInstruction>>;
  maxSellQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  opsInitiated: Scalars["Boolean"]["output"];
  orderFees: Array<OrderFee>;
  orderPrice?: Maybe<Scalars["Decimal"]["output"]>;
  orderStatus: OrderStatus;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["output"];
  quantityType?: Maybe<QuantityType>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  shortTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  side: OrderSide;
  source?: Maybe<BatchOrderSource>;
  statusHistory?: Maybe<Array<OrderStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export type OrderDetailsInput = {
  clearingAccountId: Scalars["ID"]["input"];
  limitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  maxSellQuantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["input"];
  quantityType: QuantityType;
  side: OrderSide;
  symbol: Scalars["String"]["input"];
};

export type OrderFee = {
  __typename?: "OrderFee";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: OrderFeeType;
};

export enum OrderFeeType {
  Adr = "ADR",
  MmfTrade = "MMF_TRADE",
  Sec = "SEC",
  Taf = "TAF",
}

export enum OrderPositionType {
  Long = "LONG",
  Short = "SHORT",
}

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderStatus {
  AdminInitiated = "ADMIN_INITIATED",
  Canceled = "CANCELED",
  CancelAcknowledged = "CANCEL_ACKNOWLEDGED",
  CancelPending = "CANCEL_PENDING",
  CancelRejected = "CANCEL_REJECTED",
  CancelRequested = "CANCEL_REQUESTED",
  Executed = "EXECUTED",
  Filled = "FILLED",
  Invalidated = "INVALIDATED",
  NotFilled = "NOT_FILLED",
  Pending = "PENDING",
  Queued = "QUEUED",
  Rejected = "REJECTED",
  UserInitiated = "USER_INITIATED",
  UserQueued = "USER_QUEUED",
}

export type OrderStatusHistory = {
  __typename?: "OrderStatusHistory";
  eventTime: Scalars["DateTime"]["output"];
  externalStatus?: Maybe<Scalars["String"]["output"]>;
  externalStatusCode?: Maybe<Scalars["String"]["output"]>;
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  fillAvgPrice?: Maybe<Scalars["Decimal"]["output"]>;
  fillNotional?: Maybe<Scalars["Decimal"]["output"]>;
  fillShares?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  orderId: Scalars["String"]["output"];
  orderStatus: OrderStatus;
};

export enum OrderSubmissionMode {
  Default = "DEFAULT",
  ExecuteQueued = "EXECUTE_QUEUED",
  Queue = "QUEUE",
}

export enum OrderType {
  Limit = "LIMIT",
  Market = "MARKET",
}

export type PaginatedAcatsTransferResponse = {
  __typename?: "PaginatedAcatsTransferResponse";
  data?: Maybe<Array<AcatsTransfer>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedActivity = {
  __typename?: "PaginatedActivity";
  data: Activity;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCashDividendResponse = {
  __typename?: "PaginatedCashDividendResponse";
  data?: Maybe<Array<CashDividend>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCashTransferResponse = {
  __typename?: "PaginatedCashTransferResponse";
  data?: Maybe<Array<CashTransfer>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCompletedInviteStatusResponse = {
  __typename?: "PaginatedCompletedInviteStatusResponse";
  data: Array<CompletedInviteStatusEntry>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedDailyCashDividendResponse = {
  __typename?: "PaginatedDailyCashDividendResponse";
  data?: Maybe<Array<DailyCashDividend>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedLoanEventResponse = {
  __typename?: "PaginatedLoanEventResponse";
  data?: Maybe<Array<LoanEvent>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedMarginInterestChargesResponse = {
  __typename?: "PaginatedMarginInterestChargesResponse";
  data?: Maybe<Array<MarginInterestCharge>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedOrderResponse = {
  __typename?: "PaginatedOrderResponse";
  data?: Maybe<Array<Order>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedPendingInviteStatusResponse = {
  __typename?: "PaginatedPendingInviteStatusResponse";
  data: Array<PendingInviteStatusEntry>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedSimpleActivity = {
  __typename?: "PaginatedSimpleActivity";
  data: Array<SimpleActivity>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedStockAdjustmentResponse = {
  __typename?: "PaginatedStockAdjustmentResponse";
  data?: Maybe<Array<StockAdjustmentDetails>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentAmountType {
  FixedAmount = "FIXED_AMOUNT",
  StatementBalance = "STATEMENT_BALANCE",
}

export type PaymentConfig = {
  __typename?: "PaymentConfig";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  amountType: PaymentAmountType;
  cardName?: Maybe<Scalars["String"]["output"]>;
  dayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  destinationAccount?: Maybe<PlaidAccount>;
  destinationAccountId: Scalars["ID"]["output"];
  destinationType: PaymentDestinationType;
  id: Scalars["ID"]["output"];
  periodType?: Maybe<PaymentPeriodType>;
  sourceSecurity?: Maybe<Security>;
  sourceSecurityId?: Maybe<Scalars["ID"]["output"]>;
  sourceType: PaymentSourceType;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  type: PaymentType;
};

export type PaymentConfigInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  amountType: PaymentAmountType;
  dayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  destinationAccountId: Scalars["ID"]["input"];
  destinationType: PaymentDestinationType;
  periodType?: InputMaybe<PaymentPeriodType>;
  sourceSecurityId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceType: PaymentSourceType;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: PaymentType;
};

export enum PaymentDestinationType {
  CreditCard = "CREDIT_CARD",
}

export type PaymentExecution = {
  __typename?: "PaymentExecution";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  failureReason?: Maybe<PaymentFailureReason>;
  id: Scalars["ID"]["output"];
  paymentConfig?: Maybe<PaymentConfig>;
  paymentConfigId: Scalars["ID"]["output"];
  status: PaymentExecutionStatus;
};

export enum PaymentExecutionStatus {
  Failure = "FAILURE",
  Initiated = "INITIATED",
  LoanApproved = "LOAN_APPROVED",
  LoanInitiated = "LOAN_INITIATED",
  MmfSaleCompleted = "MMF_SALE_COMPLETED",
  MmfSaleInitiated = "MMF_SALE_INITIATED",
  Terminated = "TERMINATED",
  WithdrawalCompleted = "WITHDRAWAL_COMPLETED",
  WithdrawalInitiated = "WITHDRAWAL_INITIATED",
  WithdrawalRetryableFailure = "WITHDRAWAL_RETRYABLE_FAILURE",
}

export enum PaymentFailureReason {
  AdminCancelled = "ADMIN_CANCELLED",
  LineOfCreditFailure = "LINE_OF_CREDIT_FAILURE",
  MmfFailure = "MMF_FAILURE",
  UserCancelled = "USER_CANCELLED",
  WithdrawalFailure = "WITHDRAWAL_FAILURE",
}

export enum PaymentPeriodType {
  DayOfMonth = "DAY_OF_MONTH",
  StatementDate = "STATEMENT_DATE",
}

export enum PaymentSourceType {
  FrecCash = "FREC_CASH",
  LineOfCredit = "LINE_OF_CREDIT",
  MoneyMarketFund = "MONEY_MARKET_FUND",
  Treasury = "TREASURY",
}

export enum PaymentType {
  PeriodicPayment = "PERIODIC_PAYMENT",
  SinglePayment = "SINGLE_PAYMENT",
}

export type PendingInviteStatusEntry = {
  __typename?: "PendingInviteStatusEntry";
  refereeEmail: Scalars["String"]["output"];
  referredOn: Scalars["DateTime"]["output"];
};

export type PendingInvitesForUserInput = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PendingLoan = {
  __typename?: "PendingLoan";
  amount: Scalars["Decimal"]["output"];
  cashTransferMethod?: Maybe<CashTransferMethod>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  completedLoanId?: Maybe<Scalars["ID"]["output"]>;
  depositAccountId?: Maybe<Scalars["ID"]["output"]>;
  depositAccountTransferId?: Maybe<Scalars["ID"]["output"]>;
  destinationId?: Maybe<Scalars["ID"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  status: PendingLoanStatus;
  type: PendingLoanType;
  userId: Scalars["ID"]["output"];
};

export enum PendingLoanStatus {
  Cancelled = "CANCELLED",
  Complete = "COMPLETE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum PendingLoanType {
  Loan = "LOAN",
  LoanRepayment = "LOAN_REPAYMENT",
  LoanToIntraAccount = "LOAN_TO_INTRA_ACCOUNT",
  Unknown = "UNKNOWN",
}

export enum PerformanceDataPeriod {
  All = "ALL",
  M1 = "M1",
  M3 = "M3",
  M6 = "M6",
  Y1 = "Y1",
  Y3 = "Y3",
  Y5 = "Y5",
  Y10 = "Y10",
  Ytd = "YTD",
}

export type PersistEvent = {
  __typename?: "PersistEvent";
  path?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  type?: Maybe<PersistEventType>;
};

export enum PersistEventType {
  Db = "DB",
  LocalFs = "LOCAL_FS",
  S3 = "S3",
}

export enum PhoneNumberType {
  Fax = "FAX",
  Home = "HOME",
  Mobile = "MOBILE",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
  Work = "WORK",
}

export enum PhoneVerificationChannel {
  Call = "call",
  Sms = "sms",
}

export enum PiiType {
  ApiPayload = "API_PAYLOAD",
  PlaidAccountNumber = "PLAID_ACCOUNT_NUMBER",
  Ssn = "SSN",
  TaxId = "TAX_ID",
}

export type PlaceBatchTradeOrderInput = {
  clearingAccountId: Scalars["ID"]["input"];
  isUserQueuedOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  orders: Array<BatchOrderSingleInput>;
};

export type PlaceBatchTradeOrderResponse = {
  __typename?: "PlaceBatchTradeOrderResponse";
  batchTradeOrder?: Maybe<BatchTradeOrder>;
  failures: Array<PlaceOrderFailure>;
};

export type PlaceOrderFailure = {
  __typename?: "PlaceOrderFailure";
  message: Scalars["String"]["output"];
};

export type PlaidAccount = {
  __typename?: "PlaidAccount";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  apexProcessorToken?: Maybe<Scalars["String"]["output"]>;
  connectionId: Scalars["ID"]["output"];
  creditDetails?: Maybe<PlaidAccountCreditDetails>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  error?: Maybe<PlaidAccountError>;
  frecNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  hasMissingAccountNumber: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  identityInfo?: Maybe<Scalars["String"]["output"]>;
  institutionName: Scalars["String"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nameMatchScore?: Maybe<Scalars["Float"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  owners?: Maybe<Scalars["String"]["output"]>;
  plaidInstitutionId?: Maybe<Scalars["String"]["output"]>;
  portfolio?: Maybe<PlaidAccountPortfolio>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
  status: PlaidAccountStatus;
  statusHistory: Array<Maybe<PlaidStatusHistory>>;
  subType: PlaidAccountSubType;
  type: PlaidAccountType;
  userId: Scalars["String"]["output"];
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
  wireRoutingNumber?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidAccountAcatsTransfer = {
  __typename?: "PlaidAccountAcatsTransfer";
  status?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidAccountCreditDetails = {
  __typename?: "PlaidAccountCreditDetails";
  accountAvailableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  accountCurrentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  accountLimit?: Maybe<Scalars["Decimal"]["output"]>;
  error?: Maybe<PlaidAccountError>;
  isOverdue?: Maybe<Scalars["Boolean"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["String"]["output"]>;
  lastStatementBalance?: Maybe<Scalars["Decimal"]["output"]>;
  lastStatementIssueDate?: Maybe<Scalars["String"]["output"]>;
  minimumPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  nextPaymentDueDate?: Maybe<Scalars["String"]["output"]>;
  remainingStatementBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum PlaidAccountError {
  ItemLoginRequired = "ITEM_LOGIN_REQUIRED",
  None = "NONE",
  Other = "OTHER",
}

export type PlaidAccountPortfolio = {
  __typename?: "PlaidAccountPortfolio";
  /** @deprecated Use cashBalance instead */
  balance: Scalars["Decimal"]["output"];
  cashBalance: Scalars["Decimal"]["output"];
  error?: Maybe<PlaidAccountError>;
  holdings: Array<PlaidAccountPortfolioHolding>;
  totalBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PlaidAccountPortfolioHolding = {
  __typename?: "PlaidAccountPortfolioHolding";
  closingPrice?: Maybe<Scalars["Float"]["output"]>;
  costBasis: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  cusip?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  plaidAccountId: Scalars["ID"]["output"];
  quantity: Scalars["Float"]["output"];
  security?: Maybe<Security>;
  securityId?: Maybe<Scalars["ID"]["output"]>;
  supported: Scalars["Boolean"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum PlaidAccountStatus {
  OpsGranted = "OPS_GRANTED",
  OpsRejected = "OPS_REJECTED",
  SystemGranted = "SYSTEM_GRANTED",
  SystemPending = "SYSTEM_PENDING",
  /** @deprecated Use SYSTEM_PENDING and then OPS_REJECTED */
  SystemRejected = "SYSTEM_REJECTED",
  UserDeleted = "USER_DELETED",
}

export enum PlaidAccountSubType {
  Brokerage = "BROKERAGE",
  Checking = "CHECKING",
  CreditCard = "CREDIT_CARD",
  Other = "OTHER",
  Savings = "SAVINGS",
}

export enum PlaidAccountType {
  Credit = "CREDIT",
  Depository = "DEPOSITORY",
  Investment = "INVESTMENT",
  Loan = "LOAN",
  Other = "OTHER",
}

export type PlaidAccountsInput = {
  hasLinkedBrokerageAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<PlaidAccountType>;
};

export type PlaidConnection = {
  __typename?: "PlaidConnection";
  consentExpirationTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  plaidAccounts: Array<PlaidAccount>;
  plaidInstitutionId?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidConnectionPlaidAccountsArgs = {
  input?: InputMaybe<PlaidAccountsInput>;
};

export type PlaidLinkToken = {
  __typename?: "PlaidLinkToken";
  expiresAt: Scalars["DateTime"]["output"];
  token: Scalars["String"]["output"];
};

export type PlaidStatusHistory = {
  __typename?: "PlaidStatusHistory";
  comment?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  status: PlaidAccountStatus;
};

export type Portfolio = {
  __typename?: "Portfolio";
  asOfDate?: Maybe<Scalars["DateTime"]["output"]>;
  availability: AvailabilityLevel;
  cash?: Maybe<Array<LiveCashHolding>>;
  clearingAccountId: Scalars["ID"]["output"];
  securities?: Maybe<Array<LiveSecurityHolding>>;
};

export type PortfolioAggregateValues = {
  __typename?: "PortfolioAggregateValues";
  cashValue: Scalars["Decimal"]["output"];
  dailyChange: Scalars["Decimal"]["output"];
  loanValue?: Maybe<Scalars["Decimal"]["output"]>;
  mmfDividendYTD: Scalars["Decimal"]["output"];
  mmfHoldingValue: Scalars["Decimal"]["output"];
  securityValue: Scalars["Decimal"]["output"];
};

export type PortfolioBorrowingPower = {
  __typename?: "PortfolioBorrowingPower";
  asOfDate: Scalars["DateTime"]["output"];
  borrowingPower: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  currentMaxBorrow: Scalars["Decimal"]["output"];
  frecBorrowingPower: Scalars["Decimal"]["output"];
  smaMaxBorrow: Scalars["Decimal"]["output"];
  sodMaxBorrow: Scalars["Decimal"]["output"];
};

export type PortfolioInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  availability: AvailabilityLevel;
  basePortfolio?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  useCache?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PortfolioSnapshot = {
  __typename?: "PortfolioSnapshot";
  cashBalance: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  date: Scalars["DateTime"]["output"];
  fees: Scalars["Decimal"]["output"];
  loan: Scalars["Decimal"]["output"];
  portfolioSnapshotHoldingClosingValue: Scalars["Decimal"]["output"];
};

export type PortfolioSnapshotHolding = {
  __typename?: "PortfolioSnapshotHolding";
  closingPrice: Scalars["Decimal"]["output"];
  maintenanceRequirement: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  securityId: Scalars["String"]["output"];
};

export type PortfolioStartOfDayValues = {
  __typename?: "PortfolioStartOfDayValues";
  SMABalance: Scalars["Decimal"]["output"];
  asOfDate: Scalars["DateTime"]["output"];
  availableToWithdraw: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  impliedSMA: Scalars["Decimal"]["output"];
  maintenanceRequirement: Scalars["Decimal"]["output"];
  marginExcessEquity: Scalars["Decimal"]["output"];
  marginRequirementWithConcentration: Scalars["Decimal"]["output"];
  netLoan: Scalars["Decimal"]["output"];
  positionMarketValue: Scalars["Decimal"]["output"];
  totalEquity: Scalars["Decimal"]["output"];
};

export type PortfolioValuesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
};

export type PostProcessEvent = {
  __typename?: "PostProcessEvent";
  apexSodFileId?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  processName: SodPostProcess;
  status: PostProcessStatus;
};

export type PostProcessSodFilesInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterByApexAccounts?: InputMaybe<Array<Scalars["String"]["input"]>>;
  filterByRecordId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  processNames: Array<SodPostProcess>;
};

export type PostProcessSodFilesResponse = {
  __typename?: "PostProcessSodFilesResponse";
  count: Scalars["Int"]["output"];
  date: Scalars["Date"]["output"];
  process: SodPostProcess;
  status: Scalars["Boolean"]["output"];
};

export enum PostProcessStatus {
  Fail = "FAIL",
  Invalidated = "INVALIDATED",
  ReviewRequired = "REVIEW_REQUIRED",
  Running = "RUNNING",
  Skip = "SKIP",
  Success = "SUCCESS",
}

export type ProcessSodFilesInputArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  skipMissing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProcessSodResponse = {
  __typename?: "ProcessSodResponse";
  date?: Maybe<Scalars["Date"]["output"]>;
  extractNumber: ExtractNumber;
  status: StatusCode;
};

export enum ProgressFlagCategory {
  DirectIndexAnalysis = "DIRECT_INDEX_ANALYSIS",
  FirstLoan = "FIRST_LOAN",
  Onboarding = "ONBOARDING",
}

export enum ProgressFlagStatus {
  Completed = "COMPLETED",
  New = "NEW",
}

export enum QuantityType {
  Fractional = "FRACTIONAL",
  Notional = "NOTIONAL",
}

export type Query = {
  __typename?: "Query";
  allocationExecution?: Maybe<AllocationExecution>;
  betaValues: Array<StockBeta>;
  businessEntity?: Maybe<BusinessEntity>;
  clearingAccount?: Maybe<ClearingAccount>;
  companyReferralCount: Scalars["Int"]["output"];
  comparisonPortfolioHistory: Array<ComparisonPortfolioSnapshot>;
  currentInterestRate: Scalars["Decimal"]["output"];
  demoClearingAccount?: Maybe<ClearingAccount>;
  demoComparisonPortfolioHistory: Array<ComparisonPortfolioSnapshot>;
  demoGetDirectIndexFeeCharge?: Maybe<DirectIndexFeeCharge>;
  demoGetDirectIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  /** @deprecated Field no longer supported */
  demoGetDirectIndexPerformanceData: DirectIndexPerformanceData;
  demoGetDirectIndexPerformanceDataV2: DirectIndexPerformanceData;
  demoGetDirectIndexTradeExecutionSummary?: Maybe<DirectIndexTradeExecutionSummary>;
  demoGetDirectIndexWeightsForAccount: DirectIndexAccountWeights;
  demoGetIndexConstituents: IndexConstituentData;
  demoGetLiquidateDirectIndexPortfolioRequest?: Maybe<LiquidateDirectIndexPortfolioRequest>;
  demoGetStockLotTransferDetail: StockLotTransferDetailResponse;
  demoPortfolio?: Maybe<Portfolio>;
  demoReinvestPortfolioDeltaHistory: Array<ReinvestPortfolioDelta>;
  demoSubAccountPortfolioAggregates: Array<SubAccountPortfolioAggregate>;
  detailedQuote: Array<DetailedQuote>;
  directIndexAnalysisRequests: Array<DirectIndexAnalysisRequest>;
  directIndexSimulate: DirectIndexSimulationStatus;
  directIndexWeights: Array<DirectIndexWeight>;
  employeeReadOnlyClearingAccountAccess: Array<ReadOnlyClearingAccountResponse>;
  estimateAllocationGainLoss: EstimateDirectIndexGainLossResponse;
  estimateDirectIndexGainLoss: EstimateDirectIndexGainLossResponse;
  estimateDirectIndexLiquidationAmount: EstimateDirectIndexLiquidationAmountResponse;
  fetchDirectIndexStockInfoForSubAccount?: Maybe<DirectIndexAccountInfo>;
  fundamentals: Array<FundamentalsSet>;
  getAccountCashTransfers: PaginatedCashTransferResponse;
  getAccountLoan?: Maybe<LoanEvent>;
  getAccountLoans: PaginatedLoanEventResponse;
  getAccountOrders: PaginatedOrderResponse;
  getActiveTreasuryExecutions: Array<Maybe<TreasuryExecution>>;
  getAllMmfMetadata: Array<MmfMetadata>;
  getAllSimulationsRunInfo: Array<DirectIndexSimulationRunInfo>;
  getAnnualizedReturns: Array<AnnualizedReturnResponse>;
  getAvailableIndexTypes: Array<DirectIndexType>;
  getBars: BarOutput;
  getBillingDates?: Maybe<BillingDatesResponse>;
  getCashDividendsForAccount: PaginatedCashDividendResponse;
  getCashTransfers: PaginatedCashTransferResponse;
  getCurrentMarginInterest?: Maybe<MarginInterestResponse>;
  getDailyCashDividendsForAccount: PaginatedDailyCashDividendResponse;
  getDailyMarginInterestChargeForCurrentBillingPeriod?: Maybe<
    Array<DailyMarginInterestCharge>
  >;
  getDailyMarginInterestChargeForPeriod?: Maybe<
    Array<DailyMarginInterestCharge>
  >;
  getDayTradeCount: DayTradeResponse;
  getDirectIndexFeeCharge?: Maybe<DirectIndexFeeCharge>;
  getDirectIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  getDirectIndexInitialLTV: Scalars["Decimal"]["output"];
  getDirectIndexLTV: DirectIndexLtvResponse;
  /** @deprecated Field no longer supported */
  getDirectIndexPerformanceData: DirectIndexPerformanceData;
  getDirectIndexPerformanceDataV2: DirectIndexPerformanceData;
  getDirectIndexSecurityOverrides: DirectIndexAccountWeights;
  getDirectIndexTradeExecutionSummary?: Maybe<DirectIndexTradeExecutionSummary>;
  getDirectIndexTradeExecutions: Array<DirectIndexTradeExecution>;
  getDirectIndexWeightsForAccount: DirectIndexAccountWeights;
  getHistoricalPerformanceData: IndexHistoricalPerformanceData;
  getIndexConstituents: IndexConstituentData;
  getIndexForSecurity: GetIndexForSecurityResponse;
  getIndexHistoricalData: Array<IndexHistoricalData>;
  getIndexMetadata?: Maybe<Array<IndexDetails>>;
  /** @deprecated Use getJointAccountOtherUser instead */
  getJointAccountCoOwner?: Maybe<GetJointAccountCoOwnerResponse>;
  getJointAccountOtherUser?: Maybe<GetJointAccountOtherUserResponse>;
  getLiquidateDirectIndexPortfolioRequest?: Maybe<LiquidateDirectIndexPortfolioRequest>;
  getMarginInterestCharges: PaginatedMarginInterestChargesResponse;
  getMarginInterestForecast: MarginInterestForecastResponse;
  getOverlappingDirectIndices: Array<DirectIndexType>;
  getPendingLoans: GetPendingLoansResponse;
  getSimulationRunInfoById?: Maybe<DirectIndexSimulationRunInfoById>;
  getStockAdjustmentForAccount: PaginatedStockAdjustmentResponse;
  getStockLotTransferDetail: StockLotTransferDetailResponse;
  getSubAccountTransferValues: Array<SubAccountTransferValue>;
  getTreasuryRates: TreasuryYield;
  getTreasuryYield: TreasuryYield;
  getUserAchDailyTransferLimit: GetUserAchDailyTransferLimitResponse;
  historicalDeltas: Array<HistoricalDelta>;
  historicalDividendPayments?: Maybe<Array<HistoricalDividendPayments>>;
  historicalFederalRates: Array<Maybe<RateData>>;
  historicalMarketCap: Array<HistoricalMarketCap>;
  historicalPrices: Array<StockData>;
  intraDayAccountValues?: Maybe<IntraDayAccountValues>;
  investmentSearch: InvestmentSearchResult;
  keyValueGet: Array<KeyValuePair>;
  maintenanceRequirements: Array<MarginRequirement>;
  marketHours: MarketHours;
  mfa: UserMfa;
  mmfMetadata: Array<MmfMetadata>;
  oidcClientApplicationDetails?: Maybe<OidcClientApplicationDetails>;
  paginatedCompletedInvitesForUser: PaginatedCompletedInviteStatusResponse;
  paginatedPendingInvitesForUser: PaginatedPendingInviteStatusResponse;
  plaidDepositAccountBalance?: Maybe<Scalars["Decimal"]["output"]>;
  portfolio?: Maybe<Portfolio>;
  portfolioBorrowingPower?: Maybe<PortfolioBorrowingPower>;
  portfolioHistory: Array<PortfolioSnapshot>;
  portfolioStartOfDayValues?: Maybe<PortfolioStartOfDayValues>;
  quote: Array<StockQuote>;
  referralCodeInfo?: Maybe<ReferralCodeInfoResponse>;
  reinvestPortfolioDeltaHistory: Array<ReinvestPortfolioDelta>;
  requestCustomAuthToken: Scalars["String"]["output"];
  runDirectIndexOptimization?: Maybe<DirectIndexOptimizationRunData>;
  runRuleEngine?: Maybe<Array<RuleEngineResult>>;
  sectors: Array<Sector>;
  securitiesBySymbol?: Maybe<Array<Security>>;
  securityBySymbol?: Maybe<Security>;
  simulationGetEventHistory: EventHistory;
  simulationGetSimulationEventTemplate?: Maybe<Array<ApexSimulationEvent>>;
  simulationGetSimulationIds: Array<Scalars["Int"]["output"]>;
  simulationGetSimulationsEntriesForSimulationId: Array<MarketDataSimulationEntry>;
  simulationGetTestClearingAccounts: Array<Scalars["String"]["output"]>;
  simulationGetTestUsers: Array<Scalars["String"]["output"]>;
  simulationGetUserTemplate: UserQaData;
  sparkline: SparklineOutput;
  subAccountPortfolioAggregates: Array<SubAccountPortfolioAggregate>;
  suggestCollateral: Array<ExternalAccountHoldings>;
  symbolSearch: Array<StockInfo>;
  trustEntity?: Maybe<TrustEntity>;
  user?: Maybe<User>;
  validateInviteCode: Scalars["Boolean"]["output"];
  verifyJointAccountCoOwner: VerifyJointAccountCoOwnerResponse;
};

export type QueryAllocationExecutionArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryBetaValuesArgs = {
  input: BetaValueInput;
};

export type QueryClearingAccountArgs = {
  input: ClearingAccountInput;
};

export type QueryCompanyReferralCountArgs = {
  companyId: Scalars["String"]["input"];
};

export type QueryComparisonPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryDemoClearingAccountArgs = {
  input: ClearingAccountInput;
};

export type QueryDemoComparisonPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexFeeChargeArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexFeeChargesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexPerformanceDataArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexPerformanceDataV2Args = {
  input: GetDirectIndexPerformanceDataInput;
};

export type QueryDemoGetDirectIndexTradeExecutionSummaryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexWeightsForAccountArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetIndexConstituentsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  gicsCode?: InputMaybe<GicsCode>;
  index: StockIndex;
};

export type QueryDemoGetLiquidateDirectIndexPortfolioRequestArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetStockLotTransferDetailArgs = {
  input: StockLotTransferDetailInput;
};

export type QueryDemoPortfolioArgs = {
  basePortfolio?: InputMaybe<Scalars["Boolean"]["input"]>;
  input: PortfolioInput;
};

export type QueryDemoReinvestPortfolioDeltaHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryDemoSubAccountPortfolioAggregatesArgs = {
  input: SubAccountPortfolioAggregatesInput;
};

export type QueryDetailedQuoteArgs = {
  input: DetailedQuoteInput;
};

export type QueryDirectIndexAnalysisRequestsArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryDirectIndexSimulateArgs = {
  input: Scalars["String"]["input"];
  submitOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryDirectIndexWeightsArgs = {
  input: DirectIndexWeightsInput;
};

export type QueryEstimateAllocationGainLossArgs = {
  input: EstimateAllocationGainLossInput;
};

export type QueryEstimateDirectIndexGainLossArgs = {
  input: EstimateDirectIndexGainLossInput;
};

export type QueryEstimateDirectIndexLiquidationAmountArgs = {
  subAccountId: Scalars["ID"]["input"];
  withdrawalAmount: Scalars["Decimal"]["input"];
};

export type QueryFetchDirectIndexStockInfoForSubAccountArgs = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export type QueryFundamentalsArgs = {
  input: FundamentalsInput;
};

export type QueryGetAccountCashTransfersArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAccountLoanArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  loanId: Scalars["ID"]["input"];
};

export type QueryGetAccountLoansArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAccountOrdersArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetActiveTreasuryExecutionsArgs = {
  clearingAccountId: Scalars["ID"]["input"];
};

export type QueryGetAnnualizedReturnsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  indices: Array<StockIndex>;
};

export type QueryGetBarsArgs = {
  input: BarInput;
};

export type QueryGetCashDividendsForAccountArgs = {
  input: GetDividendsArgs;
};

export type QueryGetCashTransfersArgs = {
  args?: InputMaybe<GetCashTransfersArgs>;
};

export type QueryGetDailyCashDividendsForAccountArgs = {
  input: GetDividendsArgs;
};

export type QueryGetDailyMarginInterestChargeForCurrentBillingPeriodArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type QueryGetDailyMarginInterestChargeForPeriodArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  period: DatePeriodArgs;
};

export type QueryGetDayTradeCountArgs = {
  input: CheckDayTradeInput;
};

export type QueryGetDirectIndexFeeChargeArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexFeeChargesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexInitialLtvArgs = {
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  directIndexType: DirectIndexType;
};

export type QueryGetDirectIndexLtvArgs = {
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  directIndexType: DirectIndexType;
};

export type QueryGetDirectIndexPerformanceDataArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexPerformanceDataV2Args = {
  input: GetDirectIndexPerformanceDataInput;
};

export type QueryGetDirectIndexSecurityOverridesArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexTradeExecutionSummaryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexTradeExecutionsArgs = {
  input: GetDirectIndexTradeExecutionsInput;
};

export type QueryGetDirectIndexWeightsForAccountArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetHistoricalPerformanceDataArgs = {
  args: GetHistoricalIndexPerformanceDataInput;
};

export type QueryGetIndexConstituentsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  gicsCode?: InputMaybe<GicsCode>;
  index: StockIndex;
};

export type QueryGetIndexForSecurityArgs = {
  input: GetIndexForSecurityInput;
};

export type QueryGetIndexHistoricalDataArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  indices: Array<StockIndex>;
};

export type QueryGetIndexMetadataArgs = {
  args?: InputMaybe<GetIndexDataInput>;
};

export type QueryGetLiquidateDirectIndexPortfolioRequestArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetMarginInterestChargesArgs = {
  input: MarginInterestChargeInput;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetOverlappingDirectIndicesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  directIndexType: DirectIndexType;
};

export type QueryGetPendingLoansArgs = {
  input: GetPendingLoansInput;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetSimulationRunInfoByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetStockAdjustmentForAccountArgs = {
  input: GetStockAdjustmentArgs;
};

export type QueryGetStockLotTransferDetailArgs = {
  input: StockLotTransferDetailInput;
};

export type QueryGetSubAccountTransferValuesArgs = {
  input: SubAccountTransferValueInput;
};

export type QueryGetTreasuryYieldArgs = {
  clearingAccountId: Scalars["ID"]["input"];
};

export type QueryGetUserAchDailyTransferLimitArgs = {
  input: GetUserAchDailyTransferLimitInput;
};

export type QueryHistoricalDeltasArgs = {
  input: HistoricalDeltaInput;
};

export type QueryHistoricalDividendPaymentsArgs = {
  date: Scalars["Date"]["input"];
  symbol: Scalars["String"]["input"];
};

export type QueryHistoricalMarketCapArgs = {
  input: HistoricalMarketCapInput;
};

export type QueryHistoricalPricesArgs = {
  input: HistoricalInput;
};

export type QueryIntraDayAccountValuesArgs = {
  input: ClearingAccountInput;
};

export type QueryInvestmentSearchArgs = {
  query: Scalars["String"]["input"];
};

export type QueryKeyValueGetArgs = {
  input: KeyValueGetInput;
};

export type QueryMaintenanceRequirementsArgs = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type QueryMmfMetadataArgs = {
  input: MmfMetadataInput;
};

export type QueryOidcClientApplicationDetailsArgs = {
  input: OidcClientApplicationDetailsInput;
};

export type QueryPaginatedCompletedInvitesForUserArgs = {
  input: CompletedInvitesForUserInput;
};

export type QueryPaginatedPendingInvitesForUserArgs = {
  input: PendingInvitesForUserInput;
};

export type QueryPlaidDepositAccountBalanceArgs = {
  plaidAccountId: Scalars["ID"]["input"];
};

export type QueryPortfolioArgs = {
  basePortfolio?: InputMaybe<Scalars["Boolean"]["input"]>;
  input: PortfolioInput;
};

export type QueryPortfolioBorrowingPowerArgs = {
  input: PortfolioValuesInput;
};

export type QueryPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPortfolioStartOfDayValuesArgs = {
  input: PortfolioValuesInput;
};

export type QueryQuoteArgs = {
  input: QuoteInput;
};

export type QueryReferralCodeInfoArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type QueryReinvestPortfolioDeltaHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  sampling?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryRunDirectIndexOptimizationArgs = {
  input: RunDirectIndexOptimizationInput;
};

export type QueryRunRuleEngineArgs = {
  input: FactInput;
  ruleGroup: RuleGroup;
};

export type QuerySecuritiesBySymbolArgs = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type QuerySecurityBySymbolArgs = {
  symbol: Scalars["String"]["input"];
};

export type QuerySimulationGetEventHistoryArgs = {
  eventId: Scalars["String"]["input"];
  eventType: EventType;
};

export type QuerySimulationGetSimulationEventTemplateArgs = {
  eventId: Scalars["String"]["input"];
  eventType: EventType;
};

export type QuerySimulationGetSimulationsEntriesForSimulationIdArgs = {
  simulationId: Scalars["Int"]["input"];
};

export type QuerySparklineArgs = {
  input: SparklineInput;
};

export type QuerySubAccountPortfolioAggregatesArgs = {
  input: SubAccountPortfolioAggregatesInput;
};

export type QuerySuggestCollateralArgs = {
  input: SuggestCollateralInput;
};

export type QuerySymbolSearchArgs = {
  options?: InputMaybe<SymbolSearchInput>;
  query: Scalars["String"]["input"];
};

export type QueryValidateInviteCodeArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type QueryVerifyJointAccountCoOwnerArgs = {
  input: VerifyJointAccountCoOwnerInput;
};

export type QueueApprovedDirectIndexTradeExecutionInput = {
  executionIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QuoteInput = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type RateData = {
  __typename?: "RateData";
  date: Scalars["Date"]["output"];
  rate: Scalars["Decimal"]["output"];
};

export type ReadOnlyClearingAccountResponse = {
  __typename?: "ReadOnlyClearingAccountResponse";
  businessReason: Scalars["String"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
};

export enum RealizedIndicatorEnumGql {
  Realized = "Realized",
  Unrealized = "Unrealized",
}

export type RealizedLotsSummary = {
  __typename?: "RealizedLotsSummary";
  estimatedTaxSavings: Scalars["Decimal"]["output"];
  longCostBasis: Scalars["Decimal"]["output"];
  longNetProceeds: Scalars["Decimal"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  longTermLosses: Scalars["Decimal"]["output"];
  longWashSalesDisallowed: Scalars["Decimal"]["output"];
  netLongTermLosses: Scalars["Decimal"]["output"];
  netShortTermLosses: Scalars["Decimal"]["output"];
  shortCostBasis: Scalars["Decimal"]["output"];
  shortNetProceeds: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  shortTermLosses: Scalars["Decimal"]["output"];
  shortWashSalesDisallowed: Scalars["Decimal"]["output"];
};

export type RealizedLotsSummaryInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated Use fromDate instead */
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
  withEstimatedTaxSavings?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RebalanceAllocationInput = {
  activeAllocationConfigId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export enum RecipientBankType {
  Aba = "ABA",
  Bic = "BIC",
}

export type ReferralCodeInfoResponse = {
  __typename?: "ReferralCodeInfoResponse";
  dealType: UserReferralDeal;
  invitedBy?: Maybe<Scalars["String"]["output"]>;
};

export type ReferralRewardDetails = {
  __typename?: "ReferralRewardDetails";
  /** @deprecated Present for backwards compatibility, use grantedOn instead */
  completedOn: Scalars["DateTime"]["output"];
  grantedOn?: Maybe<Scalars["DateTime"]["output"]>;
  kind: RewardKind;
  referralRewardType: ReferralRewardType;
  /** @deprecated This information is simply referralRewardType rendered as a string */
  rewardReceived: Scalars["String"]["output"];
  /** @deprecated This string will be constructed on the client by looking up */
  rewardReceivedAdditionalInfo?: Maybe<Scalars["String"]["output"]>;
  side: ReferralRewardSide;
};

export enum ReferralRewardSide {
  InvitedUser = "INVITED_USER",
  ReferringUser = "REFERRING_USER",
}

export type ReferralRewardType = CashRewardType | TreasuryRewardType;

export type ReinvestPortfolioDelta = {
  __typename?: "ReinvestPortfolioDelta";
  clearingAccountId: Scalars["ID"]["output"];
  date: Scalars["DateTime"]["output"];
  subAccountId: Scalars["ID"]["output"];
  totalDelta: Scalars["Decimal"]["output"];
};

export enum RemoteConfigGroup {
  AutoTlhAccountSkipList = "AUTO_TLH_ACCOUNT_SKIP_LIST",
  CashTransferReviewAccountSkipList = "CASH_TRANSFER_REVIEW_ACCOUNT_SKIP_LIST",
  CashTransferReviewRoutingNumberList = "CASH_TRANSFER_REVIEW_ROUTING_NUMBER_LIST",
  ClearingAccountTreasuryMmfOverrideList = "CLEARING_ACCOUNT_TREASURY_MMF_OVERRIDE_LIST",
  Default = "DEFAULT",
  DiAvailableIndices = "DI_AVAILABLE_INDICES",
  DiMetadataTags = "DI_METADATA_TAGS",
  DiPopularIndices = "DI_POPULAR_INDICES",
  DiSubAccountSkipAutoApprove = "DI_SUB_ACCOUNT_SKIP_AUTO_APPROVE",
  DiSubAccountSkipList = "DI_SUB_ACCOUNT_SKIP_LIST",
  EventTypeBatchSize = "EVENT_TYPE_BATCH_SIZE",
  MarginCallNotifyAccountSkipList = "MARGIN_CALL_NOTIFY_ACCOUNT_SKIP_LIST",
  MfaUserAllowList = "MFA_USER_ALLOW_LIST",
  PortfolioFetchSkipSnapshotAccountList = "PORTFOLIO_FETCH_SKIP_SNAPSHOT_ACCOUNT_LIST",
  ReferralRewardBypassThresholdUserList = "REFERRAL_REWARD_BYPASS_THRESHOLD_USER_LIST",
  SplitLotClearingAccountList = "SPLIT_LOT_CLEARING_ACCOUNT_LIST",
}

export type RepayLoanInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  marginInterestFeeAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export enum ReportType {
  CaisCustomer = "CAIS_CUSTOMER",
  CaisFdid = "CAIS_FDID",
  CaisTransformedId = "CAIS_TRANSFORMED_ID",
  CatOrders = "CAT_ORDERS",
  FinraAccountBalance = "FINRA_ACCOUNT_BALANCE",
  FinraAccountInfo = "FINRA_ACCOUNT_INFO",
  FinraOrders = "FINRA_ORDERS",
  Mecr = "MECR",
  Meno = "MENO",
  Meoc = "MEOC",
  Meor = "MEOR",
}

export type RequestMfaPhoneChallengeInput = {
  channel: PhoneVerificationChannel;
};

export type RequestMfaPhoneChallengeResponse = {
  __typename?: "RequestMfaPhoneChallengeResponse";
  phone: Scalars["String"]["output"];
  status: Scalars["Boolean"]["output"];
};

export type RequestVerificationCodeResponse = {
  __typename?: "RequestVerificationCodeResponse";
  codeSentAt?: Maybe<Scalars["String"]["output"]>;
  isCodeSent: Scalars["Boolean"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ResendJointAccountCoOwnerInvitationInput = {
  coOwnerContactEmail: UserContactEmailInput;
  coOwnerPersonalDetails: UserPersonalDetailsInput;
  initiatorUserId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ResendJointAccountCoOwnerInvitationResponse = {
  __typename?: "ResendJointAccountCoOwnerInvitationResponse";
  coOwnerContactEmail: UserContactEmail;
  coOwnerPersonalDetails: UserPersonalDetails;
  coOwnerUserId: Scalars["ID"]["output"];
};

export enum ReviewStatus {
  Reviewed = "REVIEWED",
  ReviewRequired = "REVIEW_REQUIRED",
}

export enum ReviewType {
  AcatsInMismatchReview = "ACATS_IN_MISMATCH_REVIEW",
  AcatsOutReview = "ACATS_OUT_REVIEW",
  AccountNotFound = "ACCOUNT_NOT_FOUND",
  AccountUpdateReview = "ACCOUNT_UPDATE_REVIEW",
  AchReturn = "ACH_RETURN",
  ActiveAccountMissingPositions = "ACTIVE_ACCOUNT_MISSING_POSITIONS",
  AdrFee = "ADR_FEE",
  CashBalanceMismatch = "CASH_BALANCE_MISMATCH",
  CashRebalanceRequired = "CASH_REBALANCE_REQUIRED",
  ClosedAccount = "CLOSED_ACCOUNT",
  CorpActionDetected = "CORP_ACTION_DETECTED",
  CostBasisInfoMissing = "COST_BASIS_INFO_MISSING",
  CostBasisMismatch = "COST_BASIS_MISMATCH",
  DebitCashTransfer = "DEBIT_CASH_TRANSFER",
  DtcFee = "DTC_FEE",
  Fpsl = "FPSL",
  IncomingAssetsWithMultipleAcats = "INCOMING_ASSETS_WITH_MULTIPLE_ACATS",
  IncomingAssetsWithNoAcats = "INCOMING_ASSETS_WITH_NO_ACATS",
  IncorrectDividendProcessing = "INCORRECT_DIVIDEND_PROCESSING",
  InvalidAccountType = "INVALID_ACCOUNT_TYPE",
  InvalidCorporateAction = "INVALID_CORPORATE_ACTION",
  InvalidCostBasisInfo = "INVALID_COST_BASIS_INFO",
  InvalidData = "INVALID_DATA",
  InvalidDrip = "INVALID_DRIP",
  InvalidQuantity = "INVALID_QUANTITY",
  MarginCall = "MARGIN_CALL",
  NegativeCashBalance = "NEGATIVE_CASH_BALANCE",
  OtcSymbol = "OTC_SYMBOL",
  PortfolioSymbolQuantityMismatch = "PORTFOLIO_SYMBOL_QUANTITY_MISMATCH",
  ResidualCreditReview = "RESIDUAL_CREDIT_REVIEW",
  SymbolChange = "SYMBOL_CHANGE",
  SymbolNotFound = "SYMBOL_NOT_FOUND",
  UnsupportedCorporateAction = "UNSUPPORTED_CORPORATE_ACTION",
  WireReversalFeeApplied = "WIRE_REVERSAL_FEE_APPLIED",
}

export enum ReviewerType {
  Engineering = "ENGINEERING",
  Ops = "OPS",
}

export type RevokeOidcConnectionInput = {
  recipientId: Scalars["String"]["input"];
};

export enum RewardKind {
  Granted = "GRANTED",
  Pending = "PENDING",
}

export enum RiskTolerance {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  Unspecified = "UNSPECIFIED",
}

export type RuleEngineConfig = {
  __typename?: "RuleEngineConfig";
  active: Scalars["Boolean"]["output"];
  allowAction: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  group: RuleGroup;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  priority: Scalars["Int"]["output"];
  rule: Scalars["String"]["output"];
};

export type RuleEngineConfigInput = {
  active: Scalars["Boolean"]["input"];
  allowAction: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  group: RuleGroup;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  rule: Scalars["String"]["input"];
};

export type RuleEngineResult = {
  __typename?: "RuleEngineResult";
  actionContext: ActionContext;
  allowAction: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum RuleGroup {
  ActionContext = "ACTION_CONTEXT",
  Trading = "TRADING",
}

export type RunDirectIndexOptimizationInput = {
  accountInfo: DirectIndexAccountInfoInput;
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  directIndexOptions?: InputMaybe<DirectIndexOptions>;
  directIndexSolverOptions?: InputMaybe<DirectIndexSolverOptions>;
  forceRebalance?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidateAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  runMode?: InputMaybe<DirectIndexCandidateGenerationRunMode>;
};

export type RunJobInput = {
  forceRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobName: JobName;
};

export type RunJobResponse = {
  __typename?: "RunJobResponse";
  status: RunJobResponseStatus;
};

export enum RunJobResponseStatus {
  JobAlreadyRunning = "JOB_ALREADY_RUNNING",
  JobFailed = "JOB_FAILED",
  JobNotActive = "JOB_NOT_ACTIVE",
  JobNotFound = "JOB_NOT_FOUND",
  JobNotRegistered = "JOB_NOT_REGISTERED",
  JobSkipped = "JOB_SKIPPED",
  JobStartedSuccessfully = "JOB_STARTED_SUCCESSFULLY",
  JobSuccess = "JOB_SUCCESS",
  JobTooEarlyToStart = "JOB_TOO_EARLY_TO_START",
  JobWaitingOnDependency = "JOB_WAITING_ON_DEPENDENCY",
}

export type RunJobsResponse = {
  __typename?: "RunJobsResponse";
  jobName: Scalars["String"]["output"];
  status: RunJobResponseStatus;
};

export type ScheduleDirectIndexAnalysisMeetingInput = {
  eventId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  inviteeId: Scalars["ID"]["input"];
};

export type ScheduledDepositConfig = {
  __typename?: "ScheduledDepositConfig";
  allocationConfigId?: Maybe<Scalars["ID"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  dayOfPeriod?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  periodType: ScheduledDepositPeriodType;
  primaryAccountId?: Maybe<Scalars["ID"]["output"]>;
  secondaryDayOfPeriod?: Maybe<Scalars["Int"]["output"]>;
  sourceSubAccountId?: Maybe<Scalars["ID"]["output"]>;
  sourceType: MoneyMovementSourceType;
  startAt: Scalars["DateTime"]["output"];
  subAccountId: Scalars["ID"]["output"];
  type: ScheduledDepositType;
};

export type ScheduledDepositConfigInput = {
  allocationConfigId?: InputMaybe<Scalars["ID"]["input"]>;
  amount: Scalars["Decimal"]["input"];
  dayOfPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  periodType: ScheduledDepositPeriodType;
  primaryAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  secondaryDayOfPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  sourceSubAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceType: MoneyMovementSourceType;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: ScheduledDepositType;
};

export type ScheduledDepositExecution = {
  __typename?: "ScheduledDepositExecution";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  failureReason?: Maybe<ScheduledDepositFailureReason>;
  id: Scalars["ID"]["output"];
  scheduledDepositConfig?: Maybe<ScheduledDepositConfig>;
  scheduledDepositConfigId: Scalars["ID"]["output"];
  status: ScheduledDepositExecutionStatus;
};

export enum ScheduledDepositExecutionStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  DepositInitiated = "DEPOSIT_INITIATED",
  Failure = "FAILURE",
  Initiated = "INITIATED",
  Terminated = "TERMINATED",
}

export enum ScheduledDepositFailureReason {
  AdminCancelled = "ADMIN_CANCELLED",
  DepositFailure = "DEPOSIT_FAILURE",
  UserCancelled = "USER_CANCELLED",
}

export enum ScheduledDepositPeriodType {
  BiWeekly = "BI_WEEKLY",
  Monthly = "MONTHLY",
  None = "NONE",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
}

export enum ScheduledDepositType {
  PeriodicDeposit = "PERIODIC_DEPOSIT",
  PeriodicMarginInterest = "PERIODIC_MARGIN_INTEREST",
  SingleDeposit = "SINGLE_DEPOSIT",
}

export type ScheduledReward = {
  __typename?: "ScheduledReward";
  createdAt: Scalars["DateTime"]["output"];
  creditAmount: Scalars["Decimal"]["output"];
  creditDueDate: Scalars["DateTime"]["output"];
  creditId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  promotionKey: Scalars["String"]["output"];
  promotionName: Scalars["String"]["output"];
  promotionTerms: Scalars["String"]["output"];
  subAccount?: Maybe<SubAccount>;
  subAccountId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Sector = {
  __typename?: "Sector";
  industries: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SectorMapping = {
  __typename?: "SectorMapping";
  gicsCode: GicsCode;
  id: Scalars["ID"]["output"];
  index?: Maybe<StockIndex>;
  name: Scalars["String"]["output"];
};

export type Security = {
  __typename?: "Security";
  asOfDate?: Maybe<Scalars["DateTime"]["output"]>;
  buyStatus: SecurityTradeStatus;
  cusip?: Maybe<Scalars["String"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  effectiveDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  isin?: Maybe<Scalars["String"]["output"]>;
  mmfMetadata?: Maybe<MmfMetadata>;
  name: Scalars["String"]["output"];
  sellStatus: SecurityTradeStatus;
  stockQuote?: Maybe<StockQuote>;
  subType: SecuritySubType;
  symbol: Scalars["String"]["output"];
  symbolAlias?: Maybe<Array<Maybe<SymbolAlias>>>;
  type: SecurityType;
};

export enum SecurityAliasContext {
  Acats = "ACATS",
  Default = "DEFAULT",
  None = "NONE",
  Plaid = "PLAID",
  Reporting = "REPORTING",
  SodFiles = "SOD_FILES",
}

export type SecurityGainLoss = {
  __typename?: "SecurityGainLoss";
  longTermGains: Scalars["Decimal"]["output"];
  longTermWashSales: Scalars["Decimal"]["output"];
  securityId: Scalars["ID"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  shortTermWashSales: Scalars["Decimal"]["output"];
};

export type SecuritySearchResult = {
  __typename?: "SecuritySearchResult";
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum SecuritySubType {
  Call = "CALL",
  CommonStock = "COMMON_STOCK",
  CorporateBond = "CORPORATE_BOND",
  Etf = "ETF",
  MiscStock = "MISC_STOCK",
  MoneyMarketFund = "MONEY_MARKET_FUND",
  MutualFund = "MUTUAL_FUND",
  PreferredStock = "PREFERRED_STOCK",
  Put = "PUT",
  TreasuryNote = "TREASURY_NOTE",
  Warrant = "WARRANT",
}

export enum SecurityTradeStatus {
  Fractional = "FRACTIONAL",
  None = "NONE",
  WholeShares = "WHOLE_SHARES",
}

export enum SecurityType {
  Bond = "BOND",
  Equity = "EQUITY",
  Future = "FUTURE",
  Option = "OPTION",
}

export type SelfManagedTaxLossConfig = {
  __typename?: "SelfManagedTaxLossConfig";
  clearingAccountId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  securities: Array<Scalars["ID"]["output"]>;
  sweepAll: Scalars["Boolean"]["output"];
  sweepToSubAccountId?: Maybe<Scalars["String"]["output"]>;
  sweepToTransferType?: Maybe<MoneyMovementSourceType>;
};

export type SelfManagedTaxLossConfigInput = {
  clearingAccountId: Scalars["ID"]["input"];
  sweepAll: Scalars["Boolean"]["input"];
  sweepToSubAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  sweepToTransferType?: InputMaybe<MoneyMovementSourceType>;
};

export type SelfManagedTaxLossSecuritiesInput = {
  clearingAccountId: Scalars["ID"]["input"];
  securities: Array<Scalars["ID"]["input"]>;
};

export type SellOrder = {
  date: Scalars["DateTime"]["input"];
  symbol: Scalars["String"]["input"];
};

export type SetProgressFlagInput = {
  category: ProgressFlagCategory;
  status: ProgressFlagStatus;
};

export type SimpleActivity = {
  __typename?: "SimpleActivity";
  activityType: ActivityType;
  automated?: Maybe<Scalars["Boolean"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  numOfShares?: Maybe<Scalars["Decimal"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  status: StatusEnum;
  statusText?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  value?: Maybe<Scalars["Decimal"]["output"]>;
};

export type SimulationDirectIndexExecutionInput = {
  directIndexTradeExecutionId: Scalars["ID"]["input"];
};

export enum SimulationType {
  CustomSymbols = "CUSTOM_SYMBOLS",
  DirectIndex = "DIRECT_INDEX",
  DiSnapshotBased = "DI_SNAPSHOT_BASED",
  EtfBased = "ETF_BASED",
  IndexOnly = "INDEX_ONLY",
}

export enum SodPostProcess {
  AdrFee = "ADR_FEE",
  AdrFeeTransactions = "ADR_FEE_TRANSACTIONS",
  AdrHoldingFee = "ADR_HOLDING_FEE",
  CashActivity = "CASH_ACTIVITY",
  CorporateAction = "CORPORATE_ACTION",
  CostBasis = "COST_BASIS",
  DetectCorpAction = "DETECT_CORP_ACTION",
  Dividend = "DIVIDEND",
  DividendSweep = "DIVIDEND_SWEEP",
  SecurityMaster = "SECURITY_MASTER",
  StockActivity = "STOCK_ACTIVITY",
  TaxLotEntries = "TAX_LOT_ENTRIES",
  TaxLotEntryRealized = "TAX_LOT_ENTRY_REALIZED",
  TaxLotReconciled = "TAX_LOT_RECONCILED",
}

export type SparklineInput = {
  symbol: Scalars["String"]["input"];
};

export type SparklineOutput = {
  __typename?: "SparklineOutput";
  prevClose?: Maybe<Scalars["Float"]["output"]>;
  prices: Array<Scalars["Float"]["output"]>;
};

export enum StatusCode {
  ExternalFileMissing = "EXTERNAL_FILE_MISSING",
  FailedToConnectToExternalServer = "FAILED_TO_CONNECT_TO_EXTERNAL_SERVER",
  FailedToPersistFile = "FAILED_TO_PERSIST_FILE",
  FailedToValidateFile = "FAILED_TO_VALIDATE_FILE",
  FileAlreadyFetchedAndPersisted = "FILE_ALREADY_FETCHED_AND_PERSISTED",
  FileAlreadyValidated = "FILE_ALREADY_VALIDATED",
  FileNotProcessed = "FILE_NOT_PROCESSED",
  IncompleteFileDownloaded = "INCOMPLETE_FILE_DOWNLOADED",
  SuccessfulDownloadAndPersist = "SUCCESSFUL_DOWNLOAD_AND_PERSIST",
  SuccessfulPersist = "SUCCESSFUL_PERSIST",
  SuccessfulValidation = "SUCCESSFUL_VALIDATION",
  UnsupportedExtractNumber = "UNSUPPORTED_EXTRACT_NUMBER",
  UnsupportedReportType = "UNSUPPORTED_REPORT_TYPE",
}

export enum StatusEnum {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Executed = "EXECUTED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  Pending = "PENDING",
  PendingCancellation = "PENDING_CANCELLATION",
  Queued = "QUEUED",
  Returned = "RETURNED",
  Review = "REVIEW",
  SettleClose = "SETTLE_CLOSE",
  Unknown = "UNKNOWN",
}

export type StockAdjustmentAmountByType = {
  __typename?: "StockAdjustmentAmountByType";
  adjustmentType: AdjustmentType;
  amount: Scalars["Decimal"]["output"];
};

export type StockAdjustmentDetails = {
  __typename?: "StockAdjustmentDetails";
  adjustmentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  adjustmentFactor: Scalars["Decimal"]["output"];
  adjustmentType: AdjustmentType;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  effectiveTime: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  securityPrice?: Maybe<Scalars["Decimal"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
  treasuryFees?: Maybe<Array<TreasuryFee>>;
};

export type StockBeta = {
  __typename?: "StockBeta";
  beta: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
};

export type StockData = {
  __typename?: "StockData";
  history?: Maybe<Array<StockHistory>>;
  symbol: Scalars["String"]["output"];
};

export type StockHistory = {
  __typename?: "StockHistory";
  close: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
};

export enum StockIndex {
  CrspIssLargeCapEsg = "CRSP_ISS_LARGE_CAP_ESG",
  CrspLargeCap = "CRSP_LARGE_CAP",
  CrspMidCap = "CRSP_MID_CAP",
  CrspSmallCap = "CRSP_SMALL_CAP",
  CrspTotalMarket = "CRSP_TOTAL_MARKET",
  Russell_1000 = "RUSSELL_1000",
  Russell_2000 = "RUSSELL_2000",
  Russell_3000 = "RUSSELL_3000",
  Smh = "SMH",
  Sp500 = "SP500",
  Sp500_10 = "SP500_10",
  Sp500_15 = "SP500_15",
  Sp500_20 = "SP500_20",
  Sp500_25 = "SP500_25",
  Sp500_30 = "SP500_30",
  Sp500_35 = "SP500_35",
  Sp500_40 = "SP500_40",
  Sp500_45 = "SP500_45",
  Sp500_50 = "SP500_50",
  Sp500_55 = "SP500_55",
  Sp500_60 = "SP500_60",
  Sp1500 = "SP1500",
  SpAdrDm = "SP_ADR_DM",
  SpAdrEm = "SP_ADR_EM",
  SpShariah = "SP_SHARIAH",
}

export type StockInfo = {
  __typename?: "StockInfo";
  exchangeShortName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  security?: Maybe<Security>;
  securityId?: Maybe<Scalars["ID"]["output"]>;
  symbol: Scalars["String"]["output"];
};

export type StockLendingInfo = {
  __typename?: "StockLendingInfo";
  allocatedStocks: Array<AllocatedStock>;
  /** @deprecated Use status field instead */
  enabled: Scalars["Boolean"]["output"];
  interestSweepDestinationId: Scalars["String"]["output"];
  interestSweepDestinationType: MoneyMovementSourceType;
  status: StockLendingStatus;
  totalInterest: Scalars["Decimal"]["output"];
  ytdInterest: Scalars["Decimal"]["output"];
};

export enum StockLendingStatus {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
  PendingDisabled = "PENDING_DISABLED",
  PendingEnabled = "PENDING_ENABLED",
}

export type StockLendingStatusHistory = {
  __typename?: "StockLendingStatusHistory";
  createdAt: Scalars["DateTime"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  status: StockLendingStatus;
  stockLendingId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type StockLotTransferDetailForSymbol = {
  __typename?: "StockLotTransferDetailForSymbol";
  canShowTargetAllocation: Scalars["Boolean"]["output"];
  lots?: Maybe<Array<StockLotTransferLotDetail>>;
  name: Scalars["String"]["output"];
  securityId: Scalars["ID"]["output"];
  stockPriceUsed: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  targetAllocation?: Maybe<Scalars["Decimal"]["output"]>;
  withinIndex: Scalars["Boolean"]["output"];
};

export type StockLotTransferDetailInput = {
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  directIndexType: DirectIndexType;
};

export enum StockLotTransferDetailInvalidReason {
  MissingCostBasis = "MISSING_COST_BASIS",
  MultipleLotsPerTransaction = "MULTIPLE_LOTS_PER_TRANSACTION",
  NotFractionallyTradeable = "NOT_FRACTIONALLY_TRADEABLE",
  TaxLotHoldingQtyMismatch = "TAX_LOT_HOLDING_QTY_MISMATCH",
}

export type StockLotTransferDetailInvalidStateBySymbol = {
  __typename?: "StockLotTransferDetailInvalidStateBySymbol";
  name: Scalars["String"]["output"];
  reason: StockLotTransferDetailInvalidReason;
  symbol: Scalars["String"]["output"];
};

export type StockLotTransferDetailResponse = {
  __typename?: "StockLotTransferDetailResponse";
  invalidStatesBySymbol?: Maybe<
    Array<StockLotTransferDetailInvalidStateBySymbol>
  >;
  lotsBySymbol?: Maybe<Array<StockLotTransferDetailForSymbol>>;
};

export type StockLotTransferLotDetail = {
  __typename?: "StockLotTransferLotDetail";
  id: Scalars["ID"]["output"];
  longTermShortTermIndicator: Scalars["String"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  recomputedRealizedGainLoss: Scalars["Decimal"]["output"];
  taxLotOpenBuyDate: Scalars["Date"]["output"];
  washSalesIndicator: Scalars["String"]["output"];
};

export type StockQuote = {
  __typename?: "StockQuote";
  change?: Maybe<Scalars["Decimal"]["output"]>;
  changesPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  marginRequirement?: Maybe<MarginRequirement>;
  name?: Maybe<Scalars["String"]["output"]>;
  open?: Maybe<Scalars["Decimal"]["output"]>;
  previousClose?: Maybe<Scalars["Decimal"]["output"]>;
  price: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  volume?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum StockTransferServiceType {
  Acats = "ACATS",
  Dtc = "DTC",
}

export type SubAccount = {
  __typename?: "SubAccount";
  directIndexAccountMetadata?: Maybe<DirectIndexAccountMetadata>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  pricing?: Maybe<Array<SubAccountPricing>>;
  type: SubAccountType;
};

export enum SubAccountAssetType {
  Cash = "CASH",
  Security = "SECURITY",
}

export type SubAccountPortfolioAggregate = {
  __typename?: "SubAccountPortfolioAggregate";
  aggregates: PortfolioAggregateValues;
  directIndexStatus?: Maybe<DirectIndexStatus>;
  directIndexType?: Maybe<DirectIndexType>;
  subAccount?: Maybe<SubAccount>;
  subAccountId: Scalars["ID"]["output"];
  subAccountType: SubAccountType;
};

export type SubAccountPortfolioAggregatesInput = {
  availability: AvailabilityLevel;
  clearingAccountId: Scalars["ID"]["input"];
};

export type SubAccountPricing = {
  __typename?: "SubAccountPricing";
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  unit: SubAccountPricingUnit;
  validFrom?: Maybe<Scalars["DateTime"]["output"]>;
  validTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum SubAccountPricingUnit {
  BasisPoints = "BASIS_POINTS",
  Percentage = "PERCENTAGE",
}

export type SubAccountTransferValue = {
  __typename?: "SubAccountTransferValue";
  assetType: SubAccountAssetType;
  date: Scalars["DateTime"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type SubAccountTransferValueInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum SubAccountType {
  DirectIndex = "DIRECT_INDEX",
  Primary = "PRIMARY",
  Treasury = "TREASURY",
}

export type SuggestCollateralInput = {
  currentHoldings: Array<HoldingInput>;
  currentLoanAmount: Scalars["Decimal"]["input"];
  externalAccounts: Array<ExternalAccountHoldingsInput>;
  fixedMarginRequirement?: InputMaybe<Scalars["Decimal"]["input"]>;
  targetLoanAmount: Scalars["Decimal"]["input"];
};

export enum SweepInstruction {
  NoSweep = "NO_SWEEP",
  Sweep = "SWEEP",
}

export type SymbolAlias = {
  __typename?: "SymbolAlias";
  context: Array<Maybe<SecurityAliasContext>>;
  symbol: Scalars["String"]["output"];
};

export type SymbolSearchInput = {
  securitySubTypes?: InputMaybe<Array<SecuritySubType>>;
  securityTypes?: InputMaybe<Array<SecurityType>>;
};

export enum TaxFilingStatus {
  Married = "Married",
  Single = "Single",
}

export enum TaxIdType {
  Ein = "EIN",
  Ssn = "SSN",
}

export type TaxLotEntry = {
  __typename?: "TaxLotEntry";
  buyBackIndicator: Scalars["String"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  closedLotId: Scalars["String"]["output"];
  cost: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  longTermShortTermIndicator: Scalars["String"]["output"];
  netProceed: Scalars["Decimal"]["output"];
  openBuyCostAmount: Scalars["Decimal"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  openLotId: Scalars["String"]["output"];
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss: Scalars["Decimal"]["output"];
  realizedIndicator: Scalars["String"]["output"];
  securityId: Scalars["ID"]["output"];
  settlementDate?: Maybe<Scalars["Date"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
  symbol: Scalars["String"]["output"];
  taxLotCloseSellDate?: Maybe<Scalars["Date"]["output"]>;
  taxLotOpenBuyDate?: Maybe<Scalars["Date"]["output"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["output"];
  washSalesDisallowed: Scalars["Decimal"]["output"];
  washSalesIndicator: Scalars["String"]["output"];
};

export type TaxLotInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  realizedIndicator?: InputMaybe<RealizedIndicatorEnumGql>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type TaxLotSimulation = {
  __typename?: "TaxLotSimulation";
  symbol: Scalars["String"]["output"];
};

export enum TaxLotTransactionSource {
  Acats = "ACATS",
  IntraAccount = "INTRA_ACCOUNT",
  Order = "ORDER",
  StockAdjustment = "STOCK_ADJUSTMENT",
}

export enum TimeHorizon {
  Average = "AVERAGE",
  Longest = "LONGEST",
  Short = "SHORT",
  Unspecified = "UNSPECIFIED",
}

export enum TimeInterval {
  Hour = "HOUR",
  Minute = "MINUTE",
  Second = "SECOND",
}

export type TradeExecution = {
  __typename?: "TradeExecution";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  liquidateAmount?: Maybe<Scalars["Decimal"]["output"]>;
  metadata: Array<DirectIndexTradeExecutionMetadata>;
  status: TradeExecutionStatus;
  statusHistory?: Maybe<Array<DirectIndexTradeExecutionStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  type?: Maybe<TradeExecutionType>;
};

export type TradeExecutionOrders = {
  __typename?: "TradeExecutionOrders";
  /** @deprecated Field no longer supported */
  isLongTerm?: Maybe<Scalars["Boolean"]["output"]>;
  longTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  notional: Scalars["Decimal"]["output"];
  orderSide: OrderSide;
  quantity: Scalars["Decimal"]["output"];
  /** @deprecated Field no longer supported */
  realizedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  shortTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum TradeExecutionStatus {
  AutoApproved = "AUTO_APPROVED",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Error = "ERROR",
  Failed = "FAILED",
  Invalidated = "INVALIDATED",
  NoOp = "NO_OP",
  OpsApproved = "OPS_APPROVED",
  OpsRejected = "OPS_REJECTED",
  OpsReview = "OPS_REVIEW",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
  PreProcessPending = "PRE_PROCESS_PENDING",
  Queued = "QUEUED",
  UserInitiated = "USER_INITIATED",
}

export enum TradeExecutionType {
  DiRun = "DI_RUN",
  SelfManagedAutoTlh = "SELF_MANAGED_AUTO_TLH",
}

export type TradeRestriction = {
  __typename?: "TradeRestriction";
  security: Security;
};

export type TradeRestrictionSecurityInput = {
  securityId: Scalars["ID"]["input"];
};

export enum TransactionType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export type TransferOnDeathBeneficiary = {
  __typename?: "TransferOnDeathBeneficiary";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  dateOfBirthOrCreation: Scalars["Date"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  entityType: TransferOnDeathBeneficiaryEntityType;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  legalName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  relationship: Scalars["String"]["output"];
  sharePercentage: Scalars["Int"]["output"];
  state: Scalars["String"]["output"];
  type: TransferOnDeathBeneficiaryType;
  zipCode: Scalars["String"]["output"];
};

export enum TransferOnDeathBeneficiaryEntityType {
  Individual = "INDIVIDUAL",
  Trust = "TRUST",
}

export type TransferOnDeathBeneficiaryInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  dateOfBirthOrCreation: Scalars["Date"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  entityType: TransferOnDeathBeneficiaryEntityType;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  legalName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  relationship: Scalars["String"]["input"];
  sharePercentage: Scalars["Int"]["input"];
  state: Scalars["String"]["input"];
  taxId: Scalars["String"]["input"];
  type: TransferOnDeathBeneficiaryType;
  zipCode: Scalars["String"]["input"];
};

export enum TransferOnDeathBeneficiaryType {
  Contingent = "CONTINGENT",
  Primary = "PRIMARY",
}

export enum TransferScheduleType {
  Custom = "CUSTOM",
  LastDayOfMonth = "LAST_DAY_OF_MONTH",
}

export type TransferStockFromDiInput = {
  clearingAccountId: Scalars["String"]["input"];
  excludeFromDi: Scalars["Boolean"]["input"];
  fromSubAccountId: Scalars["String"]["input"];
  symbol: Scalars["String"]["input"];
  toSubAccountId: Scalars["String"]["input"];
};

export type TransferStockSubAccountInput = {
  clearingAccountId: Scalars["String"]["input"];
  fromSubAccountId: Scalars["String"]["input"];
  lotsToTransferBySymbol: Array<LotsToTransferBySymbolInput>;
  toSubAccountId: Scalars["String"]["input"];
};

export type TreasuryConfig = {
  __typename?: "TreasuryConfig";
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type TreasuryConfigInput = {
  clearingAccountId: Scalars["ID"]["input"];
};

export enum TreasuryConfigStatus {
  Active = "ACTIVE",
  Paused = "PAUSED",
}

export type TreasuryConfigStatusHistory = {
  __typename?: "TreasuryConfigStatusHistory";
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  status: TreasuryConfigStatus;
  treasuryConfigId: Scalars["ID"]["output"];
};

export type TreasuryExecution = {
  __typename?: "TreasuryExecution";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  cashTransfer?: Maybe<TreasuryExecutionCashTransfer>;
  direction: TreasuryExecutionDirection;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  moneyMovementMethod: TreasuryExecutionMoneyMovementMethod;
  order?: Maybe<TreasuryExecutionOrder>;
  status: TreasuryExecutionStatus;
  treasuryConfigId: Scalars["ID"]["output"];
};

export type TreasuryExecutionCashTransfer = {
  __typename?: "TreasuryExecutionCashTransfer";
  cashTransferId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  treasuryExecutionId: Scalars["ID"]["output"];
};

export enum TreasuryExecutionDirection {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export enum TreasuryExecutionInitiationType {
  AllocationDeposit = "ALLOCATION_DEPOSIT",
  AllocationWithdrawal = "ALLOCATION_WITHDRAWAL",
  Automated = "AUTOMATED",
  CashSweep = "CASH_SWEEP",
  FeeJobInitiated = "FEE_JOB_INITIATED",
  OpsInitiated = "OPS_INITIATED",
  ScheduledDeposit = "SCHEDULED_DEPOSIT",
  UserInitiated = "USER_INITIATED",
}

export enum TreasuryExecutionMoneyMovementMethod {
  Ach = "ACH",
  Allocation = "ALLOCATION",
  FeeCharge = "FEE_CHARGE",
  /** @deprecated Use INTRA_ACCOUNT instead */
  FrecCash = "FREC_CASH",
  IntraAccount = "INTRA_ACCOUNT",
  Wire = "WIRE",
}

export type TreasuryExecutionOrder = {
  __typename?: "TreasuryExecutionOrder";
  batchOrderMetadataId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  treasuryExecutionId: Scalars["ID"]["output"];
};

export enum TreasuryExecutionStatus {
  BuyOrderCompleted = "BUY_ORDER_COMPLETED",
  BuyOrderInitiated = "BUY_ORDER_INITIATED",
  Canceled = "CANCELED",
  CanceledByOps = "CANCELED_BY_OPS",
  Completed = "COMPLETED",
  DepositCompleted = "DEPOSIT_COMPLETED",
  DepositInitiated = "DEPOSIT_INITIATED",
  DepositRequested = "DEPOSIT_REQUESTED",
  Failure = "FAILURE",
  FeeChargeCompleted = "FEE_CHARGE_COMPLETED",
  FeeChargeInitiated = "FEE_CHARGE_INITIATED",
  FeeChargeRejected = "FEE_CHARGE_REJECTED",
  Initiated = "INITIATED",
  NoOp = "NO_OP",
  SellOrderCompleted = "SELL_ORDER_COMPLETED",
  SellOrderInitiated = "SELL_ORDER_INITIATED",
  SellOrderRequested = "SELL_ORDER_REQUESTED",
  WithdrawalCompleted = "WITHDRAWAL_COMPLETED",
  WithdrawalInitiated = "WITHDRAWAL_INITIATED",
}

export type TreasuryFee = {
  __typename?: "TreasuryFee";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  feeRate: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  status: TreasuryFeeStatus;
};

export enum TreasuryFeeBilledTo {
  FrecCash = "FREC_CASH",
  Loan = "LOAN",
  TreasuryAssets = "TREASURY_ASSETS",
}

export enum TreasuryFeeStatus {
  Accumulating = "ACCUMULATING",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type TreasuryRewardType = {
  __typename?: "TreasuryRewardType";
  basisPoints: Scalars["Decimal"]["output"];
};

export type TreasuryTransferInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  direction?: InputMaybe<TreasuryExecutionDirection>;
  initiationType: TreasuryExecutionInitiationType;
  moneyMovementMethod?: InputMaybe<TreasuryExecutionMoneyMovementMethod>;
  status?: InputMaybe<TreasuryExecutionStatus>;
  transferType: TreasuryTransferType;
};

export enum TreasuryTransferType {
  DepositFromBank = "DEPOSIT_FROM_BANK",
  DepositFromFrecCash = "DEPOSIT_FROM_FREC_CASH",
  DepositFromWire = "DEPOSIT_FROM_WIRE",
  FeeCharge = "FEE_CHARGE",
  WithdrawalToBank = "WITHDRAWAL_TO_BANK",
  WithdrawalToFrecCash = "WITHDRAWAL_TO_FREC_CASH",
}

export type TreasuryYield = {
  __typename?: "TreasuryYield";
  allTimeNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
  annualFeePercentage: Scalars["Decimal"]["output"];
  blendedSevenDayYieldPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  lastMonthNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
  maxSevenDayYieldPercentage: Scalars["Decimal"]["output"];
  supportedFundSymbols: Array<Scalars["String"]["output"]>;
  ytdNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
};

export type TrustEntity = {
  __typename?: "TrustEntity";
  additionalTrustees: Array<Trustee>;
  address?: Maybe<GenericAddress>;
  approvers: TrustEntityApprovers;
  entityDetails: TrustEntityDetails;
  id: Scalars["ID"]["output"];
  primaryTrustee?: Maybe<User>;
  primaryTrusteeId: Scalars["ID"]["output"];
};

export type TrustEntityApprovers = {
  __typename?: "TrustEntityApprovers";
  principalApprover: Scalars["String"]["output"];
  registeredRepresentativeApprover: Scalars["String"]["output"];
};

export type TrustEntityDetails = {
  __typename?: "TrustEntityDetails";
  address?: Maybe<GenericAddress>;
  dateOfCreation: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  revocability?: Maybe<TrustRevocability>;
  state: Scalars["String"]["output"];
  taxId: Scalars["String"]["output"];
  taxIdType?: Maybe<TaxIdType>;
  trustAgreementAllowsMarginsAndShortPositions?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  trustAgreementAllowsOptions?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TrustEntityIndividualInput = {
  address: GenericAddressInput;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  companySymbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
  controlPerson: Scalars["Boolean"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  familyName: Scalars["String"]["input"];
  firmName?: InputMaybe<Scalars["String"]["input"]>;
  givenName: Scalars["String"]["input"];
  governmentIdNumber: Scalars["String"]["input"];
  idExpirationDate: Scalars["Date"]["input"];
  idIssuingStateOrCountry: Scalars["String"]["input"];
  idType: TrusteeIdType;
  immediateFamilyMembers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicalTitle?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
  ssn: Scalars["String"]["input"];
};

export enum TrustRevocability {
  Irrevocable = "IRREVOCABLE",
  Revocable = "REVOCABLE",
}

export type Trustee = {
  __typename?: "Trustee";
  address: GenericAddress;
  dateOfBirth: Scalars["Date"]["output"];
  disclosure?: Maybe<IndividualDisclosure>;
  familyName?: Maybe<Scalars["String"]["output"]>;
  givenName?: Maybe<Scalars["String"]["output"]>;
  governmentIdNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  idExpirationDate?: Maybe<Scalars["Date"]["output"]>;
  idIssuingStateOrCountry?: Maybe<Scalars["String"]["output"]>;
  idType?: Maybe<TrusteeIdType>;
  name: Scalars["String"]["output"];
};

export enum TrusteeIdType {
  DriverLicense = "DRIVER_LICENSE",
  Passport = "PASSPORT",
  StateId = "STATE_ID",
}

export type UpdateAllocationConfigInput = {
  cashTransfers?: InputMaybe<Array<AllocationConfigCashTransferInput>>;
  clearingAccountId: Scalars["ID"]["input"];
  purchaseOrders?: InputMaybe<Array<AllocationConfigPurchaseOrderInput>>;
  type: AllocationConfigType;
};

export type UpdateClearingHouseAccountStateResponse = {
  __typename?: "UpdateClearingHouseAccountStateResponse";
  status: Scalars["String"]["output"];
};

export type UpdateStockLendingSettingsInput = {
  clearingAccountId: Scalars["String"]["input"];
  enabled: Scalars["Boolean"]["input"];
  interestSweepDestinationId: Scalars["String"]["input"];
  interestSweepDestinationType: MoneyMovementSourceType;
};

export type UpdateTransferOnDeathBeneficiariesInput = {
  clearingAccountId: Scalars["String"]["input"];
  jointSignature?: InputMaybe<Scalars["Boolean"]["input"]>;
  spouseName?: InputMaybe<Scalars["String"]["input"]>;
  spouseSignature?: InputMaybe<Scalars["Boolean"]["input"]>;
  transferOnDeathBeneficiaries: Array<TransferOnDeathBeneficiaryInput>;
};

export type UpdateUserEntityAccountRequestStatusInput = {
  state: EntityAccountRequestStatusState;
};

export type UpdateUserProfileInput = {
  contactEmail?: InputMaybe<UserContactEmailInput>;
  contactEmailId?: InputMaybe<Scalars["ID"]["input"]>;
  contactPhone?: InputMaybe<UserContactPhoneInput>;
  contactPhoneId?: InputMaybe<Scalars["ID"]["input"]>;
  homeAddress?: InputMaybe<GenericAddressInput>;
  homeAddressId?: InputMaybe<Scalars["ID"]["input"]>;
  investmentProfile?: InputMaybe<UserInvestmentProfileInput>;
  investmentProfileId?: InputMaybe<Scalars["ID"]["input"]>;
  serviceProfile?: InputMaybe<UserServiceProfileInput>;
  serviceProfileId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateUserProfileResponse = {
  __typename?: "UpdateUserProfileResponse";
  requestQueued: Scalars["Boolean"]["output"];
  user?: Maybe<User>;
};

export enum UploadDocumentForward {
  Apex = "APEX",
}

export type UploadDocumentInput = {
  clearingAccountId?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  document: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: UploadDocumentType;
};

export enum UploadDocumentScanResult {
  Clean = "CLEAN",
  NotSafe = "NOT_SAFE",
  NotScanned = "NOT_SCANNED",
  Unknown = "UNKNOWN",
}

export enum UploadDocumentSource {
  Apex = "APEX",
  Customer = "CUSTOMER",
  Ops = "OPS",
}

export enum UploadDocumentType {
  AccountTransferForm = "ACCOUNT_TRANSFER_FORM",
  AffiliatedApproval = "AFFILIATED_APPROVAL",
  ArticlesOfIncorporation = "ARTICLES_OF_INCORPORATION",
  BrokerageAccountCostBasis = "BROKERAGE_ACCOUNT_COST_BASIS",
  CertificateOfTrust = "CERTIFICATE_OF_TRUST",
  CipInvestigationAddress = "CIP_INVESTIGATION_ADDRESS",
  CipInvestigationDob = "CIP_INVESTIGATION_DOB",
  CipInvestigationName = "CIP_INVESTIGATION_NAME",
  CipInvestigationTaxId = "CIP_INVESTIGATION_TAX_ID",
  CorporationAccountForm = "CORPORATION_ACCOUNT_FORM",
  DepositExplanation = "DEPOSIT_EXPLANATION",
  DriversLicense = "DRIVERS_LICENSE",
  EntityFormationDocument = "ENTITY_FORMATION_DOCUMENT",
  GeneralPartnersAgreement = "GENERAL_PARTNERS_AGREEMENT",
  IdentificationCard = "IDENTIFICATION_CARD",
  IraDepositSlip = "IRA_DEPOSIT_SLIP",
  LimitedMarginAgreement = "LIMITED_MARGIN_AGREEMENT",
  ManagersAgreement = "MANAGERS_AGREEMENT",
  Other = "OTHER",
  PartnershipAgreement = "PARTNERSHIP_AGREEMENT",
  Passport = "PASSPORT",
  SignatureImage = "SIGNATURE_IMAGE",
  SocialSecurityCard = "SOCIAL_SECURITY_CARD",
  TransferOnDeathAgreement = "TRANSFER_ON_DEATH_AGREEMENT",
  W9Form = "W9_FORM",
}

export enum UploadDocumentVerificationStatus {
  ApexVerified = "APEX_VERIFIED",
  NotVerified = "NOT_VERIFIED",
  OpsVerified = "OPS_VERIFIED",
}

export enum UploadSinkType {
  S3 = "S3",
}

export type UploadedDocument = {
  __typename?: "UploadedDocument";
  clearingAccountId?: Maybe<Scalars["String"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  forwardTo?: Maybe<UploadDocumentForward>;
  id: Scalars["ID"]["output"];
  location: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  originalName: Scalars["String"]["output"];
  scanResult: UploadDocumentScanResult;
  source: UploadDocumentSource;
  type: UploadDocumentType;
  userId: Scalars["String"]["output"];
  verificationStatus: UploadDocumentVerificationStatus;
};

export type UpsertBusinessEntityIndividualInput = {
  address: GenericAddressInput;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  companySymbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
  controlPerson: Scalars["Boolean"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  entityId: Scalars["ID"]["input"];
  familyName: Scalars["String"]["input"];
  firmName: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  immediateFamilyMembers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  individualType: BusinessEntityIndividualType;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicalTitle?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
  ssn: Scalars["String"]["input"];
};

export type UpsertBusinessEntityInput = {
  address: GenericAddressInput;
  bankingInstitutionNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  businessScope: BusinessEntityScope;
  corporationSecretaryName?: InputMaybe<Scalars["String"]["input"]>;
  entityType: BusinessEntityType;
  faxNumber?: InputMaybe<Scalars["String"]["input"]>;
  foreignBankUSAgent?: InputMaybe<Scalars["String"]["input"]>;
  industry: BusinessEntityIndustry;
  largeTraderId?: InputMaybe<Scalars["String"]["input"]>;
  mailingAddress: GenericAddressInput;
  multiplePartnersOrManagers?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  primaryFundingSource: BusinessEntityFundingSource;
  stateOfIncorporation: Scalars["String"]["input"];
  taxId: Scalars["String"]["input"];
  userTitle: Scalars["String"]["input"];
};

export type UpsertBusinessEntityInvestmentProfileInput = {
  annualIncomeMax: Scalars["Int"]["input"];
  annualIncomeMin: Scalars["Int"]["input"];
  depositSource: Scalars["String"]["input"];
  initialDepositAmount: Scalars["Int"]["input"];
  investmentExperience: InvestmentExperience;
  investmentObjective: BusinessEntityInvestmentObjective;
  investmentPlan: BusinessEntityInvestmentPlan;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["input"];
  netWorthMin: Scalars["Int"]["input"];
  riskTolerance: RiskTolerance;
  withdrawFrequency: BusinessEntityWithdrawFrequency;
};

export type UpsertBusinessEntityW9DataInput = {
  certifyEnteredInformationCorrect: Scalars["Boolean"]["input"];
  certifyNotSubjectToBackupWithholding: Scalars["Boolean"]["input"];
  certifyUSPersonForTax: Scalars["Boolean"]["input"];
  entityId: Scalars["String"]["input"];
  exemptPayeeCode?: InputMaybe<Scalars["String"]["input"]>;
  exemptionFromFatca?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpsertJointAccountCoOwnerInput = {
  coOwnerAddress: GenericAddressInput;
  coOwnerContactEmail: UserContactEmailInput;
  coOwnerPersonalDetails: UserPersonalDetailsInput;
  coOwnerUserId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpsertJointAccountCoOwnerResponse = {
  __typename?: "UpsertJointAccountCoOwnerResponse";
  coOwnerAddress: GenericAddress;
  coOwnerContactEmail: UserContactEmail;
  coOwnerPersonalDetails: UserPersonalDetails;
  coOwnerUserId: Scalars["ID"]["output"];
  initiatorUserId: Scalars["ID"]["output"];
};

export type UpsertTradeRestrictionsInput = {
  clearingAccountId: Scalars["ID"]["input"];
  restrictions: Array<TradeRestrictionSecurityInput>;
};

export type UpsertTrustEntityIndividualInput = {
  entityId: Scalars["ID"]["input"];
  upsertTrustEntityIndividualInput: Array<TrustEntityIndividualInput>;
};

export type UpsertTrustEntityInput = {
  address?: InputMaybe<GenericAddressInput>;
  name: Scalars["String"]["input"];
  revocability: TrustRevocability;
  taxId: Scalars["String"]["input"];
  taxIdType: TaxIdType;
  trustAgreementAllowsMarginsAndShortPositions: Scalars["Boolean"]["input"];
  trustAgreementAllowsOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
  trustDateOfCreation: Scalars["Date"]["input"];
  trustState: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  clearingAccounts?: Maybe<Array<ClearingAccount>>;
  entityAccountRequestStatus?: Maybe<EntityAccountRequestStatus>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  inviteCodeApplication?: Maybe<InviteCodeApplication>;
  inviteCodeApplications?: Maybe<Array<InviteCodeApplication>>;
  inviteCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  isEmployee: Scalars["Boolean"]["output"];
  linkedBrokerageAccounts?: Maybe<Array<LinkedBrokerageAccount>>;
  oidcConnections?: Maybe<Array<OidcGrantMetadata>>;
  onboardingAccountCreationRequests?: Maybe<
    Array<UserOnboardingAccountCreationRequest>
  >;
  plaidAccounts?: Maybe<Array<PlaidAccount>>;
  plaidConnections?: Maybe<Array<PlaidConnection>>;
  referralPromo?: Maybe<UserReferralPromotion>;
  uploadedDocuments?: Maybe<Array<UploadedDocument>>;
  userAddresses?: Maybe<Array<GenericAddress>>;
  userContactEmail?: Maybe<UserContactEmail>;
  userContactPhone?: Maybe<UserContactPhone>;
  userDisclosures?: Maybe<UserDisclosures>;
  userEmploymentDetails?: Maybe<Array<UserEmploymentDetails>>;
  userIdentityDetails?: Maybe<UserIdentityDetails>;
  userInvestmentProfile?: Maybe<UserInvestmentProfile>;
  userMfaPreferences?: Maybe<Array<UserMfaPreference>>;
  userMfaStatus?: Maybe<UserMfaStatus>;
  userNotifications?: Maybe<Array<UserNotification>>;
  userPersonalDetails?: Maybe<UserPersonalDetails>;
  userProgressFlags?: Maybe<Array<Maybe<UserProgressFlag>>>;
  userServiceProfile?: Maybe<UserServiceProfile>;
  userSessions?: Maybe<Array<UserSession>>;
  userSource?: Maybe<UserSource>;
  userTaxRates?: Maybe<UserTaxRates>;
  userTrustedContact?: Maybe<UserTrustedContact>;
};

export type UserLinkedBrokerageAccountsArgs = {
  input?: InputMaybe<LinkedBrokerageAccountsInput>;
};

export type UserPlaidAccountsArgs = {
  input?: InputMaybe<PlaidAccountsInput>;
};

export type UserUserNotificationsArgs = {
  channel?: InputMaybe<NotificationChannel>;
};

export type UserContactEmail = {
  __typename?: "UserContactEmail";
  email: Scalars["String"]["output"];
  emailHash?: Maybe<Scalars["String"]["output"]>;
  emailType: EmailType;
  id: Scalars["ID"]["output"];
  verified: Scalars["Boolean"]["output"];
};

export type UserContactEmailInput = {
  email: Scalars["String"]["input"];
  emailType: EmailType;
};

export type UserContactPhone = {
  __typename?: "UserContactPhone";
  id: Scalars["ID"]["output"];
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["output"];
};

export type UserContactPhoneInput = {
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
};

export type UserDisclosures = {
  __typename?: "UserDisclosures";
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  affiliatedExchangeOrFINRAEntityName?: Maybe<Scalars["String"]["output"]>;
  backupWithholding: Scalars["Boolean"]["output"];
  companySymbols?: Maybe<Scalars["String"]["output"]>;
  controlPerson: Scalars["Boolean"]["output"];
  firmName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  immediateFamily?: Maybe<Scalars["String"]["output"]>;
  politicalOrganization?: Maybe<Scalars["String"]["output"]>;
  politicallyExposed: Scalars["Boolean"]["output"];
};

export type UserDisclosuresInput = {
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  affiliatedExchangeOrFINRAEntityName?: InputMaybe<Scalars["String"]["input"]>;
  backupWithholding: Scalars["Boolean"]["input"];
  companySymbols?: InputMaybe<Scalars["String"]["input"]>;
  controlPerson: Scalars["Boolean"]["input"];
  firmName?: InputMaybe<Scalars["String"]["input"]>;
  immediateFamily?: InputMaybe<Scalars["String"]["input"]>;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
};

export type UserEmploymentDetails = {
  __typename?: "UserEmploymentDetails";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  employerName?: Maybe<Scalars["String"]["output"]>;
  employmentStatus: EmploymentStatus;
  id: Scalars["ID"]["output"];
  occupation?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  yearsEmployed?: Maybe<Scalars["Float"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

export type UserEmploymentDetailsInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  employerName?: InputMaybe<Scalars["String"]["input"]>;
  employmentStatus: EmploymentStatus;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  yearsEmployed?: InputMaybe<Scalars["Float"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserIdentityDetails = {
  __typename?: "UserIdentityDetails";
  citizenshipCountry: Scalars["String"]["output"];
  dateOfBirth: Scalars["Date"]["output"];
  governmentIdNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  idExpirationDate?: Maybe<Scalars["Date"]["output"]>;
  idIssuingStateOrCountry?: Maybe<Scalars["String"]["output"]>;
  idType?: Maybe<TrusteeIdType>;
  maritalStatus?: Maybe<MaritalStatus>;
  numberOfDependents?: Maybe<Scalars["Int"]["output"]>;
  permanentResident?: Maybe<Scalars["Boolean"]["output"]>;
  ssn: Scalars["String"]["output"];
  visaExpiration?: Maybe<Scalars["Date"]["output"]>;
  visaStatus?: Maybe<VisaType>;
};

export type UserIdentityDetailsInput = {
  accountType: FrecAccountType;
  citizenshipCountry: Scalars["String"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  governmentIdNumber?: InputMaybe<Scalars["String"]["input"]>;
  idExpirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  idIssuingStateOrCountry?: InputMaybe<Scalars["String"]["input"]>;
  idType?: InputMaybe<TrusteeIdType>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  numberOfDependents?: InputMaybe<Scalars["Int"]["input"]>;
  permanentResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  ssn: Scalars["String"]["input"];
  visaExpiration?: InputMaybe<Scalars["Date"]["input"]>;
  visaStatus?: InputMaybe<VisaType>;
};

export type UserInvestmentProfile = {
  __typename?: "UserInvestmentProfile";
  annualIncomeMax: Scalars["Int"]["output"];
  annualIncomeMin: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["output"];
  netWorthMin: Scalars["Int"]["output"];
  riskTolerance: RiskTolerance;
};

export type UserInvestmentProfileInput = {
  annualIncomeMax: Scalars["Int"]["input"];
  annualIncomeMin: Scalars["Int"]["input"];
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["input"];
  netWorthMin: Scalars["Int"]["input"];
  riskTolerance: RiskTolerance;
};

export type UserInvestorDocument = {
  __typename?: "UserInvestorDocument";
  category: Scalars["String"]["output"];
  date: Scalars["String"]["output"];
  documentType: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type UserMfa = {
  __typename?: "UserMFA";
  id: Scalars["ID"]["output"];
  phoneLast4?: Maybe<Scalars["String"]["output"]>;
  userMfaPreferences?: Maybe<Array<UserMfaPreference>>;
  userMfaStatus?: Maybe<UserMfaStatus>;
};

export type UserMfaPreference = {
  __typename?: "UserMfaPreference";
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: MfaType;
};

export type UserMfaPreferenceInput = {
  type: MfaType;
};

export type UserMfaPreferenceWithQrCode = {
  __typename?: "UserMfaPreferenceWithQrCode";
  qrCode?: Maybe<Scalars["String"]["output"]>;
  setupKey?: Maybe<Scalars["String"]["output"]>;
  userMfaPreference: UserMfaPreference;
};

export type UserMfaStatus = {
  __typename?: "UserMfaStatus";
  isActive: Scalars["Boolean"]["output"];
  lastLoginTime?: Maybe<Scalars["DateTime"]["output"]>;
  lastMfaChallengeTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserNotification = {
  __typename?: "UserNotification";
  category: NotificationCategory;
  channel: NotificationChannel;
  content: Scalars["String"]["output"];
  cta?: Maybe<NotificationCta>;
  event: NotificationEvent;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  referenceId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<NotificationStatus>;
  subject: Scalars["String"]["output"];
  type: NotificationType;
};

export type UserOnboardingAccountCreationRequest = {
  __typename?: "UserOnboardingAccountCreationRequest";
  accountType: FrecAccountType;
  id: Scalars["ID"]["output"];
  jointAccountCoOwnerIdentityVerified?: Maybe<Scalars["Boolean"]["output"]>;
  requestGroupId: Scalars["String"]["output"];
  status: OnboardingAccountCreationRequestStatus;
};

export type UserPersonalDetails = {
  __typename?: "UserPersonalDetails";
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
};

export type UserPersonalDetailsInput = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserProgressFlag = {
  __typename?: "UserProgressFlag";
  category?: Maybe<Scalars["String"]["output"]>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  status?: Maybe<ProgressFlagStatus>;
};

export type UserQaData = {
  __typename?: "UserQaData";
  addressLine1: Scalars["String"]["output"];
  addressType: AddressType;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  annualIncomeMax: Scalars["Float"]["output"];
  annualIncomeMin: Scalars["Float"]["output"];
  citizenshipCountry: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  controlPerson: Scalars["Boolean"]["output"];
  country: Scalars["String"]["output"];
  dateOfBirth: Scalars["String"]["output"];
  dividendReinvestment: DividendReinvestment;
  email: Scalars["String"]["output"];
  emailType: EmailType;
  employmentStatus: EmploymentStatus;
  firstName: Scalars["String"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  isEmployee: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  middleName: Scalars["String"]["output"];
  netWorthMax: Scalars["Float"]["output"];
  netWorthMin: Scalars["Float"]["output"];
  permanentResident?: Maybe<Scalars["Boolean"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["output"];
  politicallyExposed: Scalars["Boolean"]["output"];
  riskTolerance: RiskTolerance;
  ssn: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  sweepInstruction: SweepInstruction;
  visaExpiration?: Maybe<Scalars["String"]["output"]>;
  visaStatus?: Maybe<VisaType>;
  zipCode: Scalars["String"]["output"];
};

export type UserQaDataInput = {
  addressLine1: Scalars["String"]["input"];
  addressType: AddressType;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  affiliatedExchangeOrFINRAEntityName: Scalars["String"]["input"];
  annualIncomeMax: Scalars["Float"]["input"];
  annualIncomeMin: Scalars["Float"]["input"];
  backupWithholding?: InputMaybe<Scalars["Boolean"]["input"]>;
  citizenshipCountry: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  controlPerson: Scalars["Boolean"]["input"];
  country: Scalars["String"]["input"];
  dateOfBirth: Scalars["String"]["input"];
  dividendReinvestment: DividendReinvestment;
  email: Scalars["String"]["input"];
  emailType: EmailType;
  employmentStatus: EmploymentStatus;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  isEmployee: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  middleName: Scalars["String"]["input"];
  netWorthMax: Scalars["Float"]["input"];
  netWorthMin: Scalars["Float"]["input"];
  permanentResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["input"];
  politicallyExposed: Scalars["Boolean"]["input"];
  riskTolerance: RiskTolerance;
  ssn: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  sweepInstruction: SweepInstruction;
  visaExpiration?: InputMaybe<Scalars["String"]["input"]>;
  visaStatus?: InputMaybe<VisaType>;
  zipCode: Scalars["String"]["input"];
};

export enum UserReferralDeal {
  DiAumManagedForNoFees = "DI_AUM_MANAGED_FOR_NO_FEES",
  DiOneHundredDollarsTreasuryTwentyBps = "DI_ONE_HUNDRED_DOLLARS_TREASURY_TWENTY_BPS",
  DiTwoHundredFiftyDollarsEachTreasuryTwentyBps = "DI_TWO_HUNDRED_FIFTY_DOLLARS_EACH_TREASURY_TWENTY_BPS",
  /** @deprecated Field no longer supported */
  ExternalEventGiveaway = "EXTERNAL_EVENT_GIVEAWAY",
  /** @deprecated Field no longer supported */
  FrecNewYearPromo_2024 = "FREC_NEW_YEAR_PROMO_2024",
  HamptonMemberDiTwoHundredFiftyDollars = "HAMPTON_MEMBER_DI_TWO_HUNDRED_FIFTY_DOLLARS",
  /** @deprecated Field no longer supported */
  HnwDiFiveHundredDollars = "HNW_DI_FIVE_HUNDRED_DOLLARS",
  HnwDiThreeHundredDollars = "HNW_DI_THREE_HUNDRED_DOLLARS",
  /** @deprecated Field no longer supported */
  HnwTieredTotalAssetBonus = "HNW_TIERED_TOTAL_ASSET_BONUS",
  InfluencerDiTwoHundredFiftyDollars = "INFLUENCER_DI_TWO_HUNDRED_FIFTY_DOLLARS",
  LegacySignup = "LEGACY_SIGNUP",
  MetrixDiTwoHundredFiftyDollars = "METRIX_DI_TWO_HUNDRED_FIFTY_DOLLARS",
}

export type UserReferralPromotion = {
  __typename?: "UserReferralPromotion";
  code: Scalars["String"]["output"];
  dealType: UserReferralDeal;
};

export type UserServiceProfile = {
  __typename?: "UserServiceProfile";
  dividendReinvestment: DividendReinvestment;
  id: Scalars["ID"]["output"];
  sweepInstruction: SweepInstruction;
};

export type UserServiceProfileInput = {
  dividendReinvestment: DividendReinvestment;
  sweepInstruction: SweepInstruction;
};

export type UserSession = {
  __typename?: "UserSession";
  browserName?: Maybe<Scalars["String"]["output"]>;
  browserVersion?: Maybe<Scalars["String"]["output"]>;
  deviceModel?: Maybe<Scalars["String"]["output"]>;
  deviceVendor?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  loginTime: Scalars["DateTime"]["output"];
  osName?: Maybe<Scalars["String"]["output"]>;
  osVersion?: Maybe<Scalars["String"]["output"]>;
};

export type UserSource = {
  __typename?: "UserSource";
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type UserSourceInput = {
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
}

export type UserTaxRates = {
  __typename?: "UserTaxRates";
  federalCapitalGainsTaxRate: Scalars["Decimal"]["output"];
  federalIncomeTaxRate: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  stateCapitalGainsTaxRate: Scalars["Decimal"]["output"];
  stateIncomeTaxRate?: Maybe<Scalars["Decimal"]["output"]>;
  taxFilingStatus?: Maybe<TaxFilingStatus>;
};

export type UserTaxRatesInput = {
  federalCapitalGainsTaxRate: Scalars["Decimal"]["input"];
  federalIncomeTaxRate: Scalars["Decimal"]["input"];
  stateCapitalGainsTaxRate: Scalars["Decimal"]["input"];
  stateIncomeTaxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxFilingStatus?: InputMaybe<TaxFilingStatus>;
};

export type UserTrustedContact = {
  __typename?: "UserTrustedContact";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  relationship: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

export type UserTrustedContactInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
  relationship: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWithProfile = {
  __typename?: "UserWithProfile";
  addresses: Array<GenericAddress>;
  contactEmail: UserContactEmail;
  contactPhone: UserContactPhone;
  disclosures: UserDisclosures;
  employmentDetails: Array<UserEmploymentDetails>;
  id: Scalars["ID"]["output"];
  identityDetails: UserIdentityDetails;
  investmentProfile: UserInvestmentProfile;
  personalDetails: UserPersonalDetails;
  serviceProfile: UserServiceProfile;
};

export type ValidateMfaPhoneChallengeInput = {
  code: Scalars["String"]["input"];
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  firstTimeChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  type: MfaType;
};

export type ValidateMfaPhoneChallengeResponse = {
  __typename?: "ValidateMfaPhoneChallengeResponse";
  status: Scalars["Boolean"]["output"];
};

export type ValidateSodFilesInputArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  forceValidate?: InputMaybe<Scalars["Boolean"]["input"]>;
  runForAccounts?: InputMaybe<Array<Scalars["String"]["input"]>>;
  runForSymbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
  skipMissing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum VerificationCodeCategory {
  EmailVerification = "EMAIL_VERIFICATION",
  MfaApp = "MFA_APP",
  MfaBackupCode = "MFA_BACKUP_CODE",
  MfaDeviceToken = "MFA_DEVICE_TOKEN",
  MfaPhone = "MFA_PHONE",
}

export type VerificationCodeInput = {
  category: VerificationCodeCategory;
  code: Scalars["String"]["input"];
};

export type VerifyJointAccountCoOwnerInput = {
  initiatorDateOfBirth: Scalars["Date"]["input"];
};

export type VerifyJointAccountCoOwnerResponse = {
  __typename?: "VerifyJointAccountCoOwnerResponse";
  isVerified: Scalars["Boolean"]["output"];
};

export enum VisaType {
  E1 = "E1",
  E2 = "E2",
  E3 = "E3",
  F1 = "F1",
  G4 = "G4",
  H1B = "H1B",
  L1 = "L1",
  O1 = "O1",
  Other = "OTHER",
  Tn1 = "TN1",
  Unspecified = "UNSPECIFIED",
}

export enum WashSaleIndicatorEnumGql {
  None = "None",
  WashSale = "WashSale",
}

export type IntervalRegeneratePortfolioSnapshotsInput = {
  clearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  date: Scalars["DateTime"]["input"];
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};
