import { DateTime } from "luxon";

import { ValidationResultLevel } from "../common/enums";
import { MINIMUM_TIME_BEFORE_UPDATE_SECONDS } from "../constants/accountConstants";
import { TransferOnDeathBeneficiaryInput } from "../generated/graphql";

export type UpdateClearingHouseAccountArgs = {
  clearingAccountCreationTime: Date;
  isEditAllowed: boolean;
};

export enum AccountUpdateValidationResult {
  InsufficientTimeElapsedSinceCreation = "InsufficientTimeElapsedSinceCreation",
  NotAllowed = "NotAllowed",
}

export const AccountUpdateValidationResultLevelMap: Record<
  AccountUpdateValidationResult,
  ValidationResultLevel
> = {
  InsufficientTimeElapsedSinceCreation: ValidationResultLevel.Error,
  NotAllowed: ValidationResultLevel.Error,
};

export const validateAccountUpdate = (args: UpdateClearingHouseAccountArgs) => {
  if (!args.isEditAllowed) return AccountUpdateValidationResult.NotAllowed;

  const diff = DateTime.now().diff(
    DateTime.fromJSDate(args.clearingAccountCreationTime),
  );
  if (diff.milliseconds / 1000 < MINIMUM_TIME_BEFORE_UPDATE_SECONDS) {
    return AccountUpdateValidationResult.InsufficientTimeElapsedSinceCreation;
  }
};

type UpdateClearingHouseAccountFormsArgs = {
  clearingAccountCreationTime: Date;
  isEditAllowed: boolean;
  forms: {
    transferOnDeath?: TransferOnDeathForm;
  };
};

export enum AccountFormsUpdateValidationResult {
  InsufficientTimeElapsedSinceCreation = "InsufficientTimeElapsedSinceCreation",
  NotAllowed = "NotAllowed",
  NoFormsToUpdate = "NoFormsToUpdate",
  InvalidForms = "InvalidForms",
}

export const AccountUpdateFormsValidationResultLevelMap: Record<
  AccountFormsUpdateValidationResult,
  ValidationResultLevel
> = {
  InsufficientTimeElapsedSinceCreation: ValidationResultLevel.Error,
  NotAllowed: ValidationResultLevel.Error,
  NoFormsToUpdate: ValidationResultLevel.Error,
  InvalidForms: ValidationResultLevel.Error,
};

export const validateAccountFormsUpdate = (
  args: UpdateClearingHouseAccountFormsArgs,
) => {
  if (!args.isEditAllowed) return AccountFormsUpdateValidationResult.NotAllowed;

  const diff = DateTime.now().diff(
    DateTime.fromJSDate(args.clearingAccountCreationTime),
  );
  if (diff.milliseconds / 1000 < MINIMUM_TIME_BEFORE_UPDATE_SECONDS) {
    return AccountFormsUpdateValidationResult.InsufficientTimeElapsedSinceCreation;
  }

  if (Object.keys(args.forms).length === 0) {
    return AccountFormsUpdateValidationResult.NoFormsToUpdate;
  }

  if (args.forms.transferOnDeath) {
    return validateTransferOnDeathForm(args.forms.transferOnDeath);
  }
};

type TransferOnDeathForm = {
  primaryBeneficiaries: Omit<TransferOnDeathBeneficiaryInput, "type">[];
  contingentBeneficiaries?: Omit<TransferOnDeathBeneficiaryInput, "type">[];
  spouseName?: string;
  jointSignature?: boolean;
  spouseSignature?: boolean;
};

const validateTransferOnDeathForm = (form: TransferOnDeathForm) => {
  // Cannot have contingent beneficiaries without primary beneficiaries
  if (
    form.primaryBeneficiaries.length === 0 &&
    (form.contingentBeneficiaries?.length ?? 0) > 0
  ) {
    return AccountFormsUpdateValidationResult.InvalidForms;
  }

  // Number of beneficiaries must not exceed 6
  if (
    form.primaryBeneficiaries.length +
      (form.contingentBeneficiaries?.length ?? 0) >
    6
  ) {
    return AccountFormsUpdateValidationResult.InvalidForms;
  }

  // Share percentage must sum to 100 for both primary and contingent beneficiaries
  const primarySharePercentageSum = form.primaryBeneficiaries.reduce(
    (sum, beneficiary) => sum + beneficiary.sharePercentage,
    0,
  );
  const contingentSharePercentageSum = form.contingentBeneficiaries?.reduce(
    (sum, beneficiary) => sum + beneficiary.sharePercentage,
    0,
  );

  if (
    (form.primaryBeneficiaries.length > 0 &&
      primarySharePercentageSum !== 100) ||
    (form.contingentBeneficiaries &&
      form.contingentBeneficiaries.length > 0 &&
      contingentSharePercentageSum !== 100)
  ) {
    return AccountFormsUpdateValidationResult.InvalidForms;
  }

  // If spouse name is provided, spouse signature is required, and vice versa
  if (
    (form.spouseName && !form.spouseSignature) ||
    (!form.spouseName && form.spouseSignature)
  ) {
    return AccountFormsUpdateValidationResult.InvalidForms;
  }
};
