import { mergeClassNames } from "./classNames";

export enum AlertPreset {
  Default,
  Warning,
  Error,
  InlineError,
}

// Mantine CSS overrides
const CommonStyles = {
  icon: "h-5 w-5 mr-2 mt-0!",
  root: "rounded-lg border-0 p-0 @container",
  wrapper: "@3xl:p-6 p-3",
  title: "text-16 font-medium text-medium16",
  message: "text-book14 text-14 font-book w-full",
};

const DefaultStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkGray",
  root: "bg-frecXLightGray",
  title: "text-frecBlack",
  message: "text-frecBlack",
});

const WarningStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecYellow dark:text-frecNearBlack",
  root: "bg-frecLightYellow dark:bg-frecDarkYellow",
  title: "text-frecNearBlack",
  message: "text-frecNearBlack",
});

const ErrorStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkRed dark:text-frecNearBlack",
  root: "bg-frecLightRed dark:bg-frecDarkRed",
  title: "text-frecNearBlack",
  message: "text-frecNearBlack",
});

const InlineErrorStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkRed",
  root: "p-0! bg-transparent",
  title: "text-frecDarkRed",
  message: "text-frecDarkRed",
});

export const AlertStyle = {
  CommonStyles,
  DefaultStyles,
  WarningStyles,
  ErrorStyles,
  InlineErrorStyles,
};
