import { MoneyMovementSourceType, SubAccountType } from "../generated/graphql";

export const SubAccountTypeShortText: Record<SubAccountType, string> = {
  // Prod types
  [SubAccountType.Primary]: "Self-managed",
  [SubAccountType.DirectIndex]: "Direct Index",
  [SubAccountType.Treasury]: "Treasury",
};

export const SubAccountTypeToMoneyMovementSourceType: Record<
  SubAccountType,
  MoneyMovementSourceType
> = {
  [SubAccountType.Primary]: MoneyMovementSourceType.FrecCash, // Can also be line of credit
  [SubAccountType.DirectIndex]: MoneyMovementSourceType.DirectIndex,
  [SubAccountType.Treasury]: MoneyMovementSourceType.Treasury,
};
